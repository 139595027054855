<template>
    <div v-if="posts.length > 0" class="video-feed-org">
        <div class="subdomain-pin-post mb-2">
            <h1 class="title-all-posts text-white">
                <span class="pin-icon mr-05"><img src="@/static/images/icon-pin.svg"></span>
                {{ $t('subdomain.videoPin') }}
            </h1>
        </div>
        <VideoFeedItem
            :post="posts[0]"
            :size-title="'medium'"
            :is-owner="isOwner"
            is-video-pin
        />
    </div>
</template>

<script>
    import { fetchVideoPinOrganizationSubdomain } from '~/api/organizations'
    import VideoFeedItem from './PostPinItem.vue'

    export default {
        components: {
            VideoFeedItem,
        },

        props: {
            orgSubdomain: String,
            isOwner: Boolean,
        },

        data() {
            return {
                posts: [],
            }
        },

        mounted() {
            this.getVideoPin()
        },

        methods: {
            async getVideoPin() {
                const videoPin = await fetchVideoPinOrganizationSubdomain(this.orgSubdomain)
                    .then(({ data }) => data)

                this.posts = videoPin.data
            },
        },
    }
</script>
