// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="members">
        <HeadingTitle :title="$t('navbarOrg.topMembers')" class="members-title mb-5" />
        <div class="members-list">
            <div
                v-for="(member, index) in members"
                :key="index"
                class="members-list--card"
            >
                <UserItem
                    :user-id="member.id"
                    :is-member-top="true"
                    :follow="follow"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UserItem from '~/components/subdomains-theme-2/shared/UserItem.vue'
    import HeadingTitle from '~/components/subdomains-theme-2/shared/HeadingTitle.vue'

    export default {
        components: {
            UserItem,
            HeadingTitle,
        },
        props: {
            members: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
