// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-if="user" class="user--inline d-inline-flex text-break">
        <Avatar
            v-if="showAvatar"
            :username="user.username"
            :image="user.avatar"
            size="xs"
            class="mr-05"
        />

        <nuxt-link
            v-if="isMobile"
            :to="profilePage"
            :class="{ 'is-banned' : user.banned_at}"
            class="mr-05"
        >
            {{ user.name }}
        </nuxt-link>

        <UserPopover
            v-else
            :username="user.username"
            placement="bottom-start"
            trigger="hover"
        >
            <nuxt-link
                v-if="defaultReference"
                slot="reference"
                :to="profilePage"
                :class="{ 'is-banned' : user.banned_at}"
                class="mr-05"
            >
                {{ user.name }}
            </nuxt-link>

            <slot slot="reference" name="reference" />
        </UserPopover>

        <span v-if="showUsername" class="text-muted mr-05">
            @{{ user.username }}
        </span>
    </div>
</template>

<script>
    import { user as toUser } from '~/utils/url'

    import Avatar from '~/components/shared/user/Avatar.vue'
    import UserPopover from './Popover.vue'

    export default {
        components: {
            Avatar,
            UserPopover,
        },

        props: {
            user: {
                type: Object,
                required: true,
            },

            showAvatar: {
                type: Boolean,
                default: true,
            },

            showUsername: {
                type: Boolean,
                default: true,
            },

            defaultReference: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            isMobile: false,
        }),

        computed: {
            profilePage() {
                return toUser(this.user)
            },
        },

        mounted() {
            if (process.client) {
                if (window.screen.width < 700) {
                    this.isMobile = true
                }
            }
        },
    }
</script>
