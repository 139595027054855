// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        class="tags d-flex flex-wrap mt-1"
    >
        <slot name="prepend" />

        <slot
            v-for="(tag, index) in tags"
            :index="index"
            :tag="tag"
        >
            <nuxt-link :to="toTag(tag, page)" class="tag-technology">
                <div class="tag-technology-item">
                    {{ tag.name }}
                </div>
            </nuxt-link>
        </slot>

        <slot name="append" />
    </div>
</template>

<script>
    import { tag as toTag } from '~/utils/url'

    export default {
        props: {
            tags: Array,
            size: {
                type: String,
                default: 'medium',
            },
            page: String,
        },

        methods: {
            toTag,
        },
    }
</script>

<style lang="scss" scoped>
    @import "~assets/sass/bootstrap/mixins";

    .tags .tag:not(:last-child) {
        margin-right: 5px;
    }

    .tag-technology:hover {
        text-decoration: none;
    }

    .tags .tag.badge-primary:hover, .tags .tag.badge-primary:focus {
        color: #fff;
    }

    .tags .tag {
        font-size: 90%;
        margin-bottom: 0;
        max-width: 200px;
    }

    @include media-breakpoint-down (sm) {
        .tags .tag {
            font-size: .7rem;
        }
    }

    .tag-technology-item {
        padding: 5px 1.5rem;
        margin: 0rem 1rem 1rem 0rem;
        border-radius: 7px;
        color: #0EA8FF;
        background-color: #e7f6ff;
    }

    .theme-dark {
        .tag-technology-item {
            background-color: #25292e;
            color: #fff;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
</style>
