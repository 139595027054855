// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="subdomain-2">
        <header class="pb-2 header">
            <card :organization="organization" :is-owner="isOwner" />
        </header>

        <div class="subdomain-2-tabs">
            <heading-tabs
                class="subdomain-header subdomain-menu container"
                :active-tab-index="activeTabIndex"
            >
                <heading-tabs-item
                    :to="toOrganization(organization, '')"
                    index="posts"
                >
                    {{ $t('detailOrgaization.menu.posts') }}
                </heading-tabs-item>

                <heading-tabs-item
                    :to="toOrganization(organization, 'videos')"
                    index="videos"
                >
                    Videos
                </heading-tabs-item>

                <heading-tabs-item
                    v-if="canEditPost"
                    :to="toOrganization(organization, 'submissions')"
                    index="submissions"
                >
                    {{ $t('detailOrgaization.menu.submissions') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="toOrganization(organization, 'members')"
                    index="members"
                >
                    {{ $t('detailOrgaization.menu.members') }}
                </heading-tabs-item>

                <heading-tabs-item
                    :to="toOrganization(organization, 'followers')"
                    index="followers"
                >
                    {{ $t('detailOrgaization.menu.followers') }}
                </heading-tabs-item>

                <heading-tabs-item
                    :to="toOrganization(organization, 'info')"
                    index="info"
                >
                    {{ $t('detailOrgaization.menu.aboutUs') }}
                </heading-tabs-item>

                <heading-tabs-item
                    v-if="isMember"
                    :to="toOrganization(organization, 'stats')"
                    index="stats"
                >
                    {{ $t('detailOrgaization.menu.stats') }}
                </heading-tabs-item>

                <heading-tabs-item
                    v-if="isOwner || isAdmin"
                    :to="toOrganization(organization, 'invitations')"
                    index="invitations"
                >
                    {{ $t('detailOrgaization.menu.invitations') }}
                </heading-tabs-item>
            </heading-tabs>
        </div>
        <div class="subdomain-2-body">
            <div class="subdomain-header container-fluid">
                <div class="subdomain-2-content">
                    <div class="user-activities">
                        <div class="tabs-list">
                            <el-alert
                                v-if="!organization.approved"
                                :closable="false"
                                type="warning"
                                class="mb-2"
                                show-icon
                            >
                                {{ $t('detailOrgaization.notApproved') }}
                            </el-alert>
                            <nuxt-child :is-owner="isOwner" :is-admin="isAdmin" :organization-id="organizationId" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import _last from 'lodash/last'
    import _get from 'lodash/get'
    import { normalize } from 'normalizr'
    import { fetchOrganization, getExistingMembers } from '~/api/organizations'
    import { organization as routeToOrganization } from '~/utils/url'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'

    import { organization as normalizeOrganization } from '../../../store/__utils/schemas'

    import HeadingTabs from '~/components/subdomains/shared/HeadingTabs.vue'
    import HeadingTabsItem from '~/components/subdomains/shared/HeadingTabsItem.vue'
    import Card from '~/components/subdomains-theme-2/organizations/PageCard.vue'
    import { organizationSEO } from '~/utils/seo/organization'

    export default {
        components: {
            Card,
            HeadingTabs,
            HeadingTabsItem,
        },

        layout: 'subdomain/layout-subdomains-2',

        head() {
            const seo = organizationSEO(this.organization)

            return {
                ...seo,
                title: this.withUnreadCount(seo.title),
            }
        },

        props: {
            technologies: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                organizationTrackingID: '',
            }
        },

        computed: {
            // TODO: Should refactor
            ...mapState({
                organization(state, getters) {
                    return getters['entities/organizations/get'](this.organizationId)
                },
            }),

            ...mapGetters('notifications', ['withUnreadCount']),

            ...mapState('organizationView/technologyStacks', { technologyStacks: 'technologies' }),
            ...mapState('organizationView/topMembers', { topMembers: 'topMembers' }),

            organizations() {
                return this.$store.state.organizationsUser.organizations
            },

            isOwner() {
                const organization = this.organizations.find(item => item.slug === this.organization.slug)
                return organization ? organization.pivot.role === 'owner' : null
            },

            isAdmin() {
                return !!this.organizations.find(item => item.slug === this.organization.slug && _get(item, 'pivot.role') === 'admin')
            },

            isMember() {
                return !!this.organizations.find(item => item.slug === this.organization.slug)
            },

            canEditPost() {
                const organization = this.organizations.find(item => item.slug === this.organization.slug)
                const roles = ['admin', 'owner', 'editor']
                return organization ? roles.includes(organization.pivot.role) : false
            },

            activeTabIndex() {
                const arrayPath = this.$route.path.split('/')

                if (this.$store.state.config.vars.is_special_organization) {
                    return arrayPath[1] || 'posts'
                }

                return arrayPath[3] || 'posts'
            },

            isShowMenuMember() {
                return this.activeTabIndex !== 'members'
            },
        },

        async asyncData({
            store, params, req, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization
                const organization = await fetchOrganization(orgSubdomain)
                    .then(({ data }) => data)

                const response = await getExistingMembers(orgSubdomain).then(({ data }) => data)
                const members = response.data.response
                const pagination = response.meta.pagination

                const normalized = normalize(organization, normalizeOrganization)
                store.commit('entities/organizations/put', normalized.entities.organizations)

                await store.dispatch('organizationView/technologyStacks/fetch', orgSubdomain)
                await store.dispatch('organizationView/topMembers/fetch', orgSubdomain)
                await store.dispatch('organizationsUser/getOrganizations')

                return { organizationId: normalized.result, members, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        mounted() {
            this.sendPageView(this.$route, this.organization)
        },

        analytics: {
            autoTrack: false,
        },

        beforeDestroy() {
            this.destroyConfigGAOrganization()
        },

        methods: {
            toOrganization(organizationItem, tab) {
                if (this.$store.state.config.vars.is_special_organization) {
                    tab = (typeof tab === 'undefined') ? '' : `/${tab}`

                    return `${tab}`
                }

                return routeToOrganization(organizationItem, tab)
            },

            sendPageView(route, organization) {
                this.organizationTrackingID = organization.google_analytics_id
                const store = this.$store
                const options = _get(_last(route.matched), 'components.default.options.companyAnalytics', {})(route, store)
                const analyticsData = {
                    page_title: `${options.title} - ${organization.name}`,
                    page_location: options.page || route.fullPath,
                    page_path: route.path,
                    dimension1: 'company.view',
                }

                if (this.organizationTrackingID) {
                    window[`ga-disable-${this.organizationTrackingID}`] = false
                    window.gtag('config', this.organizationTrackingID, {
                        send_page_view: false,
                        items: analyticsData,
                    })
                }
            },

            destroyConfigGAOrganization() {
                window[`ga-disable-${this.organizationTrackingID}`] = true
                this.organizationTrackingID = ''
            },

            async fetchOrganization() {
                try {
                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getSlugSubdomain(window.location.host) : this.$route.params.organization
                    const organization = await fetchOrganization(orgSubdomain)
                        .then(({ data }) => data)
                    const normalized = normalize(organization, normalizeOrganization)
                    this.$store.commit('entities/organizations/put', normalized.entities.organizations)
                } catch (e) {
                    this.$message.error('Failed to refresh the organization detail.')
                }
            },
        },

        beforeRouteUpdate(to, from, next) {
            this.fetchOrganization()
            this.sendPageView(to, this.organization)
            next()
        },
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/colors";
    @import "../../../assets/sass/bootstrap/mixins";
    @import '~/assets/sass/components/subdomain-header';

    .subdomain-2 {
        background-color: #EFF0F6;

        .subdomain-2-tabs {
            background: #fff;
        }

        .container-fluid {
            padding: 0;
        }

        .header {
            background-color: #fff;
        }
    }

    .profile-tabs {
        border: solid $base-border-color;
        border-width: 1px 0 0 0;
    }


    @include media-breakpoint-up(lg) {
        .profile-tabs {
            background-color: white;
        }
    }

    @include media-breakpoint-down(md) {
        .profile-tabs {
            border-width: 6px 0 1px 0;
        }
    }

    @media only screen and (max-width: 991px) {
        .section-content-org {
            width: 100%;
        }
    }

    .item-section-navbar {
        background-color: white;
        border-radius: 0.5rem;
        padding: 0.5rem;
    }
    .subdomain-menu {
        font-family: 'RobotoCondensed Medium', sans-serif;
        justify-content: space-between;
        font-size: 18px;
        color: #808080;

        .heading-tabs__item.active {
            font-weight: 500;
        }
    }

    @media screen and (max-width: 768px) {
        .subdomain-menu {
            font-size: 16px;
        }
    }

.theme-dark {
    .subdomain-2-body {
        background-color:#100F09;
    }

    .subdomain-2 {
        .header, .subdomain-2-tabs {
            background-color: #000;
        }
    }
}
</style>
