// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="image-container">
        <img
            :src="descriptionLevelPartner.urlImage"
            alt="level partner"
            class="background-image"
            :class="{ 'bg-silver': level === 'silver' }"
        >
        <div class="text-overlay" :class="{ 'text-silver': level === 'silver' }">
            <p>{{ $t(descriptionLevelPartner.text) }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            level: {
                type: String,
                require: true,
            },
        },
        computed: {
            descriptionLevelPartner() {
                switch (this.level) {
                    case 'diamond':
                        return {
                            urlImage: '/images/diamond-org.png',
                            text: 'organization.level.diamondDescription',
                        }
                    case 'gold':
                        return {
                            urlImage: '/images/gold-org.png',
                            text: 'organization.level.goldDescription',
                        }
                    case 'silver':
                        return {
                            urlImage: '/images/sliver-org.png',
                            text: 'organization.level.silverDescription',
                        }
                    default:
                        return null
                }
            },
        },
    }
</script>
<style lang="scss">
.image-container {
  position: relative;
  width: 100%;
  margin: auto;
  .background-image {
        width: 100%;
        height: auto;
        display: block;
        max-width: 380px;
    }
    .text-overlay {
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        text-align: center;
        color: white;
        font-size: 18px;
        font-weight: 900;
    }
    .text-silver {
        color: #676767;
    }
    @media only screen and (max-width: 855px) {
        .background-image {
            max-width: 320px;
        }
        .text-overlay {
            top: 0.75rem;
            left: 1rem;
            text-align: center;
            font-size: 15px;
        }
        .text-silver {
            top: 0.5rem;
        }
    }
    @media only screen and (max-width: 550px) {
        .text-overlay {
            top: 0.55rem;
            left: 0.5rem;
            font-size: 12px;
        }
        .text-silver {
            left: 0.75rem;
            top: 0.35rem;
        }
        .bg-silver {
            max-width: 260px;
        }
    }
    @media only screen and (max-width: 440px) {
        .background-image {
            max-width: 260px;
        }
        .text-overlay {
            top: 0.35rem;
            left: 0.25rem;
            font-size: 10px;
        }
        .text-silver {
            left: 0.75rem;
            top: 0.20rem;
        }
        .bg-silver {
            max-width: 210px;
        }
    }
}
</style>
