// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="company-section" class="container post-section my-4">
        <heading-tabs class="container mb-4">
            <heading-tabs-item to="/me/organizations">
                {{ $t('organization.headingTabs.myOrganizations') }}
            </heading-tabs-item>

            <heading-tabs-item to="/me/organizations/followings">
                {{ $t('organization.headingTabs.followings') }}
            </heading-tabs-item>
        </heading-tabs>

        <nuxt-child />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { defaultTitle } from '~/plugins/seo'
    import HeadingTabs from '~/components/shared/HeadingTabs.vue'
    import HeadingTabsItem from '~/components/shared/HeadingTabsItem.vue'

    export default {
        components: {
            HeadingTabs,
            HeadingTabsItem,
        },

        head() {
            return {
                title: this.withUnreadCount(this.$t('organization.head').concat(`${defaultTitle}`)),
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
        },
    }
</script>
