// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="submissions pt-2 pb-2">
        <i
            v-if="posts.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <empty v-else-if="posts.length <= 0 && !loading">
            <p> {{ $t('empty') }} </p>
        </empty>
        <div v-else>
            <SubmissionItem
                v-for="(post, index) in posts"
                :key="index"
                :post="post"
                :show-organization="false"
                layout="horizontal"
                is-submission
                class="submission-item"
            />
            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading'
    import { fetchSubmittedDrafts } from '~/api/organizations'
    import { getSlugSubdomain } from '~/utils/pages'
    import Empty from '~/components/shared/Empty.vue'
    import SubmissionItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'

    export default {
        components: {
            Empty,
            SubmissionItem,
            InfiniteLoading,
        },

        data() {
            return {
                posts: [],
                pagination: null,
                loading: false,
            }
        },

        watchQuery: ['page'],

        scrollToTop: true,

        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            this.loading = true

            const posts = await fetchSubmittedDrafts(orgSubdomain, page).then(({ data }) => data)

            this.posts = [...this.posts, ...posts.data]
            this.pagination = posts.meta.pagination
            this.loading = false
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Drafts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/drafts`,
        }),
    }
</script>

<style>
.submissions {
    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .submission-item {
        margin-bottom: 24px;
    }
}
</style>
