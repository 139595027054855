// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="subdomains-2-header org-subdomain">
        <div class="d-flex banner">
            <img
                src="~/assets/images/banner-subdomain.svg"
                class="banner-img"
                alt="Image Cover"
            >
        </div>
        <div class="subdomain-header__card subdomain-header container">
            <div class="profile">
                <Avatar
                    :object="organization"
                    :image="organization.avatar"
                    :level-partner="organization.level_partner"
                    :partner-width="180"
                    :partner-hight="180"
                    is-subdomain
                    image-size="avatar"
                    size="xxl"
                    class="profile-avatar"
                />

                <div class="profile-info">
                    <div class="d-flex justify-content-between profile-info__top">
                        <div>
                            <h1 class="user-name d-inline text-break font-weight-bold">
                                {{ organization.name }}
                            </h1>
                            <DescriptionLevelPartner
                                v-if="organization.level_partner"
                                :level="organization.level_partner"
                            />
                        </div>
                        <div
                            v-if="overview.length > 0"
                            class="subdomain-header--stats"
                        >
                            <div
                                v-for="(item, index) in overview"
                                :key="index"
                                :class="`stats-card stats-${item.type}`"
                            >
                                <span class="stats-card--value">
                                    {{ item.type === 'views' || item.type === 'new-posts'
                                        ? formatNumber(item.value)
                                        : formatNumber(item.total) }}

                                </span>
                                <p class="stats-card--label">
                                    {{ $t(item.title) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1 profile-info__detail">
                        <div class="profile-avatar_describe">
                            {{ organization.short_description }}
                        </div>
                        <div class="d-flex profile-info__detail___action">
                            <div class="d-flex align-items-center">
                                <div class="switch-theme" :class="modeThemeSubdomain">
                                    <el-switch
                                        v-model="isDarkMode"
                                        v-tooltip
                                        :title="descButtonSwitchTheme"
                                        @change="switchTheme"
                                    />
                                </div>
                            </div>
                            <div class="subscribe d-flex flex-column justify-content-center">
                                <nuxt-link
                                    v-if="isOwner"
                                    :to="toOrganization(organization, 'info/edit')"
                                    class="btn btn-edit edit"
                                >
                                    <div class="d-flex align-items-center h-100">
                                        <img
                                            src="/images/icon-edit.png"
                                            class="mr-05"
                                        >
                                        {{ $t('detailOrgaization.edit') }}
                                    </div>
                                </nuxt-link>
                                <subscribe
                                    v-else
                                    ref="subscribe"
                                    :send="handleSubscribe"
                                    :subscribable="{ type: 'Organization', id: organization.slug }"
                                >
                                    <button
                                        :class="{ 'following-org': organization.following }"
                                        class="btn btn-follow-org"
                                        @click="$refs.subscribe.handle(!organization.following)"
                                    >
                                        <span
                                            v-if="organization.following"
                                        >
                                            {{ $t('detailOrgaization.following') }}
                                        </span>
                                        <span v-else>
                                            <img
                                                src="/images/icon-follow.png"
                                                class="stats-item__icon mr-05"
                                            >
                                            {{ $t('detailOrgaization.follow') }}
                                        </span>
                                    </button>
                                </subscribe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { format, subDays } from 'date-fns'
    import { getOrganizationGeneralStats } from '~/api/organizations'
    import switchModeTheme from '~/pages/__mixins/switchModeTheme'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import { getContentSubDomain } from '~/utils/pages'
    import { organization as routeToOrganization } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import DescriptionLevelPartner from '~/components/subdomains-theme-2/DescriptionLevelPartner.vue'
    import formatNumber from '~/filters/formatNumber'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        components: {
            Avatar,
            Subscribe,
            DescriptionLevelPartner,
        },

        mixins: [switchModeTheme],

        props: {
            organization: {
                type: Object,
                require: true,
            },
            isOwner: Boolean,
        },

        data() {
            return {
                statsData: null,
                statsDifferences: null,
                query: {
                    from: dateString(subDays(new Date(), 14)),
                    to: dateString(new Date()),
                },
                overview: [],
                firstStats: {},
                lastStats: {},
                isDarkMode: false,
            }
        },

        computed: {
            ...mapState('auth', ['user']),
            growth() {
                return this.$t('subdomain.growthDate', { from: this.query.from, to: this.query.to })
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },

            descButtonSwitchTheme() {
                return this.modeThemeSubdomain === 'default' ? this.$t('detailOrgaization.darkMode') : this.$t('detailOrgaization.lightMode')
            },
        },
        watch: {
            statsData() {
                this.firstStats = this.statsData ? this.statsData[0] : null
                this.lastStats = this.statsData ? this.statsData[this.statsData.length - 1] : null
                if (this.firstStats && this.lastStats) {
                    this.overview = [
                        {
                            title: 'detailOrgaization.stast.postViews',
                            type: 'views',
                            value: this.getValue('views'),
                            total: this.getTotal('views'),
                            diff: this.getDiff('views'),
                        },
                        {
                            title: 'detailOrgaization.stast.posts',
                            type: 'posts',
                            value: this.getValue('posts'),
                            total: this.getTotal('posts'),
                            diff: this.getDiff('posts'),
                        },
                        {
                            title: 'detailOrgaization.stast.members',
                            type: 'members',
                            value: this.getValue('members'),
                            total: this.getTotal('members'),
                            diff: this.getDiff('members'),
                        },
                        {
                            title: 'detailOrgaization.stast.newPosts',
                            type: 'new-posts',
                            value: this.getValue('posts'),
                            total: this.getTotal('posts'),
                            diff: this.getDiff('posts'),
                        },
                        {
                            title: 'detailOrgaization.stast.followers',
                            type: 'followers',
                            value: this.getValue('followers'),
                            total: this.getTotal('followers'),
                            diff: this.getDiff('followers'),
                        },
                    ]
                } else {
                    this.overview = []
                }
            },
        },

        mounted() {
            this.isDarkMode = this.modeThemeSubdomain === 'theme-dark'

            this.getData()
        },

        beforeUpdate() {
            this.isDarkMode = this.modeThemeSubdomain === 'theme-dark'
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
            formatNumber,

            handleSubscribe(value) {
                return this.subscribe({ organization: this.organization, value })
            },
            toOrganization(organizationItem, tab) {
                if (this.$store.state.config.vars.is_special_organization) {
                    tab = (typeof tab === 'undefined') ? '' : `/${tab}`

                    return `${tab}`
                }

                return routeToOrganization(organizationItem, tab)
            },
            async getData() {
                try {
                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getContentSubDomain(window.location.host) : this.$route.params.organization
                    const response = await getOrganizationGeneralStats(
                        orgSubdomain,
                        this.query
                    )
                    const data = response.data
                    this.statsData = data.data
                    this.statsDifferences = data.differences
                } catch (err) {
                    // TODO catch error
                }
            },
            getValue(key) {
                return this.lastStats[`${key}_count`] - this.firstStats[`${key}_count`]
            },

            getTotal(key) {
                return this.lastStats[`${key}_count`]
            },

            getDiff(key) {
                return this.statsDifferences[`percent_${key}`] || 0
            },

            switchTheme() {
                const theme = this.modeThemeSubdomain === 'default' ? 'theme-dark' : 'default'
                this.handleSwitchTheme(theme)
            },
        },
    }
</script>

<style lang="scss">
@import '../../../assets/sass/components/subdomain-header';

.org-subdomain.subdomains-2-header {
    .banner {
        position: relative;
        width: 100%;
        overflow: hidden;
        background: #00a2ea;

        &-img {
            width: 100%;
            height: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    .switch-theme {
        width: 90px;
        height: 40px;
        margin-right: 10px;
        background-color:#aeb3b4;
        border-radius: 24px;
        padding: 4px;
        cursor: pointer;

        .el-switch {
            width: 100%;
            height: 100%;

            &__core {
                width: 100% !important;
                height: 100%;
                background-image: url('/images/button-light-mode.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 24px;
                border: none;

                &::after {
                    top: unset;
                    left: 0;
                    width: 32px;
                    height: 100%;
                    margin-left: unset;
                    transition: transform 0.2s ease;
                }
            }
        }

        &.theme-dark {
            background-color:#227FF3;

            .el-switch__core {
                background-image: url('/images/button-dark-mode.png');
            }

            .el-switch.is-checked .el-switch__core::after {
                  transform: translateX(155%);
            }
        }
    }

    .header-right {
        min-width: 13rem;
    }

    .subdomain-header__card {
        background-color: #fff;

        .profile {
            display: flex;

            a.profile-avatar {
                top: 0;

                .avatar {
                    width: 150px;
                    max-height: 150px;
                    border-radius: 50% !important;
                }

                .border-avt-by-level {
                    left: unset !important;
                }
            }

            .profile-info {
                flex: 1;
                margin-left: 16px;
                margin-top: 16px;
                flex-direction: column;

                .profile-avatar_describe {
                    max-width: none;
                    text-align: left;
                }

               &__detail {
                    @media screen and (max-width: 1280px) {
                        flex-direction: column;
                    }

                    &___action {
                        @media screen and (max-width: 768px ) {
                            justify-content: flex-start;
                            margin-top: 12px;
                        }
                    }
               }

                .subdomain-header--stats {
                    display: flex;
                    gap: 14px;

                    @media screen and (max-width: 768px ) {
                        gap: 0;
                    }

                    .stats-card {
                        padding: 12px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &--value {
                            font-size: 24px;
                            font-weight: 700;
                        }

                        &--label {
                            margin-bottom: 0;
                            font-size: 16px;
                        }
                    }
                }

                .subscribe {
                    .btn-edit, .following-org {
                        font-weight: 700;
                        color: #ADA7A7 !important;
                        border: 1px solid #0EA8FF;
                        background-color: #F6FDFF !important;
                        min-height: 40px;
                        border-radius: 20px;
                        white-space: nowrap;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    .btn.btn-follow-org {
                        font-weight: 700;
                        color: white;
                        background-color: #0EA8FF;
                        min-height: 40px;
                        border-radius: 20px;
                        padding: 0 24px;
                        white-space: nowrap;

                        span {
                            display: flex;
                            align-items: center;
                        }

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .subdomain-header {
            padding: 0 1.5rem;
        }
    }

    @media screen and (max-width: 1280px) {
        .subdomain-header__card {
            .profile {
                .profile-info {
                    .subdomain-header--stats {
                        flex-wrap: wrap;

                        .stats-card {
                            padding: 8px;

                            &--value {
                                font-size: 20px;
                                font-weight: 700;
                            }

                            &--label {
                                margin-bottom: 0;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
      .subdomain-header__card {
          .profile {
              flex-direction: column;
          }
      }
    }

    @media screen and (max-width: 1080px) {
        .subdomain-header__card {
            .profile {
                .profile-info {
                    &__top {
                        flex-direction: column;
                    }

                    .subdomain-header--stats {
                        .stats-card {
                            flex-direction: row;
                            padding: 0;
                            margin-top: 16px;
                            margin-right: 16px;
                            align-items: flex-start;

                            &--value {
                                font-size: 16px;
                                font-weight: 700;
                            }

                            &--label {
                                margin-left: 8px;
                                margin-bottom: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.theme-dark {
    .subdomains-2-header {
        .subdomain-header__card {
            background-color: #000;

            .profile .profile-info {
                .profile-avatar_describe, .user-name, .subdomain-header--stats {
                    color: #fff;
                }
            }
        }
    }
}
</style>
