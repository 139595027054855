// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <section-title :title="$t('detailOrgaization.technology')" />

        <tags-list :tags="technologies" class="technical-skills mb-2">
            <template slot="append">
                <el-button
                    v-if="isOwner && technologies.length"
                    v-tooltip
                    title=""
                    type="text"
                    icon="el-icon-edit"
                    class="icon-edit-skill py-0"
                    @click="() => $refs.tagsDialog.open()"
                />

                <el-alert
                    v-if="!technologies.length"
                    :closable="false"
                    type="info"
                    title
                >
                    <span class="mr-05">{{ $t('detailOrgaization.noTechnology') }}</span>
                    <el-button v-if="isOwner" type="text" @click="() => $refs.tagsDialog.open()">
                        {{ $t('detailOrgaization.wiewAdminNoTechnology') }}
                    </el-button>
                </el-alert>
            </template>
        </tags-list>

        <tags-moderation-dialog
            v-if="isOwner"
            ref="tagsDialog"
            :tags="technologies"
            :allow-create="false"
            :fetch-suggestions="fetchSuggestions"
            :title="$t('detailOrgaization.dialogTag.title')"
            :placeholder="$t('detailOrgaization.dialogTag.placeholder')"
            @change="save"
        />
    </div>
</template>

<script>
    import { getTags } from 'viblo-sdk/api/tags'
    import { mapActions } from 'vuex'
    import SectionTitle from '~/components/users/SectionTitle.vue'
    import TagsList from '~/components/shared/tags/List.vue'
    import TagsModerationDialog from '~/components/shared/tags/ModerationDialog.vue'

    export default {
        name: 'TechnicalSkills',

        components: {
            SectionTitle,
            TagsList,
            TagsModerationDialog,
        },

        props: {
            organization: Object,
            isOwner: Boolean,
            technologies: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            ...mapActions('organizationView/technologyStacks', ['update']),
            fetchSuggestions: (input, done) => (input
                ? getTags({ src: 'typeahead', q: input, technology: true }).then(_ => _.data).then(done)
                : done([])
            ),
            save(technologies) {
                const { slug } = this.organization
                this.update({ slug, technologies })
                    .then(() => {
                        this.$message.success(this.$t('detailOrgaization.dialogTag.success'))
                        this.$refs.tagsDialog.close()
                    })
                    .catch(() => this.$message.error(this.$t('detailOrgaization.dialogTag.success')))
            },
        },
    }
</script>

<style lang="css" scoped>
    .technical-skills .icon-edit-skill {
        font-size: 1rem;
        position: relative;
        top: -3px;
    }
</style>
