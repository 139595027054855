// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="videos pt-2 pb-2">
        <i v-if="isEmptyVideos && loading" class="fa fa-circle-o-notch fa-spin sb-icon loading" aria-hidden="true" />
        <empty v-else-if="isEmptyVideos && !loading">
            <p> {{ $t('empty') }} </p>
        </empty>
        <div v-else>
            <div v-if="isHasVideoPin" class="videos-pin">
                <h1 class="videos-pin-title">
                    <span class="pin-icon mr-05"><i class="fa fa-thumb-tack" aria-hidden="true" /></span>
                    {{ $t('subdomain.videoPin') }}
                </h1>
                <div class="video-pin-content">
                    <VideoFeedItem
                        class="video-pin-item"
                        :post="videoPin"
                        :is-owner="isOwner"
                        is-video-pin
                        :is-show-content="false"
                        :on-pined="openConfirm"
                    />
                </div>
            </div>
            <div class="subdomain-list-videos">
                <div class="d-flex align-items-center">
                    <h1 class="title-all-videos">
                        {{ $t('allVideos') }}
                    </h1>
                </div>
                <div class="row">
                    <div v-for="(video, index) in videos" :key="index" class="col-md-4 py-1">
                        <VideoFeedItem
                            is-video-page
                            :post="video"
                            :subdomain="orgSubdomain"
                            :is-owner="isOwner || isAdmin"
                            :is-show-content="false"
                            :on-pined="openConfirm"
                        />
                    </div>
                </div>
                <client-only>
                    <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                        <span slot="no-results" />
                        <span slot="no-more" />
                    </InfiniteLoading>
                </client-only>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import _isEmpty from 'lodash/isEmpty'
    import Empty from '~/components/shared/Empty.vue'
    import {
        fetchVideoOrganizationSubdomain,
        fetchVideoPinOrganizationSubdomain,
        unPinVideoOrganization,
        pinVideoOrganization,
    } from '~/api/organizations'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import VideoFeedItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'

    export default {
        name: 'PostFeed',

        components: {
            Empty,
            VideoFeedItem,
            InfiniteLoading,
        },

        props: {
            isOwner: {
                type: Boolean,
                default: false,
            },
            isAdmin: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                videos: [],
                pagination: null,
                orgSubdomain: '',
                videoPin: {},
                loading: false,
            }
        },

        watchQuery: ['page'],


        computed: {
            ...mapState('settings', ['layout']),

            isEmptyVideos() {
                return _isEmpty(this.videos)
            },

            isHasVideoPin() {
                return !_isEmpty(this.videoPin)
            },
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                this.loading = true
                const videos = await fetchVideoOrganizationSubdomain(orgSubdomain, page)
                    .then(({ data }) => data)

                const videosPin = await fetchVideoPinOrganizationSubdomain(orgSubdomain)
                    .then(({ data }) => data)
                this.videos = [...this.videos, ...videos.data]
                this.videoPin = _isEmpty(videosPin.data) ? {} : videosPin.data[0]
                this.orgSubdomain = orgSubdomain
                this.pagination = videos.meta.pagination
                this.loading = false
            } catch (err) {
                this.loading = false
                return handlePageError(error, err)
            }
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },

            openConfirm(post) {
                this.$msgbox({
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                    title: this.$t('cancelScheduled.confirm'),
                    message: post.organization_pinned_at ? this.$t('pinPostOrg.confirmContentUnPin') : this.$t('pinPostOrg.confirmContent'),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.handle(post)
                        }
                        done()
                    },
                })
            },

            async handle(post) {
                try {
                    if (post.organization_pinned_at) {
                        await unPinVideoOrganization(post.slug)

                        this.videos = this.videos.map((videoItem) => {
                            if (videoItem.id === post.id) {
                                return { ...videoItem, organization_pinned_at: null }
                            }
                            return videoItem
                        })
                        this.videoPin = {}
                        this.$message.success(this.$t('pinPostOrg.unPinSuccess'))
                    } else {
                        await pinVideoOrganization(post.slug, this.orgSubdomain)

                        this.videoPin = { ...post, organization_pinned_at: 1 }
                        this.videos = this.videos.map((postItem) => {
                            if (postItem.id === post.id) {
                                return { ...postItem, organization_pinned_at: 1 }
                            }
                            return { ...postItem, organization_pinned_at: null }
                        })
                        this.$message.success(this.$t('pinPostOrg.pinSuccess'))
                    }
                } catch (error) {
                    this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Videos',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}`,
        }),
    }
</script>

<style lang="scss">
@import "../../../../assets/sass/bootstrap/colors";

.videos {
    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .videos-pin {
        background: $pin-bg;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 60px;

        &-title {
            color: #fff;

            .pin-icon {
                transform: rotate(45deg);
                display: inline-block;
                color: red;
            }
        }

        .video-pin-item {
            background: $pin-bg;
            box-shadow: none;
        }

        .video-pin-content {
            padding-left: 100px;
            padding-right: 100px;

            @media screen and (max-width: 1280px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .title-all-videos {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 30px;
        font-family: 'RobotoCondensed Medium', sans-serif;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
        }
    }
}

.theme-dark {
    .title-all-videos {
        color: #fff;
    }
}
</style>
