// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-popover
        :placement="placement"
        :trigger="trigger"
        :open-delay="500"
        @show="fetchData"
    >
        <slot slot="reference" name="reference" />

        <div v-if="user">
            <div class="d-flex flex-row justify-content-around">
                <div
                    class="mr-05 col-3 d-flex position-relative flex-col justify-content-center align-items-center"
                >
                    <Avatar
                        :object="user"
                        :image="user.avatar"
                        :is-user="true"
                        :level-partner="user.level_partner"
                        :partner-width="65"
                        :partner-hight="65"
                        :size="user.level_partner ? 'xl-mini' : 'xl'"
                    />
                </div>

                <div class="col-9">
                    <nuxt-link
                        :to="profilePage"
                        class="text-truncate d-inline-block truncate-width"
                        :class="{ 'is-banned' : user.banned_at}"
                    >
                        {{ user.name }}
                    </nuxt-link>
                    <br>
                    <span class="text-muted text-truncate">@{{ user.username }}</span>
                    <div>
                        <Stats
                            :stats="[
                                {
                                    name: this.$t('userInline.reputations').concat(`${user.reputation}`),
                                    icon: 'fa fa-star',
                                    value: user.reputation
                                },
                                {
                                    name: this.$t('userInline.posts').concat(`${user.posts_count}`),
                                    icon: 'fa fa-pencil',
                                    value: user.posts_count
                                },
                                {
                                    name: this.$t('userInline.followers').concat(`${user.followers_count}`),
                                    icon: 'fa fa-user-plus',
                                    value: user.followers_count
                                },
                                {
                                    name: this.$t('userInline.totalPostViews').concat(`${user.total_post_views}`),
                                    icon: 'fa fa-eye',
                                    value: user.total_post_views
                                }
                            ]"
                            class="mt-05"
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="!isUser(user.id)"
                class="d-flex justify-content-between align-items-center border-top mt-1 pt-05"
            >
                <AvatarsList
                    v-if="followers"
                    :users="followers"
                    :link="toFollowers"
                    :total="user.followers_count"
                    :is-owner="user"
                />
                <subscribe
                    ref="subscribe"
                    :send="handleFollow"
                    :subscribable="{ type: 'User', id: user.id }"
                >
                    <button
                        :class="{ 'following': user.following }"
                        class="btn btn-follow"
                        :disabled="user.banned_at"
                        @click="$refs.subscribe.handle(!user.following)"
                    >
                        {{ user.following ? $t('userInline.following') : $t('userInline.follow') }}
                    </button>
                </subscribe>
            </div>
        </div>
        <div v-else align="center">
            <i class="el-icon-loading" aria-hidden="true" />
        </div>
    </el-popover>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { getUserFollowers } from 'viblo-sdk/api/users'
    import { user as toUser } from '~/utils/url'

    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import AvatarsList from '~/components/shared/user/AvatarsList.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Stats from '~/components/shared/Stats.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
            Stats,
            AvatarsList,
        },

        props: {
            username: {
                type: String,
                required: true,
            },

            placement: {
                type: String,
                default: 'left',
            },

            trigger: {
                type: String,
                default: 'hover',
            },
        },

        data: () => ({
            followers: [],
        }),

        computed: {
            ...mapGetters('auth', ['isUser']),

            user() {
                return this.$store.getters['hovercard/get'](this.username)
            },

            profilePage() {
                return toUser(this.user)
            },

            toFollowers() {
                return 'followers'
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),

            handleFollow(value) {
                return this.follow({ username: this.username, value })
            },

            async getFollowedUsers(username) {
                const followers = await getUserFollowers(username)

                this.followers = followers.data
            },

            fetchData() {
                return Promise.all([
                    this.$store.dispatch('hovercard/getUser', this.username),
                    this.getFollowedUsers(this.username),
                ]).catch(() => {})
            },
        },

    }
</script>

<style scoped>
    .truncate-width {
        max-width: 250px;
    }
</style>
