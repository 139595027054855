// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="company-list">
        <div class="my-3">
            <nuxt-link
                v-if="!(user.reputation <= conditionReputations)"
                class="mr-1"
                to="/me/organizations/create"
            >
                <el-button>
                    {{ $t('organization.create') }}
                </el-button>
            </nuxt-link>

            <el-button v-else disabled>
                {{ $t('organization.create') }}
            </el-button>

            <el-alert
                v-if="!(user.reputation >= conditionReputations)"
                type="warning"
                show-icon
                class="mt-05"
            >
                {{ $t('profile.organizations.createOrgaizationCondition') }}
            </el-alert>
        </div>

        <el-alert
            v-if="!items.length"
            type="warning"
            class="mb-2"
            show-icon
        >
            {{ $t('organization.listJoined.first') }}
            <nuxt-link to="/organizations">
                {{ $t('organization.listJoined.organization') }}
            </nuxt-link>
            {{ $t('organization.listJoined.last') }}
        </el-alert>

        <company-item
            v-for="(item, index) in items"
            :key="index"
            :organization="item"
            @delete-company="deleteCompany"
            @leave-company="leaveCompany"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import CompanyItem from './Item.vue'

    export default {
        components: {
            CompanyItem,
        },
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        inject: [
            'deleteCompany',
            'leaveCompany',
        ],
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('conditionCreateOrganization', ['conditionReputations']),
        },
    }
</script>
