var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-popover',{attrs:{"placement":_vm.placement,"trigger":_vm.trigger,"open-delay":500},on:{"show":_vm.fetchData}},[_vm._t("reference",null,{"slot":"reference"}),_vm._v(" "),(_vm.user)?_c('div',[_c('div',{staticClass:"d-flex flex-row justify-content-around"},[_c('div',{staticClass:"mr-05 col-3 d-flex position-relative flex-col justify-content-center align-items-center"},[_c('Avatar',{attrs:{"object":_vm.user,"image":_vm.user.avatar,"is-user":true,"level-partner":_vm.user.level_partner,"partner-width":65,"partner-hight":65,"size":_vm.user.level_partner ? 'xl-mini' : 'xl'}})],1),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('nuxt-link',{staticClass:"text-truncate d-inline-block truncate-width",class:{ 'is-banned' : _vm.user.banned_at},attrs:{"to":_vm.profilePage}},[_vm._v("\n                    "+_vm._s(_vm.user.name)+"\n                ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"text-muted text-truncate"},[_vm._v("@"+_vm._s(_vm.user.username))]),_vm._v(" "),_c('div',[_c('Stats',{staticClass:"mt-05",attrs:{"stats":[
                            {
                                name: this.$t('userInline.reputations').concat(("" + (_vm.user.reputation))),
                                icon: 'fa fa-star',
                                value: _vm.user.reputation
                            },
                            {
                                name: this.$t('userInline.posts').concat(("" + (_vm.user.posts_count))),
                                icon: 'fa fa-pencil',
                                value: _vm.user.posts_count
                            },
                            {
                                name: this.$t('userInline.followers').concat(("" + (_vm.user.followers_count))),
                                icon: 'fa fa-user-plus',
                                value: _vm.user.followers_count
                            },
                            {
                                name: this.$t('userInline.totalPostViews').concat(("" + (_vm.user.total_post_views))),
                                icon: 'fa fa-eye',
                                value: _vm.user.total_post_views
                            }
                        ]}})],1)],1)]),_vm._v(" "),(!_vm.isUser(_vm.user.id))?_c('div',{staticClass:"d-flex justify-content-between align-items-center border-top mt-1 pt-05"},[(_vm.followers)?_c('AvatarsList',{attrs:{"users":_vm.followers,"link":_vm.toFollowers,"total":_vm.user.followers_count,"is-owner":_vm.user}}):_vm._e(),_vm._v(" "),_c('subscribe',{ref:"subscribe",attrs:{"send":_vm.handleFollow,"subscribable":{ type: 'User', id: _vm.user.id }}},[_c('button',{staticClass:"btn btn-follow",class:{ 'following': _vm.user.following },attrs:{"disabled":_vm.user.banned_at},on:{"click":function($event){return _vm.$refs.subscribe.handle(!_vm.user.following)}}},[_vm._v("\n                    "+_vm._s(_vm.user.following ? _vm.$t('userInline.following') : _vm.$t('userInline.follow'))+"\n                ")])])],1):_vm._e()]):_c('div',{attrs:{"align":"center"}},[_c('i',{staticClass:"el-icon-loading",attrs:{"aria-hidden":"true"}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }