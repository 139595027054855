// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="members">
        <HeadingTitle :title="$t('subdomain.AllFollowers')" class="members-title mb-5" />
        <empty v-if="userIds.length <= 0">
            {{ $t('organization.noFollowers') }}
        </empty>
        <div v-else class="members-list">
            <div
                v-for="(id, index) in userIds"
                :key="index"
                class="members-list--card"
            >
                <UserItem
                    :user-id="id"
                    :follow="follow"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import Empty from '~/components/shared/Empty.vue'
    import HeadingTitle from '~/components/subdomains-theme-2/shared/HeadingTitle.vue'
    import UserItem from '~/components/subdomains-theme-2/shared/UserItem.vue'

    export default {
        components: {
            HeadingTitle,
            UserItem,
            Empty,
        },

        props: {
            userIds: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
