// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="posts.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <draft-item
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
        />

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/Pagination.vue'
    import DraftItem from './DraftItem.vue'

    export default {
        components: {
            Empty,
            DraftItem,
            Pagination,
        },

        props: {
            posts: Array,
            pagination: Object,
        },
    }
</script>
