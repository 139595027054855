// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <joined-organizations :items="organizations" />
        <inviting-organizations
            v-if="invitations.length"
            :invitations="invitations"
            @refresh="refresh"
        />
    </div>
</template>

<script>
    import { getOrganizations, destroy, leave } from '~/api/organizations'
    import { myInvitations, acceptInvitation, declineInvitation } from '~/api/invitations'
    import JoinedOrganizations from '~/components/organizations/joinedOrganizations/List.vue'
    import InvitingOrganizations from '~/components/organizations/invitingOrganizations/List.vue'
    import { handlePageError } from '~/utils/pages'
    import { loginWithSSR } from '~/utils/login'

    export default {
        components: {
            JoinedOrganizations,
            InvitingOrganizations,
        },
        async asyncData({
            store, error, req, redirect,
        }) {
            try {
                const organizations = await getOrganizations()
                    .then(({ data }) => data.data)
                const invitations = await myInvitations()
                    .then(({ data }) => data.data)

                return { organizations, invitations }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },
        provide() {
            return {
                deleteCompany: this.destroyCompany,
                leaveCompany: this.leaveCompany,
                acceptInvitation: this.acceptInvitation,
                declineInvitation: this.declineInvitation,
            }
        },
        methods: {
            async destroyCompany({ slug }) {
                await destroy(slug)
                this.organizations = this.organizations.filter(c => c.slug !== slug)
            },
            async leaveCompany({ slug }) {
                await leave(slug)
                this.organizations = this.organizations.filter(c => c.slug !== slug)
            },
            async refresh() {
                const organizations = await getOrganizations()
                    .then(({ data }) => data.data)
                const invitations = await myInvitations()
                    .then(({ data }) => data.data)
                this.organizations = [...organizations]
                this.invitations = [...invitations]
            },
            async acceptInvitation(organization, user) {
                await acceptInvitation(organization.slug, user.username)
                this.refresh()
            },
            async declineInvitation(organization, user) {
                await declineInvitation(organization.slug, user.username)
                this.refresh()
            },
        },
    }
</script>
