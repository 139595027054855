// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div class="d-flex">
            <el-menu
                class="el-menu-demo d-flex"
                mode="horizontal"
                :default-active="menuDefaultActive"
                @select="menuOnSelect"
            >
                <el-menu-item index="OrganizationStatsOverview">
                    {{ $t('statsOrganization.overview.title') }}
                </el-menu-item>

                <el-menu-item index="OrganizationStatsContribution">
                    {{ $t('statsOrganization.contribution.title') }}
                </el-menu-item>
            </el-menu>

            <div class="search-date d-md-block d-none" tabindex="-1" style="width: 100%">
                <div class="d-flex menu-right">
                    <DatePicker
                        :from="dateRange[0]"
                        :to="dateRange[1]"
                        :filters="filters"
                        :show-button="false"
                    />
                </div>
            </div>
        </div>

        <DatePicker
            :from="dateRange[0]"
            :to="dateRange[1]"
            :filters="filters"
            :show-button="false"
            class="d-md-none mt-2 date-input stats-date-filter"
        />
    </div>
</template>

<script>
    import {
        format,
        startOfMonth,
        endOfMonth,
        subMonths,
        subDays,
    } from 'date-fns'

    import { getSlugSubdomain } from '~/utils/pages'
    import DatePicker from '~/components/widgets/DatePicker.vue'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    const statsable = [
        {
            name: 'organization',
            label: 'General',
        },
        {
            name: 'user',
            label: 'Member',
        },
    ]

    export default {
        components: {
            DatePicker,
        },

        props: {
            organization: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            const from = this.$route.query.from ? this.$route.query.from : dateString(subDays(new Date(), 14))
            const to = this.$route.query.to ? this.$route.query.to : dateString(new Date())
            const dateRange = [from, to]
            const filters = [
                {
                    text: this.$t('statsOrganization.date.thisMonth'),
                    dates: [
                        dateString(startOfMonth(new Date())),
                        dateString(endOfMonth(new Date())),
                    ],
                },
                {
                    text: this.$t('statsOrganization.date.lastMonth'),
                    dates: [
                        dateString(startOfMonth(subMonths(new Date(), 1))),
                        dateString(endOfMonth(subMonths(new Date(), 1))),
                    ],
                },
                {
                    text: this.$t('statsOrganization.date.twoMonths'),
                    dates: [
                        dateString(startOfMonth(subMonths(new Date(), 2))),
                        dateString(endOfMonth(subMonths(new Date(), 2))),
                    ],
                },
            ]

            return {
                dateRange,
                filters,
                statsable,
            }
        },

        computed: {
            menuDefaultActive() {
                return this.$route.name
            },
        },

        methods: {
            menuOnSelect(key) {
                this.$router.push({
                    name: key,
                    query: {
                        from: this.$route.query.from,
                        to: this.$route.query.to,
                    },
                })
            },
        },

        scrollToTop: true,

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Stats',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/stats`,
        }),
    }
</script>

<style lang="scss" scoped>
    .menu-right {
        align-items: center;
        justify-content: flex-end;
        outline: none;
    }
</style>

<style lang="scss">
    .el-menu-demo {
        background-color: transparent;

        .el-menu-item:not(.is-disabled):hover {
            background-color: transparent;
        }
    }
    .stats-date-filter {
        .el-date-editor {
            width: 100% !important;
        }
    }
    .search-date {
        border-bottom: 1px solid #e6e6e6;
    }
</style>
