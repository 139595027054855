// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <section class="d-flex mb-1 py-05">
        <avatar
            :image="organization.avatar"
            :username="organization.slug"
            size="lg"
        />

        <div class="d-flex flex-column flex-grow-1 justify-content-between mx-1">
            <h4>
                <nuxt-link :to="toOrganization" class="mr-1 link">
                    <strong>{{ organization.name }}</strong>
                </nuxt-link>
            </h4>

            <div class="d-inline-flex">
                <span class="text-muted">{{ $t('organization.invitations.invitedBy') }}</span>
                <avatar
                    :image="inviter.data.avatar"
                    :username="inviter.data.username"
                    class="mx-05"
                    size="xs"
                />

                <nuxt-link :to="toInviter" class="link">
                    {{ organization.inviter.data.name }}
                </nuxt-link>

                <span class="text-muted ml-05">
                    as <span class="text-capitalize">{{ $t(`inviteInOrgaization.${organization.pivot.role}`) }}</span>,
                    {{ organization.pivot.created_at | humanizeTime }}
                </span>
            </div>
        </div>

        <div class="invitation-item__actions d-flex align-items-center">
            <div class="d-flex">
                <el-button size="small" type="success" @click="() => actions.acceptInvitation(organization, user)">
                    {{ $t('organization.invitations.accept') }}
                </el-button>

                <el-button size="small" class="ml-1" @click="() => actions.declineInvitation(organization, user)">
                    {{ $t('organization.invitations.decline') }}
                </el-button>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex'
    import { organization as toOrganization, user as toUser } from '~/utils/url'
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            organization: {
                type: Object,
                required: true,
            },

            actions: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('auth', ['user']),

            inviter() {
                return this.organization.inviter
            },

            toOrganization() {
                return toOrganization(this.organization)
            },

            toInviter() {
                return toUser(this.organization.inviter.data)
            },
        },
    }
</script>
