// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="posts.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else class="post-feed">
        <FeedItem
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
            :show-organization="false"
            :show-preview="layout === 'preview'"
        />

        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Empty from '~/components/shared/Empty.vue'
    import { fetchPostOrganization } from '~/api/organizations'
    import Pagination from '~/components/Pagination.vue'
    import FeedItem from '~/containers/posts/PostFeedItem.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'PostFeed',

        components: {
            FeedItem,
            Pagination,
            Empty,
        },

        watchQuery: ['page'],


        computed: {
            ...mapState('settings', ['layout']),
        },

        async asyncData({
            store, params, query, req, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getContentSubDomain(host) : params.organization

                const posts = await fetchPostOrganization(orgSubdomain, query)
                    .then(({ data }) => data)

                return {
                    posts: posts.data,
                    pagination: posts.meta.pagination,
                }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Posts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getContentSubDomain(window.location.host) : route.params.organization}`,
        }),
    }
</script>
