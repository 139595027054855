// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-0 pb-3">
        <InviteForm
            :disabled="!organization.approved"
            :organization="organization"
            :invitations="invitations"
            @saved="refresh"
        />

        <InvitingUsers
            v-if="invitations.length"
            :invitations="invitations"
        />

        <ExistingMembers
            :members="members.data"
            :organization="organization"
            :pagination="members.meta.pagination"
        />
    </div>
</template>

<script>
    import _findIndex from 'lodash/findIndex'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import { cancelInvitation, getInvitingUsers } from '~/api/invitations'
    import {
        getOrganizationEdit, changeRoleMember, getExistingMembers, removeMember,
    } from '~/api/organizations'

    import InviteForm from '~/components/organizations/form/Invite.vue'
    import InvitingUsers from '~/components/organizations/InvitingUsers/List.vue'
    import ExistingMembers from '~/components/organizations/member/List.vue'

    const fetchOrganization = slug => getOrganizationEdit(slug).then(({ data }) => data.data)
    const fetchInvitations = slug => getInvitingUsers(slug).then(({ data }) => data.data)
    const fetchMembers = (slug, params) => getExistingMembers(slug, params).then(({ data }) => data)

    export default {
        components: {
            InviteForm,
            InvitingUsers,
            ExistingMembers,
        },

        watchQuery: ['page', 'user', 'role'],

        async asyncData({
            store, route, error, query, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const slug = store.state.config.vars.is_special_organization
                    ? getContentSubDomain(host) : route.params.organization
                const page = query.page || 1
                const user = query.user
                const role = query.role

                const [organization, invitations, members] = await Promise.all([
                    fetchOrganization(slug),
                    fetchInvitations(slug),
                    fetchMembers(slug, { page, user, role }),
                ])

                return { organization, invitations, members }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        provide() {
            return {
                cancelInvitation: this.cancelInvitation,
                changeRole: this.changeRoleMember,
                removeMember: this.removeMember,
            }
        },

        methods: {
            async refresh() {
                this.invitations = await fetchInvitations(this.organization.slug)
            },

            async cancelInvitation({ username }) {
                await cancelInvitation(this.organization.slug, username)
                this.refresh()
            },

            changeRoleMember(member, newRole) {
                return changeRoleMember(this.organization.slug, member.username, { newRole })
            },

            async removeMember(member) {
                try {
                    await removeMember(this.organization.slug, { member: member.username })
                    this.$message.success(this.$t('inviteInOrgaization.removeMember.succes'))
                    const index = _findIndex(this.members.data, { id: member.id })
                    this.members.data.splice(index, 1)
                } catch (error) {
                    this.$message.error(this.$t('inviteInOrgaization.removeMember.error'))
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getContentSubDomain(window.location.host) : route.params.organization}/invitations`,
        }),
    }
</script>
