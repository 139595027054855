// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="col-md-6 offset-md-3">
        <div class="card p-05 mt-3 mb-5 border-0 cast-thick-shadow">
            <div class="card-block text-center">
                <span class="h2">{{ $t('confirmInvitation.title') }}</span>
            </div>
            <div class="card-block">
                <el-alert
                    :closable="false"
                    type="info"
                    :title="$t('confirmInvitation.titleAlert')"
                    class="my-2 mb-3"
                    show-icon
                >
                    <div class="d-inline">
                        {{ $t('confirmInvitation.content1') }}
                        <nuxt-link :to="toOrganization">
                            <strong>{{ organization.name }}</strong>
                        </nuxt-link> {{ $t('confirmInvitation.content2') }}
                        <strong class="text-capitalize">{{ organization.pivot.role }}</strong>
                        {{ $t('confirmInvitation.content3') }}
                        <nuxt-link :to="toInviter">
                            <strong>{{ organization.inviter.data.name }}</strong>
                        </nuxt-link>
                        <span>&nbsp; {{ organization.pivot.created_at | humanizeTime }}</span>.
                    </div>
                </el-alert>
                <form class="form-horizontal">
                    <div class="form-group">
                        <div class="d-flex justify-content-center">
                            <el-button type="success" @click="accept">
                                {{ $t('confirmInvitation.accept') }}
                            </el-button>
                            <el-button @click="decline">
                                {{ $t('confirmInvitation.decline') }}
                            </el-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { organization as toOrganization, user as toUser } from '~/utils/url'
    import { handlePageError } from '~/utils/pages'
    import { getInvitation, acceptInvitation, declineInvitation } from '~/api/invitations'
    import { loginWithSSR } from '~/utils/login'

    export default {
        middleware: 'auth',

        computed: {
            toOrganization() {
                return toOrganization(this.organization)
            },
            toInviter() {
                return toUser(this.organization.inviter.data)
            },
        },

        async asyncData({
            route, error, store, req, redirect,
        }) {
            try {
                const slug = route.params.organization
                const username = route.params.user
                const organization = await getInvitation(slug, username).then(({ data }) => data.data)

                return { organization, slug, username }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },

        methods: {
            async accept() {
                try {
                    await acceptInvitation(this.slug, this.username)
                    this.$message.success('Success')
                    this.$router.push('/settings/organizations')
                } catch (e) {
                    this.handleError(e)
                }
            },

            async decline() {
                try {
                    await declineInvitation(this.slug, this.username)
                    this.$message.success('Success')
                    this.$router.push('/')
                } catch (e) {
                    this.handleError(e)
                }
            },

            handleError({ response }) {
                if (response) {
                    this.$message.error(response.statusText)
                } else {
                    this.$message.error('Something went wrong.')
                }
            },
        },
    }
</script>
