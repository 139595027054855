// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog :show.sync="show" :title="$t(title)" @close="reset">
        <div v-if="formError.length" class="alert alert-danger">
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click="formError=[]"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <ul class="mb-0">
                <li v-for="(error, index) in formError" :key="index">
                    {{ error }}
                </li>
            </ul>
        </div>

        <dropzone
            ref="dropzone"
            :params="getParams"
            @error="onUploadFailed"
            @success="onUploadSucceeded"
        />
        <CheckboxWatermark v-model="has_watermark" />
        <div v-if="images" class="images-thumbnails-container">
            <h4 class="mt-1 text-muted">
                {{ $t('dialogUpload.yourPhoto') }}
            </h4>
            <div class="image-thumbnails row mt-1">
                <div v-for="(image, index) in images" :key="index" class="col-md-2 col-xs-3 mb-1">
                    <a href="#" class="thumbnail" @click.prevent="insertImage(image)">
                        <img
                            :src="image.thumbnail"
                            alt="Thumbnail Image"
                            class="w-100"
                        >
                    </a>
                </div>
            </div>
            <pagination
                v-if="pagination"
                :nuxt="false"
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
                @click-page-item="(page) => getUploads(page)"
            />
        </div>

        <div v-else class="text-center">
            <i class="fa fa-spinner fa-5x fa-spin" aria-hidden="true" />
        </div>

        <div slot="footer">
            <el-button size="medium" @click="close">
                {{ $t('dialogUpload.cancel') }}
            </el-button>
        </div>
    </v-dialog>
</template>

<script>
    import _get from 'lodash/get'
    import { getImages } from 'viblo-sdk/api/me'

    import Pagination from '~/components/Pagination.vue'
    import Dropzone from '~/components/Dropzone.vue'
    import CheckboxWatermark from '~/components/shared/CheckboxWatermark.vue'

    export default {
        components: {
            Dropzone,
            Pagination,
            CheckboxWatermark,
        },

        props: {
            title: {
                type: String,
                default: 'dialogUpload.title',
            },
        },

        data() {
            return {
                show: false,
                images: [],
                pagination: null,
                formError: [],
                has_watermark: false,
            }
        },

        methods: {
            open() {
                this.getUploads()
                this.show = true
            },

            close() {
                this.show = false
            },

            reset() {
                this.$refs.dropzone.$_dropzone.removeAllFiles()
                this.formError = []
            },

            onUploadSucceeded(file, response) {
                this.images = [response.data, ...this.images]
                this.formError = []
                this.$refs.dropzone.$_dropzone.removeFile(file)
            },

            onUploadFailed(file, error) {
                const validationMessages = _get(error, 'errors.file')

                this.formError = validationMessages || []
            },

            async getUploads(page = 1) {
                const { data: images, meta: { pagination } } = await getImages({ limit: 18, page })

                this.images = images
                this.pagination = pagination
            },

            insertImage(image) {
                this.$emit('image-picked', image)

                this.close()
            },

            getParams() {
                return {
                    has_watermark: this.has_watermark,
                }
            },
        },
    }
</script>

<style>
    .image-thumbnails {
        max-height:calc(80vh - 250px);
        overflow-y: auto;
    }

    .image-thumbnails .thumbnail {
        display: inline-block;
    }
</style>
