// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="topMembers.length <= 0 && current.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />

        <div v-else>
            <div class="mb-3">
                <TopMembers
                    v-if="topMembers.length > 0"
                    :members="topMembers"
                    :is-follower="true"
                />
            </div>
            <Members
                :user-ids="current"
            />
            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import TopMembers from '~/components/subdomains-theme-2/members/TopMembers.vue'
    import Members from '~/components/subdomains-theme-2/members/UserList.vue'

    export default {
        name: 'OrganizationMembers',

        components: {
            TopMembers,
            Members,
            InfiniteLoading,
        },

        data() {
            return {
                isLoading: false,
            }
        },

        watchQuery: ['page'],

        scrollToTop: true,

        computed: {
            ...mapState('organizationView/topMembers', { topMembers: 'topMembers' }),
            ...mapState('organizationView/members', ['current', 'pagination']),
        },

        async fetch() {
            this.isLoading = true

            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error

            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            try {
                await store.dispatch('organizationView/topMembers/fetch', orgSubdomain)

                return await store.dispatch('organizationView/members/fetch', {
                    slug: orgSubdomain,
                    params: {
                        limit: store.state.settings.perPage,
                        page,
                        isLoadMore: true,
                    },
                })
            } catch (err) {
                return handlePageError(error, err)
            } finally {
                this.isLoading = false
            }
        },

        beforeDestroy() {
            this.$store.dispatch('organizationView/members/resetData')
            this.$store.dispatch('organizationView/topMembers/resetData')
        },

        methods: {
            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/members`,
        }),
    }
</script>

<style lang="scss">
.members {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 32px;

    &-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;
        row-gap: 64px;

        &--card {
            width: calc(100% / 4 - 24px);
        }

        @media screen and (max-width: 1024px) {
            gap: 16px;
            row-gap: 64px;

            &--card {
                width: calc(100% / 3 - 11px);
            }
        }

        @media screen and (max-width: 640px) {
            gap: 16px;
            row-gap: 64px;

            &--card {
                width: calc(100% / 2 - 8px);
            }
        }

        @media screen and (max-width: 480px) {
            row-gap: 64px;

            &--card {
                width: 100%;
            }
        }
    }
}

.theme-dark {
    .members {
        background-color: #100F09;
    }
}
</style>

<style scoped>
.loading {
    position: unset;
    width: 100%;
    text-align: center;
    font-size: 24px;
}
</style>
