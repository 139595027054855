// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <DraftsList :posts="posts" :pagination="pagination" />
</template>

<script>
    import { fetchSubmittedDrafts } from '~/api/organizations'
    import DraftsList from '~/components/organizations/drafts/List.vue'
    import { getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            DraftsList,
        },

        watchQuery: ['page'],

        scrollToTop: true,

        async asyncData({
            store, params, query, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getContentSubDomain(host) : params.organization

            const posts = await fetchSubmittedDrafts(orgSubdomain, query).then(({ data }) => data)

            return {
                posts: posts.data,
                pagination: posts.meta.pagination,
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Drafts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getContentSubDomain(window.location.host) : route.params.organization}/drafts`,
        }),
    }
</script>
