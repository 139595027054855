// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="videos.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else class="post-feed">
        <videoPin :org-subdomain="orgSubdomain" :is-owner="isOwner || isAdmin" />
        <div class="py-4 subdomain-list-videos">
            <div class="d-flex align-items-center">
                <h1 class="title-all-posts">
                    {{ $t('allVideos') }}
                </h1>
                <div class="flex-grow-1 pagination-top">
                    <pagination
                        :current-page="pagination.current_page"
                        :last-page="pagination.total_pages"
                    />
                </div>
            </div>
            <div class="row">
                <div
                    v-for="(video, index) in videos"
                    :key="index"
                    class="col-md-4 py-1"
                >
                    <VideoFeedItem
                        :post="video"
                        :post-small="true"
                        :size-title="'mini'"
                        :org-subdomain="orgSubdomain"
                        :is-owner="isOwner || isAdmin"
                    />
                </div>
            </div>

            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Empty from '~/components/shared/Empty.vue'
    import { fetchVideoOrganizationSubdomain } from '~/api/organizations'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import videoPin from '~/components/subdomains/posts/VideoPin.vue'
    import VideoFeedItem from '~/components/subdomains/posts/PostPinItem.vue'

    export default {
        name: 'PostFeed',

        components: {
            Pagination,
            Empty,
            videoPin,
            VideoFeedItem,
        },

        props: {
            isOwner: {
                type: Boolean,
                default: false,
            },
            isAdmin: {
                type: Boolean,
                default: false,
            },
        },

        watchQuery: ['page'],


        computed: {
            ...mapState('settings', ['layout']),
        },

        async asyncData({
            store, params, query, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const videos = await fetchVideoOrganizationSubdomain(orgSubdomain, query)
                    .then(({ data }) => data)

                return {
                    videos: videos.data,
                    orgSubdomain,
                    pagination: videos.meta.pagination,
                }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Videos',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}`,
        }),
    }
</script>

<style lang="scss">
.video-feed-org {
    padding: 2.5rem;
    background-color: rgba(0, 46, 71, 1);
    border-radius: 12px;
}
@media screen and (max-width: 768px) {
    .video-feed-org {
        padding: 1rem;
    }
    .subdomain-list-videos {
        .pagination-top {
            display: none;
        }
    }
}

.theme-dark {
    .video-feed-org {
        background-color: #353535;
    }

    .title-all-posts {
        color: #fff;
    }
}
</style>
