// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="subscribe">
        <slot :subscribe="handle" :disabled="processing" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import _get from 'lodash/get'

    export default {
        props: {
            subscribable: Object,
            organization: Object,
            send: {
                type: Function,
                required: true,
            },
            isOwn: Boolean,
        },

        data: () => ({
            processing: false,
        }),

        computed: {
            ...mapGetters('auth', ['authenticated']),
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),

            handle(value) {
                if (!this.isOwn) {
                    return !this.authenticated
                        ? this.showAuthDialog()
                        : this.sendSubscribe(value)
                }
                return 0
            },

            async sendSubscribe(value) {
                try {
                    this.processing = true
                    const response = await this.send(value)
                    this.sendGaEvent()

                    return response
                } catch (error) {
                    this.$message.error('Something went wrong. Please try again later.')

                    if (!error.response) {
                        throw error
                    }

                    return null
                } finally {
                    this.processing = false
                }
            },

            sendGaEvent() {
                if (this.subscribable) {
                    const organizationTrackingID = _get(this.organization, 'google_analytics_id')
                    const analyticsData = {
                        eventCategory: 'Interaction',
                        eventAction: `Subscribe ${this.subscribable.type}`,
                        eventLabel: this.subscribable.id,
                    }

                    this.$gtag.event('interaction', {
                        sent_to: [
                            process.env.GOOGLE_ANALYTICS_TRACK_ID,
                            organizationTrackingID,
                        ],
                        items: analyticsData,
                    })
                }
            },
        },
    }
</script>
