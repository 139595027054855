// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div @paste="onPaste">
        <v-dialog :show.sync="show" :size="sizeDialog" @close="reset">
            <div slot="title" class="mb-1">
                <span class="dialog-custom-heading"> {{ $t(title) }} </span>
                <el-popover
                    placement="right-end"
                    width="300"
                    trigger="hover"
                >
                    <el-button
                        slot="reference"
                        type="text"
                        icon="el-icon-question my-05 px-05"
                        style="font-size: 1.3rem"
                    />

                    <div style="text-align:left; word-break: break-word;">
                        <p>
                            {{ $t('dialogDetect.description') }}
                        </p>
                        <p class="text-danger warning-text">
                            {{ $t('dialogDetect.note') }}
                        </p>
                    </div>
                </el-popover>
            </div>

            <div v-if="formError.length" class="alert alert-danger">
                <el-button
                    class="dialog-detect-close d-flex justify-content-center align-items-center"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="formError=[]"
                >
                    <span aria-hidden="true">&times;</span>
                </el-button>
                <ul class="mb-0">
                    <li v-for="(error, index) in formError" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <div v-if="imagePreview" class="d-flex flex-column align-items-center">
                <img id="preview" class="border w-100">
                <el-button
                    size="small"
                    type="danger"
                    plain
                    class="mt-1"
                    @click="closeImagePreview"
                >
                    {{ $t('profile.embed.close') }}
                </el-button>
            </div>
            <div
                v-else
                class="position-relative"
                @mouseover="mouseMove"
            >
                <el-input
                    id="input-paste-image"
                    class="position-absolute"
                />
                <dropzone
                    ref="dropzone"
                    class="position-relative"
                    url="/api/matex/detect"
                    :default-message="this.$t('dialogDetect.dropzone')"
                    :upload-multiple="false"
                    @error="onUploadFailed"
                    @success="onUploadSucceeded"
                />
            </div>

            <el-button v-if="showLoading" :loading="showLoading" class="button-loading-detection" />

            <div v-if="matexString">
                <p class="mt-4 font-weight-bold">
                    {{ $t('dialogDetect.result') }}
                </p>

                <div class="d-flex justify-content-center my-3">
                    <Markdown :contents="matexString" />
                </div>

                <el-input v-model="matexString" />

                <div class="d-flex justify-content-center mt-2">
                    <el-button type="primary" @click="insertMatexString">
                        {{ $t('dialogDetect.done') }}
                    </el-button>
                </div>
            </div>

            <div slot="footer">
                <el-button size="medium" @click="close">
                    {{ $t('dialogUpload.cancel') }}
                </el-button>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { uploadFileDetect } from '~/api/uploads'

    import Dropzone from '~/components/Dropzone.vue'
    import Markdown from '~/components/contents/Markdown.vue'
    import editorMixins from '~/utils/editor/mixin'

    export default {
        components: {
            Dropzone,
            Markdown,
        },

        mixins: [editorMixins],

        props: {
            title: {
                type: String,
                default: 'dialogDetect.title',
            },
        },

        data() {
            return {
                show: false,
                formError: [],
                matexString: '',
                imagePreview: false,
                showLoading: false,
                sizeDialog: 'fit-content',
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            })
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
        },

        methods: {
            open() {
                this.matexString = ''
                this.show = true
            },

            close() {
                this.show = false
            },

            reset() {
                this.imagePreview = false
                setTimeout(() => {
                    this.$refs.dropzone.$_dropzone.removeAllFiles()
                    this.formError = []
                }, 10)
            },

            onUploadSucceeded(file, response) {
                if (response.data) {
                    this.matexString = `$$${response.data}$$`
                    this.formError = []
                } else {
                    this.formError = [this.$t('dialogDetect.notDetectFomulas')]
                }
            },

            onUploadFailed(file, error) {
                const validationMessages = _get(error, 'errors.file')

                this.formError = validationMessages || []
            },

            insertMatexString() {
                this.$emit('matex-picked', this.matexString)

                this.close()
            },

            async onPaste(event) {
                const file = this.getImageFromClipboard(event)

                if (!file) {
                    this.$message.error(this.$t('editor.actions.invalidImage'))
                    return
                }

                this.imagePreview = true
                this.showLoading = true
                setTimeout(() => {
                    const imageEle = document.getElementById('preview')
                    imageEle.src = URL.createObjectURL(file)
                }, 100)

                if (file && !this.isUploading) {
                    const formData = new FormData()

                    formData.append('file', file)
                    try {
                        const data = await uploadFileDetect(formData).then(response => response.data.data)
                        this.matexString = `$$${data}$$`
                        this.formError = []
                        this.showLoading = false
                    } catch (err) {
                        this.formError = [this.$t('dialogDetect.notDetectFomulas')]
                    }
                }
            },

            closeImagePreview() {
                this.imagePreview = false
                this.matexString = ''
            },

            mouseMove() {
                document.getElementById('input-paste-image').focus()
            },

            onResize() {
                if (window.innerWidth < 700) {
                    this.sizeDialog = 'full'
                } else {
                    this.sizeDialog = 'fit-content'
                }
            },
        },
    }
</script>

<style>
    .image-thumbnails {
        max-height:calc(80vh - 250px);
        overflow-y: auto;
    }

    .image-thumbnails .thumbnail {
        display: inline-block;
    }

    .dialog-detect-close {
        width: 16px;
        height: 16px;
    }

    .dialog-custom-heading {
        margin: 0;
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        font-weight: 500;
    }

    .warning-text {
        font-size: 12px;
    }

    .button-loading-detection {
        border: none;
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 2rem;
        margin-top: 2rem;
    }
</style>
