// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <el-form
            ref="form"
            :model="form"
            :rules="validations"
            size="medium"
            class="error-message--top-end"
            @submit.native="handleSubmit"
        >
            <v-row>
                <v-col
                    :md="3"
                    :class="{ 'logo-picker has-error': clickedSubmit && emptyAvatar }"
                    class="d-flex align-items-center justify-content-center flex-column"
                >
                    <div class="avatar-picker" @click.prevent="() => $refs.changeAvt.open()">
                        <avatar
                            :image="organization.avatar"
                            size="xxl"
                            class="d-block mx-0"
                        />
                    </div>
                    <p v-if="clickedSubmit && emptyAvatar" class="required-avt">
                        {{ $t('createOrganization.requiredAvt') }}
                    </p>
                </v-col>
                <v-col :md="9">
                    <el-form-item
                        :error="serverErrors.name"
                        prop="name"
                        :label="$t('createOrganization.inputName.title')"
                    >
                        <el-input v-model="form.name" @input="generateSlug" />
                    </el-form-item>

                    <el-form-item
                        :error="serverErrors.google_analytics_id"
                        prop="google_analytics_id"
                        :label="$t('createOrganization.inputTrackingID.title')"
                    >
                        <span v-tooltip class="stats-item" :title="$t('createOrganization.googleAnalyticHelp')">
                            <i class="fa fa-question-circle" aria-hidden="true" />
                        </span>
                        <el-input
                            v-model="form.google_analytics_id"
                            :disabled="disabledGAID"
                            @change="(googleAnalyticsID) => disabledGAID = !!googleAnalyticsID"
                        >
                            <i
                                v-if="organization.google_analytics_id || form.google_analytics_id"
                                slot="suffix"
                                class="icon-editable el-icon-edit el-input__icon cursor-pointer"
                                @click="disabledGAID = !disabledGAID"
                            />
                        </el-input>
                    </el-form-item>
                </v-col>
            </v-row>
            <v-row class="mt-05">
                <v-col :md="12">
                    <el-form-item
                        :error="serverErrors.slug"
                        prop="slug"
                        :label="$t('createOrganization.inputURL.title')"
                    >
                        <el-input v-model="form.slug" :disabled="disableSlug">
                            <template slot="prepend">
                                https://viblo.asia/o/
                            </template>
                            <template v-if="organization.slug" slot="append">
                                <i class="el-icon-edit mr-2" @click="confirmEditSlug" />
                                <nuxt-link :to="toOrganization(organization)">
                                    <i class="fa fa-external-link" />
                                </nuxt-link>
                            </template>
                        </el-input>
                    </el-form-item>

                    <el-form-item
                        :error="serverErrors.website"
                        prop="website"
                        :label="$t('createOrganization.inputWebsite.title')"
                    >
                        <el-input v-model="form.website" />

                        <div v-if="organization.website && !organization.website_verified" class="text-warning">
                            {{ $t('createOrganization.verify.first') }}
                            <nuxt-link
                                class="text-warning font-weight-bold"
                                :to="`/o/${organization.slug}/info/website-verify`"
                            >
                                <u>{{ $t('createOrganization.verify.between') }}</u>
                            </nuxt-link>
                            {{ $t('createOrganization.verify.last') }}
                        </div>
                    </el-form-item>

                    <el-form-item
                        prop="layout"
                        class="mt-1"
                    >
                        <div class="setting-layout">
                            <div class="setting-layout--label">
                                <span class="setting-layout--label---title">
                                    {{ $t('createOrganization.layout.title') }}
                                </span>
                                <span class="setting-layout--label---desc">
                                    {{ $t('createOrganization.layout.detail') }}
                                </span>
                            </div>
                            <div class="setting-layout--content">
                                <div class="setting-layout--content---input">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <input
                                            v-model="form.layout"
                                            type="radio"
                                            value="default"
                                            class="mr-05"
                                        >
                                        <span>{{ $t('createOrganization.layout.options.default') }}</span>
                                    </div>
                                    <div class="d-flex justify-content-start align-items-center">
                                        <input
                                            v-model="form.layout"
                                            type="radio"
                                            value="layout-subdomains-2"
                                            class="mr-05"
                                        >
                                        <span>{{ $t('createOrganization.layout.options.layoutSubdomain2') }}</span>
                                    </div>
                                </div>
                                <div class="setting-layout--content---preview">
                                    <img
                                        src="~assets/images/layout1-profile.png"
                                        :class="{'active': form.layout === 'default'}"
                                        @click="() => {
                                            form.layout = 'default'
                                        }"
                                    >
                                    <img
                                        src="~assets/images/layout2-profile.png"
                                        :class="{'active': form.layout === 'layout-subdomains-2'}"
                                        @click="() => {
                                            form.layout = 'layout-subdomains-2'
                                        }"
                                    >
                                </div>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item
                        :error="serverErrors.location"
                        prop="location"
                        :label="$t('createOrganization.inputLocation.title')"
                    >
                        <el-input v-model="form.location" />
                    </el-form-item>

                    <el-form-item
                        :error="serverErrors.short_description"
                        prop="short_description"
                        :label="$t('createOrganization.inputShotDescription.title')"
                        class="mt-1"
                    >
                        <el-input
                            v-model="form.short_description"
                            type="textarea"
                            :placeholder="$t('createOrganization.inputShotDescription.placeholder')"
                        />
                    </el-form-item>

                    <el-form-item
                        :error="serverErrors.full_description"
                        prop="full_description"
                        :label="$t('createOrganization.inputFullDescription.title')"
                        class="mt-1"
                    >
                        <el-input
                            v-model="form.full_description"
                            :autosize="{ minRows: 5}"
                            :placeholder="$t('createOrganization.inputFullDescription.placeholder')"
                            type="textarea"
                        />
                    </el-form-item>

                    <div class="user-setting-buttons float-right mt-2">
                        <el-button
                            :loading="processing"
                            size="medium"
                            type="primary"
                            plain
                            @click="handleSubmit"
                        >
                            {{ $t(labelSaveButton) }}
                        </el-button>
                    </div>
                </v-col>
            </v-row>
        </el-form>
        <ChangeAvatarDialog
            ref="changeAvt"
            @getIdImage="(avatar) => form.avatar_id = avatar.id"
            @removeImage="() => form.avatar_id = null"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _flow from 'lodash/flow'
    import _pick from 'lodash/fp/pick'
    import _assign from 'lodash/fp/assign'
    import { slugify } from '~/utils/text'
    import formMixin from '~/utils/form/mixin'
    import { organization as toOrganization } from '~/utils/url'
    import ChangeAvatarDialog from '~/components/dialogs/ChangeAvatar.vue'
    import Avatar from '~/components/shared/Avatar.vue'

    export default {
        components: {
            ChangeAvatarDialog,
            Avatar,
        },

        mixins: [formMixin],

        props: {
            organization: {
                type: Object,
                default: () => ({ contents: '', google_analytics_id: null, slug: null }),
            },

            labelSaveButton: {
                type: String,
                default: 'createOrganization.save',
            },

            save: {
                type: Function,
                required: true,
            },
        },

        data() {
            const avatar = _get(this.organization, 'avatar')
            const avatarId = avatar ? avatar.split('.')[0] : null
            const form = _flow(
                _pick(['name', 'slug', 'short_description', 'full_description', 'google_analytics_id', 'website', 'location', 'layout']),
                _assign({ avatar_id: avatarId })
            )(this.organization)

            const validations = {
                name: [
                    { required: true, message: this.$t('createOrganization.inputName.required') },
                    { max: 150, message: this.$t('createOrganization.inputName.max') },
                ],
                slug: [
                    { required: true, message: this.$t('createOrganization.inputURL.required') },
                    { max: 255, message: this.$t('createOrganization.inputURL.max') },
                ],
                website: [
                    { max: 253, message: this.$t('createOrganization.inputWebsite.max') },
                    { type: 'url', message: this.$t('createOrganization.inputWebsite.type') },
                ],
                location: [
                    { required: true, message: this.$t('createOrganization.inputLocation.required') },
                    { max: 255, message: this.$t('createOrganization.inputLocation.max') },
                ],
                google_analytics_id: [
                    { max: 20, message: this.$t('createOrganization.inputTrackingID.max') },
                ],
                short_description: [
                    { required: true, message: this.$t('createOrganization.inputShotDescription.required') },
                    { max: 500, message: this.$t('createOrganization.inputShotDescription.max') },
                ],
                full_description: [
                    { required: true, message: this.$t('createOrganization.inputFullDescription.required') },
                    { min: 200, message: this.$t('createOrganization.inputFullDescription.min') },
                    { max: 5000, message: this.$t('createOrganization.inputFullDescription.max') },
                ],
            }

            return {
                form,
                validations,
                disabledGAID: !!form.google_analytics_id,
                clickedSubmit: false,
                disableSlug: !!this.organization.slug,
                myCroppa: null,
                imgUrl: '',
            }
        },

        computed: {
            emptyAvatar() {
                return !this.form.avatar_id && !this.organization.avatar
            },
        },

        methods: {
            generateSlug(displayName) {
                if (this.organization.user_id) return
                this.form.slug = slugify(displayName)
            },

            clearErrors() {
                this.$refs.form.clearValidate()
                this.serverErrors = {}
            },

            confirmEditSlug() {
                this.$confirm(this.$t('createOrganization.confirmEditSlug.title'), this.$t('createOrganization.confirmEditSlug.content'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => { this.disableSlug = false })
            },

            handleSubmit() {
                this.clickedSubmit = true
                if (!this.emptyAvatar) {
                    this.submit(this.$refs.form, this.store)
                }
            },

            async store() {
                this.processing = true

                try {
                    await this.save(this.form)
                    this.$message.success(this.$t('createOrganization.updatesuccess'))
                    this.clearErrors()

                    window.location.replace('/')
                } catch (error) {
                    this.handleError(error)
                } finally {
                    this.processing = false
                }
            },
            toOrganization,
        },
    }
</script>

<style lang="css">
    .el-input.is-disabled .icon-editable {
        cursor: pointer !important;
    }
    .has-error .avatar-picker img {
        border: solid 1px #f56c6c;
    }
    .has-error:after {
        display: inline-block;
        color: #f56c6c;
        font-size: 12px;
        margin-top: 0.5rem;
    }

    .profile-image img {
        border-radius: 0%;
    }

    .required-avt {
        display: inline-block;
        color: #f56c6c;
        font-size: 12px;
        margin-top: 0.5rem;
    }

    .setting-layout {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: 765px) {
            flex-direction: column;
        }

        &--label {
            display: flex;
            flex-direction: column;
            width: 50%;

            @media only screen and (max-width: 765px) {
                width: 100%;
            }

            &---desc {
                color: #9b9b99;
                line-height: 1.5rem;
            }
        }

        &--content {
            &---input {
                display: flex;
                justify-content: space-around;
                align-items: center;
                cursor: pointer;
            }

            &---preview {
                display: flex;
                justify-content: flex-end;
                gap: 8px;

                img {
                    max-width: 170px;
                    max-height: 173px;
                    cursor: pointer;

                    &.active {
                        border: 1px solid #409EFE;
                        border-radius: 12px;
                    }

                    @media only screen and (max-width: 420px) {
                        max-width: 130px;
                        max-height: 130px;
                    }
                }
            }
        }
    }
</style>
