// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        class="series-editor-add-posts modal-body-fit-screen"
        :title="$t('editor.actions.linkPopup.title')"
    >
        <el-select
            v-model="value"
            class="select-post"
            :loading="loading"
            :remote-method="fetchData"
            :placeholder="$t('editor.actions.linkPopup.placeholder')"
            filterable
            default-first-option
            allow-create
            popper-class="popper-lists-post"
            @change="insert($event)"
        >
            <el-option
                v-for="(item, index) in data"
                :key="index"
                :value="item.url"
                :label="item.title"
            />
        </el-select>
    </v-dialog>
</template>

<script>
    import { fetchUserPosts } from '~/api/users'

    export default {
        data() {
            return {
                show: false,
                value: '',
                loading: false,
                data: [],
            }
        },

        async mounted() {
            await this.fetchData()
        },


        inject: ['actions'],

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
                this.value = ''
            },

            insert(value) {
                this.$emit('insertLinkPost', value)
            },

            async fetchData() {
                try {
                    this.loading = true
                    const page = this.pagination ? this.pagination.current_page + 1 : 1
                    const data = await fetchUserPosts(
                        this.$store.state.auth.user.username,
                        'posts',
                        {
                            page,
                            ...this.$route.query,
                        }
                    ).then(({ data: res }) => res)
                    this.data = data.data
                } catch (e) {
                    this.data = []
                } finally {
                    this.loading = false
                }
            },
        },
    }
</script>

<style lang="scss">
    .select-post {
     width: 100%;
    }

    .popper-lists-post {
        left: 31%;
        right: 31%;
    }
</style>
