// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ul :class="`participants--${size}`" class="list-unstyled mb-0 d-flex align-items-center">
        <li
            v-for="user in previewUsers"
            :key="user.id"
            v-tooltip
            :title="user.name"
            class="participants__item"
        >
            <Avatar :image="user.avatar" :username="user.username" :size="size" />
        </li>

        <li v-if="more">
            <el-button
                size="mini"
                class="participants__more"
                circle
                @click="$emit('click-more-button')"
            >
                {{ count }}
            </el-button>
        </li>
    </ul>
</template>

<script>
    import _slice from 'lodash/slice'
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            users: {
                type: Array,
                default: () => [],
            },
            limit: {
                type: Number,
                default: 5,
            },
            size: {
                type: String,
                default: 'xs',
            },
            showMore: {
                type: Boolean,
                default: true,
            },
            total: {
                type: Number,
                default: 0,
            },
            isOwner: {
                type: Object,
            },
            link: {
                type: String,
                default: null,
            },
        },

        computed: {
            previewUsers() {
                return _slice(this.users, 0, this.limit)
            },

            more() {
                return this.showMore && this.users.length > this.limit
            },

            count() {
                const more = this.total === 0 ? this.users.length - this.limit : this.total - this.limit
                return more > 9 ? '9+' : `+${more}`
            },
        },
    }
</script>

<style lang="scss">
    @import '~assets/sass/variables';

    .participants {
        &__item {
            margin-right: .3rem;
        }

        &__more {
            &.el-button {
                padding: 0;
            }

            &:before {
                content: '';
                float: left;
                padding-top: 100%;
            }
        }

        &--xs {
            .participants__more.el-button {
                width: $avatar-size-xs;
                line-height: $avatar-size-xs - 2;
                font-size: 9px;
            }
        }

        &--md {
            .participants__more.el-button {
                width: $avatar-size-md;
                line-height: $avatar-size-md - 2;
            }
        }
    }
</style>
