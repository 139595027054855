// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="about-us p-2">
        <about-organization :organization="organization" :is-owner="isOwner" class="about-us-subdomain" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { normalize } from 'normalizr'
    import _get from 'lodash/get'
    import { fetchOrganization } from '~/api/organizations'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import { organization as normalizeOrganization } from '~/store/__utils/schemas'

    import AboutOrganization from '~/components/organizations/AboutOrganization.vue'

    export default {
        name: 'AboutUs',

        components: {
            AboutOrganization,
        },

        scrollToTop: true,

        computed: {
            ...mapState({
                organization(state, getters) {
                    return getters['entities/organizations/get'](this.organizationId)
                },
            }),

            organizations() {
                return this.$store.state.organizationsUser.organizations
            },

            isOwner() {
                return !!this.organizations.find(item => item.slug === this.organization.slug && _get(item, 'pivot.role') === 'owner')
            },
        },

        async asyncData({
            store, error, params, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const organization = await fetchOrganization(orgSubdomain)
                    .then(({ data }) => data)

                const normalized = normalize(organization, normalizeOrganization)
                store.commit('entities/organizations/put', normalized.entities.companies)

                return { organizationId: normalized.result }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Description',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/info`,
        }),
    }
</script>

<style lang="scss">
.about-us {
    .about-us-subdomain {
        background-color: #fff;
        border-radius: 8px;
        padding: 32px;
   }

    .md-contents {
        font-size: 16px !important;
    }
}

.theme-dark {
   .about-us-subdomain {
      color: #fff;
      background-color: #100F09;
   }
}
</style>
