// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="row member-search">
        <div class="col-md-6 d-flex ml-auto ">
            <div class="d-flex ml-05">
                <el-select
                    v-model="filteredRole"
                    size="small"
                    placeholder="Select"
                    @change="debouncedSearch"
                >
                    <el-option
                        v-for="role in roles"
                        :key="role.value"
                        :label="$t(role.text)"
                        :value="role.value"
                    />
                </el-select>
            </div>
            <div class="seach-input ml-05">
                <el-input
                    v-model="value"
                    size="small"
                    :placeholder="$t('inviteInOrgaization.existingMember.search')"
                    @input="debouncedSearch"
                >
                    <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                    />
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import _debounce from 'lodash/debounce'

    const roles = [
        { text: 'inviteInOrgaization.owner', value: 'owner' },
        { text: 'inviteInOrgaization.admin', value: 'admin' },
        { text: 'inviteInOrgaization.writer', value: 'writer' },
        { text: 'inviteInOrgaization.editor', value: 'editor' },
        { text: 'inviteInOrgaization.allRole', value: '' },
    ]

    export default {
        data() {
            return {
                roles,
                filteredRole: this.$route.query.role || '',
                value: this.$route.query.user || '',
            }
        },

        computed: {
            debouncedSearch() {
                return _debounce(this.search, 800)
            },
        },

        methods: {
            search() {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        user: this.value.trim(),
                        page: 1,
                        role: this.filteredRole,
                    }),
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.member-search {
    .seach-input {
        width: 300px;
    }
}
</style>
