// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="block-exhibition row" :class="{'row-followers-tags' : isFollower}">
        <div
            v-for="(member, index) in members"
            :key="index"
            class="followers-tags col-sm-12 col-md-6"
        >
            <user-grid-item :user-id="member.id" :is-follower="isFollower" />
        </div>
    </div>
</template>

<script>
    import UserGridItem from '~/components/subdomains/users/UserItem.vue'

    export default {
        components: {
            // Empty,
            UserGridItem,
        },
        props: {
            members: {
                type: Array,
                default: () => [],
            },
            actions: {
                type: Object,
                default: () => ({}),
            },

            isFollower: {
                type: Boolean,
                default: false,
            },
        },
        provide() {
            return {
                follow: this.actions.follow,
            }
        },
    }
</script>

<style lang="scss" scoped>
.row-followers-tags {
    margin: 0 !important;
}

</style>
