// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script lang="js">
    export default {
        functional: true,

        props: {
            score: Number,
            rated: Number,
            title: String,
        },

        render: (h, { props, data }) => (
            <div class={['points', data.class, data.staticClass]} title={ props.title } v-tooltip>
                <div class='carets'>
                    <i class={['fa fa-caret-up', { voted: props.rated > 0 }]} aria-hidden='true'></i>
                    <i class={['fa fa-caret-down', { voted: props.rated < 0 }]} aria-hidden='true'></i>
                </div>
                <span class='text-muted'>{ props.score }</span>
            </div>
        ),
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";

    .points {
        display: flex;
        align-items: center;
        cursor: default;

        .carets {
            display: inline-flex;
            flex-direction: column;
            margin-right: 0.25rem;
            padding-top: 0.3rem;

            .fa {
                font-size: 1rem;
                color: $gray-light;

                &:first-child {
                    height: 0.5rem;
                    margin-top: -0.25rem;
                }

                &.voted {
                    color: $primary;
                }
            }
        }
    }
</style>
