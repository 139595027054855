// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="organization-stats stats-subdomain pt-2 pb-2">
        <StatsHeader :organization="organization" />

        <Contribution
            :users="users"
            :header="header"
        />
    </div>
</template>

<script>
    import {
        format,
        subDays,
    } from 'date-fns'
    import { mapGetters } from 'vuex'

    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import { fetchOrganization, getOrganizationStats } from '~/api/organizations'
    import Contribution from './stats/contribution.vue'
    import StatsHeader from './stats/stats-header.vue'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        name: 'OrganizationStats',
        layout: 'subdomain/default',

        components: {
            Contribution,
            StatsHeader,
        },


        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),

            menuDefaultActive() {
                return this.$route.name
            },
        },

        async asyncData({
            store, params, error, req, query,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const res = await fetchOrganization(orgSubdomain)
                const organization = res.data

                const from = query.from || dateString(subDays(new Date(), 14))
                const to = query.to || dateString(new Date())

                const response = await getOrganizationStats(orgSubdomain, {
                    from,
                    to,
                })
                const data = response.data

                return {
                    organization,
                    users: data.users,
                    header: data.header,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        head() {
            return {
                title: this.withUnreadCount(`Stats - ${this.organization.name}`),
            }
        },

        scrollToTop: true,

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Stats',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/stats`,
        }),
    }
</script>
