// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <el-alert
            v-if="!organizations.length"
            type="warning"
            show-icon
        >
            {{ $t('organization.listJoined.first') }}
            <nuxt-link to="/organizations">
                {{ $t('organization.listJoined.organization') }}
            </nuxt-link>
            {{ $t('organization.listJoined.last') }}
        </el-alert>

        <organization-list :organizations="organizations" />

        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { getFollowings } from '~/api/organizations'
    import { handlePageError } from '~/utils/pages'
    import Pagination from '~/components/Pagination.vue'
    import OrganizationList from '~/components/organizations/followings/List.vue'
    import { loginWithSSR } from '~/utils/login'

    export default {
        middleware: 'auth',

        name: 'Followings',

        components: {
            OrganizationList,
            Pagination,
        },

        async asyncData({
            query, error, store, req, redirect,
        }) {
            try {
                const response = await getFollowings(query)

                return {
                    organizations: response.data,
                    pagination: response.meta.pagination,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },
    }
</script>
