// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <section-title :text="$t('organization.invitations.title')" class="my-2" />
        <inviting-organization-item
            v-for="(organization, index) in invitations"
            :key="index"
            :organization="organization"
            :actions="{ acceptInvitation, declineInvitation }"
        />
    </div>
</template>

<script>
    import { user as toUser } from '~/utils/url'
    import SectionTitle from '~/components/settings/SectionTitle.vue'
    import InvitingOrganizationItem from './Item.vue'

    export default {

        components: {
            SectionTitle,
            InvitingOrganizationItem,
        },
        props: {
            invitations: {
                type: Array,
                default: () => [],
            },
        },

        inject: [
            'acceptInvitation',
            'declineInvitation',
        ],

        methods: {
            toUser(user) {
                return toUser(user)
            },
        },
    }
</script>
