// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { absoluteUrl, routeToOrganization } from '~/lib/functions'
import { image as imageUrl } from '~/utils/url'

const structuredData = organization => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: organization.name,
    url: absoluteUrl(routeToOrganization(organization)),
    logo: imageUrl(organization.avatar),
    address: organization.location,
    sameAs: organization.website,
    description: organization.short_description,
})

const organizationSEO = (organization) => {
    const baseMeta = [
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:url', property: 'og:url', content: absoluteUrl(routeToOrganization(organization)) },
        { hid: 'og:site_name', property: 'og:site_name', content: organization.name },
        { hid: 'og:title', property: 'og:title', content: organization.name },
        { hid: 'og:image', property: 'og:image', content: imageUrl(organization.avatar) },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: organization.name },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: organization.short_description,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return {
        title: `${organization.name} - Viblo`,
        meta: baseMeta,
        script: [
            {
                type: 'application/ld+json',
                json: structuredData(organization),
            },
        ],
    }
}

export { organizationSEO }
