<template>
    <div>
        <AvatarsList
            :size="size"
            :users="users"
            :limit="limit"
            :show-more="showMore"
            @click-more-button="() => $refs.popup.open()"
        />
        <UsersPopup
            v-if="more"
            ref="popup"
            :users="users"
            append-to-body
        />
    </div>
</template>

<script>
    import AvatarsList from './AvatarsList.vue'
    import UsersPopup from '~/components/dialogs/Users.vue'

    export default {
        components: {
            UsersPopup,
            AvatarsList,
        },

        props: {
            users: {
                type: Array,
                default: () => [],
            },
            limit: {
                type: Number,
                default: 5,
            },
            showMore: {
                type: Boolean,
                default: true,
            },
            size: {
                type: String,
                default: 'xs',
            },
        },

        computed: {
            more() {
                return this.showMore && this.users.length > this.limit
            },
        },
    }
</script>
