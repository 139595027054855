// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="posts-wrapper pt-2 pb-2">
        <i
            v-if="posts.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <empty v-else-if="posts.length <= 0 && !loading">
            <p> {{ $t('empty') }} </p>
        </empty>
        <div v-else>
            <i
                v-if="loading"
                class="fa fa-circle-o-notch fa-spin sb-icon loading"
                aria-hidden="true"
            />
            <PostPin
                v-else
                :subdomain="orgSubdomain"
                :posts="postPins"
                :is-owner="isOwner || isAdmin"
                :on-pined="openConfirmPinOrUnPinPost"
                :on-save-pined-post="handleSavePinedPost"
                class="post-pin-list"
            />
            <div v-show="!isHiddenAbout">
                <p class="title-about-us">
                    {{ `${$t('somePicturesAboutUs')} ${organization.name}` }}
                </p>
                <About :organization-id="organizationId" :is-owner="isOwner" @checkEmptyImages="onCheckEmptyImages" />
            </div>
            <div class="post-list">
                <p class="title-all-posts">
                    {{ $t('allPosts') }}
                </p>
                <div class="container-fluid">
                    <div class="row">
                        <div v-for="(post, index) in posts" :key="index" class="col-lg-4 col-md-6 col-sm-12 post-item">
                            <PostFeedItem
                                :post="post"
                                is-post-page
                                :show-organization="false"
                                :is-owner="isOwner"
                                :on-pined="openConfirmPinOrUnPinPost"
                            />
                        </div>
                    </div>
                </div>
                <client-only>
                    <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                        <span slot="no-results" />
                        <span slot="no-more" />
                    </InfiniteLoading>
                </client-only>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import _isEmpty from 'lodash/isEmpty'
    import Empty from '~/components/shared/Empty.vue'
    import { fetchPostOrganization, getListPostPinOrganization, pinPostsOrganization } from '~/api/organizations'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import PostPin from '~/components/subdomains-theme-2/posts/PostPin.vue'
    import PostFeedItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'
    import About from '~/components/subdomains-theme-2/shared/About.vue'

    export default {
        name: 'PostFeed',

        components: {
            Empty,
            PostPin,
            About,
            PostFeedItem,
            InfiniteLoading,
        },

        props: {
            isOwner: {
                type: Boolean,
                default: false,
            },
            isAdmin: {
                type: Boolean,
                default: false,
            },
            organizationId: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                posts: [],
                pagination: null,
                orgSubdomain: '',
                postPins: [],
                loading: false,
                isHiddenAbout: false,
            }
        },

        watchQuery: ['page'],

        computed: {
            ...mapState('settings', ['layout']),
            ...mapState({
                organization(state, getters) {
                    return getters['entities/organizations/get'](this.organizationId)
                },
            }),
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                this.loading = true
                const posts = await fetchPostOrganization(orgSubdomain, page)
                    .then(({ data }) => data)

                const postPins = await getListPostPinOrganization(orgSubdomain)
                    .then(({ data }) => data)

                this.posts = [...this.posts, ...posts.data]
                this.pagination = posts.meta.pagination
                this.orgSubdomain = orgSubdomain
                this.postPins = postPins.data
                this.loading = false
            } catch (err) {
                this.loading = false
                return handlePageError(error, err)
            }
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },

            onCheckEmptyImages(images) {
                this.isHiddenAbout = _isEmpty(images) && !this.isOwner
            },

            openConfirmPinOrUnPinPost(post) {
                this.$msgbox({
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                    title: this.$t('cancelScheduled.confirm'),
                    message: post.organization_pinned_at ? this.$t('pinPostOrg.confirmPostUnPin') : this.$t('pinPostOrg.confirmPostPin'),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.handlePinOrUnPinPost(post)
                        }
                        done()
                    },
                })
            },

            async handlePinOrUnPinPost(post) {
                try {
                    if (post.organization_pinned_at) {
                        const postFiltered = this.postPins.filter(postPin => postPin.id !== post.id)
                        const newPostIds = postFiltered.map(postPin => ({
                            id: postPin.id,
                            position: postPin.organization_pinned_at,
                        }))
                        const result = await pinPostsOrganization(this.orgSubdomain, { data: newPostIds })
                            .then(({ data }) => data)

                        this.postPins = result.data
                        this.posts = this.posts.map((postItem) => {
                            if (postItem.id === post.id) {
                                return { ...postItem, organization_pinned_at: null }
                            }
                            return postItem
                        })
                        this.$message.success(this.$t('pinPostOrg.unPinSuccess'))
                    } else {
                        const postIds = this.postPins.map(postPin => ({
                            id: postPin.id,
                            position: postPin.organization_pinned_at,
                        }))
                        const baseDate = new Date('2024-07-07')
                        const dateToConvert = new Date()

                        const diffInMilliseconds = dateToConvert - baseDate
                        const diffInTimes = Math.floor(diffInMilliseconds / 1000)

                        const newPostIds = [...postIds, { id: post.id, position: diffInTimes }]
                        this.loading = true

                        const result = await pinPostsOrganization(this.orgSubdomain, { data: newPostIds })
                            .then(({ data }) => data)

                        this.postPins = result.data
                        this.posts = this.posts.map((postItem) => {
                            if (postItem.id === post.id) {
                                return { ...postItem, organization_pinned_at: diffInTimes }
                            }
                            return postItem
                        })
                        this.loading = false
                        this.$message.success(this.$t('pinPostOrg.pinSuccess'))
                    }
                } catch (error) {
                    this.loading = false
                    this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                }
            },

            handleSavePinedPost(pinnedPosts, close) {
                this.$confirm(this.$t('pinPostOrg.confirmPinPost.content'), this.$t('pinPostOrg.confirmPinPost.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(async () => {
                        try {
                            const postIds = pinnedPosts.map(pin => ({
                                id: pin.id,
                                position: pin.organization_pinned_at,
                            }))
                            const result = await pinPostsOrganization(this.orgSubdomain, { data: postIds })
                                .then(({ data }) => data)
                            this.postPins = result.data
                            this.posts = this.posts.map((postItem) => {
                                if (result.data.every(postPin => postPin.id !== postItem.id)) {
                                    return { ...postItem, organization_pinned_at: null }
                                }
                                return postItem
                            })
                            close()
                            this.$message.success(this.$t('pinPostOrg.pinSuccess'))
                        } catch {
                            this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                        }
                    })
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Posts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}`,
        }),
    }
</script>

<style lang="scss">
.posts-wrapper {
    position:relative;

    @media screen and (max-width: 1280px) {
        padding: 0 32px;
    }

    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .post-pin-list {
        margin-bottom: 60px;
    }

    .title-about-us {
        font-weight: 700;
        font-size: 30px;
        font-family: 'RobotoCondensed Medium', sans-serif;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
        }
    }

    .title-all-posts {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 30px;
        font-family: 'RobotoCondensed Medium', sans-serif;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
        }
    }

    .post-item {
        margin-top: 30px;
    }
}

.theme-dark {
    .title-about-us {
        color: #fff;
    }
}
</style>
