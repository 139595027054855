<template>
    <div class="d-flex justify-content-end mt-1">
        <el-checkbox :value="value" class="checkbox_watermark" @change="$emit('input', $event)">
            {{ $t('dialogUpload.watermark') }}
        </el-checkbox>
        <a href="/announcements/bJzKmReOZ9N" target="_blank" class="help">
            <i class="fa fa-question-circle" />
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            value: Boolean,
        },
    }
</script>

<style scoped lang="scss">
    .checkbox_watermark {
        margin-right: 10px
    }
    .help {
        color: #2c3e50
    }
</style>
