// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="existing-members">
        <HeadingTitle :title="$t('inviteInOrgaization.existingMember.title')" class="members-title" />
        <div class="existing-members--search">
            <Search />
        </div>
        <div class="overflow-auto">
            <div class="existing-members--table">
                <div class="existing-members--table---header">
                    <div class="existing-members--table---header----stt">
                        STT
                    </div>
                    <div class="existing-members--table---header----info">
                        {{ $t('inviteInOrgaization.existingMember.table.name') }}
                    </div>
                    <div class="existing-members--table---header----role">
                        {{ $t('inviteInOrgaization.existingMember.table.role') }}
                    </div>
                    <div class="existing-members--table---header----action">
                        {{ $t('inviteInOrgaization.existingMember.table.action') }}
                    </div>
                </div>
                <div class="existing-members--table---body">
                    <div v-for="(member, index) in members" :key="index" class="existing-members--table---body----row">
                        <div class="row-stt">
                            {{ index + 1 }}
                        </div>
                        <div class="row-user">
                            <UserAvatar
                                :image="member.avatar"
                                :username="member.username"
                                size="md"
                            />
                            <div class="row-user--info">
                                <p class="row-user--info---name">
                                    {{ member.name }}
                                </p>
                                <p class="row-user--info---username">
                                    <i>{{ `@${member.username}` }}</i>
                                </p>
                            </div>
                        </div>
                        <div class="row-role">
                            <Role
                                :user="member"
                                :is-owner="member.id === organization.user_id"
                                :current-role="member.pivot.role"
                                :change-role="changeRole"
                                :auth-is-owner="authIsOwner"
                                :permission="hasPermisstion(member)"
                            />
                        </div>
                        <div class="row-action">
                            <el-button
                                type="danger"
                                size="small"
                                icon="el-icon-delete"
                                class="px-05 ml-1"
                                @click="confirmRemove(member)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import UserAvatar from '~/components/shared/user/Avatar.vue'
    import Role from './Role.vue'
    import Search from './Search.vue'
    import HeadingTitle from '~/components/subdomains-theme-2/shared/HeadingTitle.vue'

    export default {
        components: {
            UserAvatar,
            Role,
            Search,
            HeadingTitle,
        },

        props: {
            members: {
                type: Array,
                default: () => [],
            },
            organization: Object,
        },

        inject: ['changeRole', 'removeMember'],

        computed: {
            ...mapGetters('auth', ['userId']),

            authIsOwner() {
                return this.userId === this.organization.user_id
            },
        },

        methods: {
            hasPermisstion(user) {
                return user.id === this.userId || this.isOwner
            },

            confirmRemove(user) {
                this.$confirm(this.$t('inviteInOrgaization.confirmRemove.title'), this.$t('inviteInOrgaization.confirmRemove.content'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.removeMember(user))
                    .catch(() => { })
            },
        },
    }
</script>

<style lang="scss" scoped>
.existing-members {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 48px;
    border-radius: 8px;
    background-color: #fff;

    &--title {
        margin-bottom: 16px;
    }

    &--search {
        margin-bottom: 16px;
    }

    &--table {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        min-width: 640px;

        &---header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #909399;
            font-weight: bold;
            border-bottom: 1px solid #ebeef5;
            padding: 12px 0;

            &----stt {
                width: 5%;
            }

            &----info {
                width: 75%;
                margin-left: 8px;
            }

            &----role {
                width: 10%;
                text-align: center;
            }

            &----action {
                width: 10%;
                text-align: center;
            }
        }

        &---body {
            display: flex;
            flex-direction: column;

            &----row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding: 12px 0;
                border-bottom: 1px solid #ebeef5;

                .row-stt {
                    width: 5%;
                }

                .row-role {
                    width: 10%;
                }

                .row-action {
                    width: 10%;
                    display: flex;
                    justify-content: center;
                }

                .row-user {
                    display: flex;
                    justify-content: flex-start;
                    width: 75%;
                    margin-left: 8px;

                    &--info {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-left: 16px;

                        &---name {
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-weight: 700;
                            margin: 0;
                            text-align: left;
                            max-width: 400px;
                        }

                        &---username {
                            color: #9b9b9b;
                            margin: 0;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
    }
}

.theme-dark {
    .existing-members {
        background-color: #100F09;
        border: 1px solid #585858;

        &--table {
            &---header {
                color: #fff;
            }
        }
    }
}
</style>
