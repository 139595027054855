// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-card">
        <div class="d-flex">
            <Avatar
                :object="organization"
                :image="organization.avatar"
                :level-partner="organization.level_partner"
                :partner-width="95"
                :partner-hight="95"
                image-size="avatar"
                size="xxl"
                class="mr-1"
            />
            <div>
                <div class="d-flex align-items-center">
                    <h1 class="user-name word-break d-inline-block">
                        {{ organization.name }}
                    </h1>

                    <div class="my-05 mt-1 d-inline-block">
                        <nuxt-link v-if="isOwner" :to="`/o/${organization.slug}/info/edit`" class="btn btn-follow">
                            {{ $t('detailOrgaization.edit') }}
                        </nuxt-link>
                        <subscribe
                            v-else
                            ref="subscribe"
                            :send="handleSubscribe"
                            :subscribable="{ type: 'Organization', id: organization.slug }"
                        >
                            <button
                                :class="{ 'following': organization.following }"
                                class="btn btn-follow"
                                @click="$refs.subscribe.handle(!organization.following)"
                            >
                                <span
                                    v-if="organization.following"
                                >
                                    <i class="fa fa-check" />
                                    {{ $t('detailOrgaization.following') }}
                                </span>
                                <span v-else><i class="fa fa-plus" /> {{ $t('detailOrgaization.follow') }} </span>
                            </button>
                        </subscribe>
                    </div>
                </div>

                <h4 v-if="organization.website && (organization.website_verified || isOwner)">
                    <a
                        :href="organization.website"
                        rel="noopener"
                        target="_blank"
                    >{{ organization.website }}</a>

                    <span v-if="organization.website_verified" v-tooltip :title="$t('verifyOrganization.verified')">
                        <i class="icon-verified el-icon-success" />
                    </span>

                    <span
                        v-if="!organization.website_verified && isOwner"
                        v-tooltip
                        class="text-warning"
                        :title="$t('verifyOrganization.noVerify')"
                    >
                        <nuxt-link
                            class="text-warning font-weight-bold"
                            :to="`/o/${organization.slug}/info/website-verify`"
                        >
                            <i class="el-icon-warning" />
                        </nuxt-link>
                    </span>
                </h4>

                <h4>{{ organization.short_description }}</h4>
                <h4 v-if="organization.description_subdomain" class="font-italic">
                    {{ $t('profile.organizations.description') }}
                    <a :href="organization.description_subdomain" target="_blank">
                        {{ organization.description_subdomain }}
                    </a>
                </h4>

                <div class="company-status">
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.posts').concat(`${organization.posts_count}`)"
                    >
                        <i class="fa fa-pencil" /> {{ organization.posts_count || 0 }}
                    </span>
                    <!-- eslint-disable -->
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.followers').concat(`${organization.followers_count}`)"
                    >
                     <!--eslint-enable-->
                        <i class="fa fa-user-plus" /> {{ organization.followers_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.member').concat(`${organization.members_count}`)"
                    >
                        <i class="fa fa-users" /> {{ organization.members_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('profile.organizations.view').concat(`${organization.postviews_count}`)"
                    >
                        <i class="fa fa-eye" /> {{ organization.postviews_count | formatNumber }}
                    </span>
                </div>
                <DescriptionLevelPartner v-if="organization.level_partner" :level="organization.level_partner" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import { organization as toOrganization } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import DescriptionLevelPartner from '~/components/DescriptionLevelPartner.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
            DescriptionLevelPartner,
        },

        props: {
            organization: {
                type: Object,
                require: true,
            },
            isOwner: Boolean,
        },

        computed: {
            ...mapState('auth', ['user']),
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),

            handleSubscribe(value) {
                return this.subscribe({ organization: this.organization, value })
            },
            toOrganization,
        },
    }
</script>

<style lang="scss" scoped>
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/mixins";

    .company-status {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: #9b9b9b;
    }

    .btn-follow {
        margin-top: -10px;
        margin-left: 25px;
        padding: 2px 10px;
    }

    .user-name {
        font-size: 1.3rem;
    }

    .user-stats {
        color: $gray-light;
        display: flex;
        justify-content: flex-start;

        span {
            flex-shrink: 0;

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .hero {
            width: 100px;
        }

        .user-name {
            font-size: 2rem;
        }
    }

    .icon-verified {
        font-size: 1.15rem;
        margin-bottom: 0.5rem;
        margin-left: 6px;
        color: #409EFF;
    }

    .avt-org-center {
        align-items: center;
    }
</style>
