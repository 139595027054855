// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-inline">
        <span v-if="prefix">{{ $t(prefix) }}</span>
        <el-popover
            :title="organization.name"
            :open-delay="600"
            placement="top-start"
            width="300"
            class="el-popover__title"
            trigger="hover"
        >
            <nuxt-link slot="reference" class="organization-name" :to="organizationPage">
                {{ organization.name }}
            </nuxt-link>

            <template>
                <div class="d-flex justify-content-between">
                    <p class="text-left text-break mr-1">
                        {{ organization.short_description || 'No description' }}
                    </p>

                    <Avatar
                        :object="organization"
                        :image="organization.avatar"
                        :level-partner="organization.level_partner"
                        :size="organization.level_partner ? 'lg-mini' : 'lg'"
                        :partner-width="50"
                        :partner-hight="50"
                        image-size="avatar"
                    />
                </div>
                <div class="d-flex justify-content-between align-items-center border-top mt-1 pt-05">
                    <div>
                        {{ $t('postBody.postInOgranziation.followedBy') }}
                        <span class="text-body">{{ stats.followers_count }}</span>
                        {{ $t('postBody.postInOgranziation.people') }}
                    </div>

                    <subscribe
                        ref="subscribe"
                        :send="(value) => subscribe({ organization: stats, value })"
                        :subscribable="{ type: 'Organization', id: organization.slug }"
                    >
                        <button
                            :class="{ 'following': !!stats.following }"
                            class="btn btn-follow"
                            @click="$refs.subscribe.handle(!stats.following)"
                        >
                            {{
                                stats.following
                                    ? $t('postBody.postInOgranziation.following')
                                    : $t('postBody.postInOgranziation.follow')
                            }}
                        </button>
                    </subscribe>
                </div>
            </template>
        </el-popover>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import _assign from 'lodash/assign'
    import { organization as toOrganization } from '~/utils/url'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
        },

        props: {
            organization: {
                type: Object,
                required: true,
            },
            prefix: {
                type: String,
                default: 'postBody.postInOgranziation.in',
            },
        },

        computed: {
            stats() {
                const stats = this.$store.getters['entities/organizations/get'](this.organization.id)
                return _assign({}, this.organization, stats)
            },

            organizationPage() {
                return toOrganization(this.organization)
            },
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),
        },
    }
</script>

<style lang="scss">
    .el-popover__title {
        text-align: left;
        word-break: break-word;
        word-spacing: 4px;
    }
    .organization-name {
        display: table-cell;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
    }

    .organization-group-avatar {
        height: 55px;
    }

    @media screen and (max-width: 400px){
        .organization-name {
            white-space: nowrap;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
