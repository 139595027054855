// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-form
        ref="form"
        :model="form"
        :disabled="disabled"
        :inline="false"
        size="medium"
        class="top-level-message"
    >
        <section-title :text="$t('inviteInOrgaization.title')" class="mb-2 invite-title" />

        <div class="d-flex align-items-center">
            <p class="text-body block">
                {{ $t('inviteInOrgaization.fistContent') }}
                <nuxt-link :to="toOrganization()">
                    {{ organization.name }}
                </nuxt-link> {{ $t('inviteInOrgaization.lastContent') }}
            </p>
        </div>

        <div>
            <el-autocomplete
                v-model="form.users"
                :fetch-suggestions="fetchSuggestions"
                :trigger-on-focus="false"
                class="inline-input d-inline-block input-username"
                :placeholder="$t('inviteInOrgaization.formSearch')"
                size="medium"
                @select="handleSelect"
            >
                <span slot-scope="props" class="d-flex user-item">
                    <UserAvatar
                        :image="props.item.avatar"
                        :username="props.item.username"
                        size="sm"
                    />

                    <div class="flex-grow-1 ml-1">
                        <span class="text-name text-dark">{{ props.item.name }}</span>
                        <span class="text-username text-muted ml-1">@{{ props.item.username }}</span>
                    </div>
                </span>
            </el-autocomplete>

            <el-form-item :error="serverErrors.role" prop="role" class="d-inline-block">
                <el-select
                    v-model="form.role"
                    name="role"
                    class="role"
                    :placeholder="$t('inviteInOrgaization.formRole')"
                >
                    <el-option :label="$t('inviteInOrgaization.admin')" value="admin" />
                    <el-option :label="$t('inviteInOrgaization.writer')" value="writer" />
                    <el-option :label="$t('inviteInOrgaization.editor')" value="editor" />
                </el-select>
            </el-form-item>

            <div class="justify-content-end d-inline-block">
                <el-button
                    :loading="processing"
                    size="medium"
                    type="primary"
                    plain
                    class="button-invite"
                    @click="submit($refs.form, inviteUsers)"
                >
                    {{ $t('inviteInOrgaization.invite') }}
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapState } from 'vuex'
    import _forEach from 'lodash/forEach'
    import _includes from 'lodash/includes'
    import _map from 'lodash/map'
    import _isEmpty from 'lodash/isEmpty'

    import { search } from '~/api/search'
    import { organization as toOrganization } from '~/utils/url'
    import { invite } from '~/api/invitations'
    import formMixin from '~/utils/form/mixin'
    import UserAvatar from '~/components/shared/user/Avatar.vue'
    import SectionTitle from '~/components/settings/SectionTitle.vue'

    export default {
        components: {
            UserAvatar,
            SectionTitle,
        },

        mixins: [formMixin],

        props: {
            organization: {
                type: Object,
                required: true,
            },
            invitations: {
                type: Array,
                default: () => [],
            },
            members: {
                type: Array,
                default: () => [],
            },
            disabled: Boolean,
        },

        data: () => ({
            isDirty: false,
            searchTimeout: null,
            loading: false,
            processing: false,
            form: { users: '', role: 'writer' },
        }),

        computed: {
            ...mapState('auth', { auth: 'user' }),
        },

        methods: {
            fetchSuggestions: (input, done) => (input
                ? search(`users?q=${input}`)
                    .then(_ => _.data)
                    .then(done)
                : done([])
            ),

            handleSelect(item) {
                this.form.users = item.username
            },

            setDirty() {
                this.isDirty = true
            },

            toOrganization() {
                return toOrganization(this.organization)
            },

            reset() {
                this.form.users = ''
            },

            async inviteUsers() {
                this.processing = true
                try {
                    const users = this.form.users ? this.form.users.split(',') : []
                    const formData = { users, role: this.form.role }
                    if (this.validateUsers(users)) {
                        await invite(this.organization.slug, formData)
                        this.$message.success(this.$t('inviteInOrgaization.success'))
                    }
                    this.reset()
                    this.$emit('saved')
                } catch (error) {
                    if (error.response.status === 403) {
                        this.$message.error(this.$t('inviteInOrgaization.error'))
                    }
                    this.handleError(error)
                } finally {
                    this.processing = false
                }
            },

            validateUsers(users) {
                if (_isEmpty(users)) {
                    this.$message.error(this.$t('inviteInOrgaization.valdateError1'))
                    return false
                }
                if (!this.checkUserNotBeenInvited(users)) {
                    this.$message.error(this.$t('inviteInOrgaization.valdateError2'))
                    return false
                }

                return true
            },

            checkUserNotBeenInvited(users) {
                const invitedMember = _map(this.invitations, 'username')
                let condition = true

                _forEach(users, (o) => {
                    condition = !_includes(invitedMember, o)

                    return condition
                })

                return condition
            },
        },
    }
</script>

<style lang="scss" scoped>
    .input-username {
        width: 350px;
    }

    .role {
        width: 110px;
    }

    .theme-dark {
        .submissions-post-subdomain {
            .invite-title, .text-body {
                color: #fff !important;
            }

            .button-invite {
                background-color: #464646;
                color: #fff;

                &:hover {
                    background-color: blue;
                    border: none;
                }
            }
        }
    }
</style>

<style lang="scss">
    @import '~assets/sass/bootstrap/colors';

    .theme-dark {
        .el-input-group__append, .el-input-group__prepend, .el-input.is-disabled .el-input__inner, .el-textarea__inner {
            background: #000;
            border: 1px solid $dark-gray;
            color: #fff;
        }

        .el-input__inner {
        background: #000;
        }

        .el-form-item__label {
            color: #fff;
        }

        .el-input__inner:focus {
            border-color: #fff;
        }

        .el-input:focus-visible {
            .el-input__inner {
                border-color: $dark-gray;
            }
        }

        .el-button--primary.is-plain {
            background-color: #000;
            color: #fff;
        }
    }
</style>
