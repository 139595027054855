<template>
    <div>
        <div class="d-flex flex-column justify-content-around chart">
            <div
                v-for="(item, index) in overview"
                :key="item.label"
                :class="{ 'separator': index < overview.length - 1 }"
                class="row py-4 chart-item"
            >
                <div class="col-12 col-sm-4 d-flex flex-sm-column" style="width: 350px">
                    <div class="mr-1">
                        <h1 class="font-weight-bold mb-0 over-view--label" style="font-size: 3.4rem">
                            {{ item.value | formatNumberThousands }}
                        </h1>

                        <div :class="{'increase' : item.diff >= 0, 'decrease' : item.diff < 0}">
                            <i
                                class="fa"
                                :class="{'fa-arrow-up' : item.diff >= 0, 'fa-arrow-down' : item.diff < 0}"
                                aria-hidden="true"
                            />
                            {{ item.diff }}%
                        </div>
                    </div>

                    <div class="mt-05 over-view--label">
                        <h4 class="font-weight-bold">
                            {{ allChartData[item.type].unit }}
                        </h4>
                        <p>
                            {{ $t('organization.stats.numberOf') }}
                            <span style="text-transform: lowercase;">
                                {{ allChartData[item.type].unit }}
                            </span>
                            {{ $t('organization.stats.from') }}
                            {{ firstStats.date }}
                            {{ $t('organization.stats.to') }}
                            {{ lastStats.date }}.
                        </p>
                    </div>
                </div>

                <Chart
                    v-if="allChartData[item.type]"
                    :chart-data="allChartData[item.type]"
                    class="col-12 col-sm-8"
                    style="height: 360px"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { format, subDays } from 'date-fns'
    import { getOrganizationGeneralStats } from '~/api/organizations'
    import Chart from './Chart.vue'
    import { withCommaInThousandsPlaces } from '~/filters/formatNumber'
    import { getContentSubDomain } from '~/utils/pages'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        components: {
            Chart,
        },

        filters: {
            formatNumberThousands(num) {
                return withCommaInThousandsPlaces(num)
            },
        },

        data() {
            return {
                statsData: null,
                statsDifferences: null,
                chartData: null,
                allChartData: {},

                statsType: [
                    { name: 'postView', code: 'views' },
                    { name: 'followers', code: 'followers' },
                    { name: 'posts', code: 'posts' },
                    { name: 'members', code: 'members' },
                ],

                currentStatsCode: 'views',
                overview: [],
                firstStats: {},
                lastStats: {},
            }
        },

        computed: {
            currentStatsName() {
                return this.statsType.find(t => t.code === this.currentStatsCode).name
            },

            query() {
                return {
                    from: this.$route.query.from || dateString(subDays(new Date(), 14)),
                    to: this.$route.query.to || dateString(new Date()),
                }
            },
        },

        watch: {
            '$route.query': function $routeQuery(valueNew, valueOld) {
                if (valueNew !== valueOld) {
                    this.getData()
                }
            },

            statsData() {
                this.firstStats = this.statsData[0]
                this.lastStats = this.statsData[this.statsData.length - 1]

                if (this.firstStats && this.lastStats) {
                    this.overview = [
                        {
                            type: 'views',
                            value: this.getValue('views'),
                            total: this.getTotal('views'),
                            diff: this.getDiff('views'),
                        },
                        {
                            type: 'followers',
                            value: this.getValue('followers'),
                            total: this.getTotal('followers'),
                            diff: this.getDiff('followers'),
                        },
                        {
                            type: 'posts',
                            value: this.getValue('posts'),
                            total: this.getTotal('posts'),
                            diff: this.getDiff('posts'),
                        },
                        {
                            type: 'members',
                            value: this.getValue('members'),
                            total: this.getTotal('members'),
                            diff: this.getDiff('members'),
                        },
                    ]
                } else {
                    this.overview = []
                }
            },
        },

        mounted() {
            this.getData()
        },

        methods: {
            handleCommand(code) {
                this.currentStatsCode = code
                this.buildChartData(code)
            },

            async getData() {
                try {
                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getContentSubDomain(window.location.host) : this.$route.params.organization

                    const response = await getOrganizationGeneralStats(
                        orgSubdomain,
                        this.query
                    )

                    const data = response.data
                    this.statsData = data.data
                    this.statsDifferences = data.differences

                    this.statsType.forEach((type) => {
                        this.buildChartData(type.code)
                    })
                } catch (err) {
                    // TODO catch error
                }
            },

            buildChartData(code) {
                const labels = this.statsData.map(item => item.date)
                const name = this.statsType.find(type => type.code === code).name
                const series = [this.statsData.map(item => ({
                    meta: btoa(JSON.stringify({
                        date: item.date,
                        total: item[`${code}_count`],
                        type: name,
                    })),
                    value: item[`${code}_diff`],
                }))]

                this.allChartData[code] = { labels, series, unit: this.$t(`organization.stats.${name}`) }
            },

            getValue(key) {
                return this.lastStats[`${key}_count`] - this.firstStats[`${key}_count`]
            },

            getTotal(key) {
                return this.lastStats[`${key}_count`]
            },

            getDiff(key) {
                return this.statsDifferences[`percent_${key}`] || 0
            },
        },
    }
</script>

<style lang="scss" scoped>
    .increase {
        color: #10ac84;
    }

    .decrease {
        color: #ff6b6b;
    }

    .type-select {
        z-index: 10;
    }

    .separator {
        border-bottom: 1px solid #dadada;
    }

    .over-view {
        &--label {
            color: #333;
        }
    }

    .theme-dark {
        .over-view {
            &--label {
                color: #fff;
            }
        }
    }
</style>
