// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="current.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <div v-else>
            <Members
                :user-ids="current"
            />
            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import Members from '~/components/subdomains-theme-2/followings/UserList.vue'

    export default {
        name: 'OrganizationFollowers',

        components: {
            Members,
            InfiniteLoading,
        },

        data() {
            return {
                isLoading: false,
            }
        },

        watchQuery: ['page'],

        scrollToTop: true,

        computed: {
            ...mapState('organizationView/followers', ['current', 'pagination']),
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            this.isLoading = true

            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            try {
                await store.dispatch('organizationView/followers/fetch', {
                    slug: orgSubdomain,
                    params: {
                        limit: store.state.settings.perPage,
                        page,
                        isLoadMore: true,
                    },
                })
                this.isLoading = false
            } catch (err) {
                this.isLoading = false
                return handlePageError(error, err)
            }
        },

        beforeDestroy() {
            this.$store.dispatch('organizationView/followers/resetData')
        },

        methods: {
            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Followers',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/followers`,
        }),
    }
</script>

<style scoped>
.loading {
    position: unset;
    width: 100%;
    text-align: center;
    font-size: 24px;
}
</style>
