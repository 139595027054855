// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default {
    data: () => ({
        state: {
            show: false,
        },
    }),

    methods: {
        open() {
            this.state.show = true
        },

        close() {
            this.state.show = false
        },
    },
}
