// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div
            v-if="!organization.full_description"
            class="text-center font-weight-bold text-muted my-05"
        >
            <span>{{ $t('detailOrgaization.about.fullDescription') }}</span>
        </div>
        <div v-else>
            <div v-if="isOwner" class="text-left text-md-right">
                <nuxt-link :to="toOrganization(organization, 'info/edit')" class="link link--plain">
                    <i class="fa fa-pencil-square-o" aria-hidden="true" /> {{ $t('detailOrgaization.about.edit') }}
                </nuxt-link>
            </div>
            <Markdown :contents="organization.full_description" class="article-content__body my-2 flex-fill" />
        </div>
    </div>
</template>

<script>
    import Markdown from '~/components/contents/Markdown.vue'
    import { organization as routeToOrganization } from '~/utils/url'

    export default {
        components: {
            Markdown,
        },

        props: {
            organization: {
                type: Object,
                require: true,
            },
            isOwner: Boolean,
        },

        methods: {
            toOrganization(organizationItem, tab) {
                if (this.$store.state.config.vars.is_special_organization) {
                    tab = (typeof tab === 'undefined') ? '' : `/${tab}`

                    return `${tab}`
                }

                return routeToOrganization(organizationItem, tab)
            },
        },
    }
</script>
