// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-block">
        <SectionTitle
            :text="$t('inviteInOrgaization.existingMember.title')"
            class="mt-3 mb-2"
        />
        <section class="search">
            <Search />
        </section>
        <section class="list">
            <ElTable
                :data="members"
                class="full-width py-1"
                row-key="username"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    :label="$t('inviteInOrgaization.existingMember.table.name')"
                    width="450"
                >
                    <template slot-scope="scope">
                        <div class="d-flex">
                            <UserAvatar
                                :image="scope.row.avatar"
                                :username="scope.row.username"
                                size="md"
                            />

                            <div class="flex-grow-1 ml-1">
                                <span class="text-bold">{{ scope.row.name }}</span>
                                <span class="text-muted ml-05">
                                    <i>{{ `@${scope.row.username}` }}</i>
                                </span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="150" :label="$t('inviteInOrgaization.existingMember.table.role')">
                    <template slot-scope="scope">
                        <Role
                            :user="scope.row"
                            :is-owner="scope.row.id === organization.user_id"
                            :current-role="scope.row.pivot.role"
                            :change-role="changeRole"
                            :auth-is-owner="authIsOwner"
                            :permission="hasPermisstion(scope.row)"
                        />
                    </template>
                </el-table-column>

                <el-table-column width="100" :label="$t('inviteInOrgaization.existingMember.table.action')">
                    <template slot-scope="scope">
                        <el-button
                            type="danger"
                            size="small"
                            icon="el-icon-delete"
                            class="px-05 ml-1"
                            @click="confirmRemove(scope.row)"
                        />
                    </template>
                </el-table-column>
            </ElTable>

            <pagination
                v-if="pagination"
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
            />
        </section>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import UserAvatar from '~/components/shared/user/Avatar.vue'
    import SectionTitle from '~/components/settings/SectionTitle.vue'
    import Pagination from '~/components/Pagination.vue'
    import Role from './Role.vue'
    import Search from './Search.vue'


    export default {
        components: {
            SectionTitle,
            UserAvatar,
            Pagination,
            Role,
            Search,
        },

        props: {
            members: {
                type: Array,
                default: () => [],
            },
            organization: Object,
            pagination: Object,
        },

        inject: ['changeRole', 'removeMember'],

        computed: {
            ...mapGetters('auth', ['userId']),

            authIsOwner() {
                return this.userId === this.organization.user_id
            },
        },

        methods: {
            hasPermisstion(user) {
                return user.id === this.userId || this.isOwner
            },

            confirmRemove(user) {
                this.$confirm(this.$t('inviteInOrgaization.confirmRemove.title'), this.$t('inviteInOrgaization.confirmRemove.content'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.removeMember(user))
                    .catch(() => { })
            },
        },
    }
</script>

<style lang="scss">
.theme-dark {
    .submissions-post-subdomain {
        .el-table tr, th, .el-table {
            background-color: #000;
            color: #fff;

            tr:hover {
              background-color: #464646;
            }
        }

        .el-table--enable-row-hover .el-table__body tr:hover > td {
            background-color: #464646;
        }

        .el-table {
            padding-bottom: 0 !important;
        }

        .section-title-line, .el-table__empty-text {
            color: #fff;
        }

        .el-button--danger {
            color: #464646;
            background-color: #fff;
            border: none;

            .el-icon-delete {
                color: #464646;
            }
        }
    }
}
</style>
