// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <section class="d-flex mb-1 py-05">
        <avatar
            :image="user.avatar"
            :username="user.username"
            size="md"
        />

        <div class="d-flex flex-column flex-grow-1 justify-content-between mx-1">
            <div class="d-block">
                <nuxt-link :to="toUser" class="link text-capitalize text-bold">
                    {{ user.name }}
                </nuxt-link>
                <span class="mx-05 text-muted text-sm-left"><i>@{{ user.username }}</i></span>
            </div>

            <div class="d-inline-flex">
                <span class="text-muted">{{ $t('inviteInOrgaization.invited') }}</span>
                <span class="text-muted ml-05">{{ user.pivot.created_at | humanizeTime }}</span>
            </div>
        </div>

        <action-list
            :user="user"
            :actions="{ cancelInvitation, changeRole }"
            :current-role="user.pivot.role"
        />
    </section>
</template>

<script>
    import { user as toUser } from '~/utils/url'
    import Avatar from '~/components/shared/user/Avatar.vue'
    import ActionList from './ActionList.vue'

    export default {
        components: {
            Avatar,
            ActionList,
        },

        props: {
            user: {
                type: Object,
                required: true,
            },
        },

        inject: ['cancelInvitation', 'changeRole'],

        computed: {
            toUser() {
                return toUser(this.user)
            },

            toOwner() {
                return toUser(this.owner)
            },
        },
    }
</script>
