// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template functional>
    <div :class="['stats', data.staticClass]">
        <span
            v-for="(stat, index) in props.stats"
            :key="index"
            v-tooltip
            :title="stat.name"
            class="stats-item text-muted"
            :class="props.isSuggestion ? 'stats-item-profile' : 'w-25'"
        >
            <i :class="stat.icon" class="stats-item__icon-home" aria-hidden="true" />
            {{ stat.value | formatNumber }}
        </span>

        <slot name="append" />
    </div>
</template>

<script>
    export default {
        props: {
            stats: {
                type: Array,
                required: true,
            },
            isSuggestion: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss">
    .stats-item {
        cursor: default;
        margin-right: 10px;

        &__icon {
            margin-right: 2px;
        }
    }

    .stats-item-profile {
        font-weight: 500;
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
</style>
