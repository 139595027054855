// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="tags-input">
        <div class="tags-input__inner el-input__inner">
            <span
                v-for="(tag, index) in tagsArray"
                :key="index"
                class="el-tag el-tag--info el-tag--medium tags-input__tag"
                :style="getTagClass(tag)"
            >
                <span v-if="tag === 'MayFest2023'">
                    MAYFEST<span class="year">2023</span>
                </span>
                <span v-else>{{ tag }} </span>
                <i
                    v-if="canRemove(tag)"
                    class="el-tag__close el-icon-close"
                    :style="tag === 'MayFest2023' || tag === 'MayFest2024'
                        ? tag === 'MayFest2024'
                            ? 'color: #B335EF'
                            : 'color: #55ffff'
                        : getTagClass(tag)"
                    @click.prevent.stop="removeTag(index)"
                />
            </span>

            <el-autocomplete
                ref="input"
                v-model="input"
                :placeholder="placeholder"
                :trigger-on-focus="false"
                :fetch-suggestions="fetchSuggestions"
                :popper-class="popperClass"
                class="tags-input__autocomplete"
                @select="onSelect"
                @keyup.native="checkForSeparator"
                @keyup.native.esc.prevent="hideSuggestions"
                @keyup.native.enter.prevent="onEnter"
                @keydown.native.delete.stop="onBackspace"
            >
                <span slot-scope="props">{{ props.item.name }}</span>
            </el-autocomplete>
        </div>
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import _find from 'lodash/find'
    import _flatMap from 'lodash/flatMap'
    import _forEach from 'lodash/forEach'
    import _includes from 'lodash/includes'
    import _trimStart from 'lodash/trimStart'
    import _trimEnd from 'lodash/trimEnd'
    import { getTags } from 'viblo-sdk/api/tags'
    import { mapGetters } from 'vuex'
    import { events } from '~/constants/events'

    const fetchSuggestions = (input, done) => (input
        ? getTags({ src: 'typeahead', q: input }).then(_ => _.data).then(done)
        : done([])
    )

    const hasTag = (tag, tagsArray) => {
        const normalizedArray = tagsArray.map(_ => _.toLowerCase())
        return _includes(normalizedArray, tag.toLowerCase())
    }

    export default {
        props: {
            value: String,
            limit: Number,
            name: String,
            popperClass: String,
            isPostTagsInput: Boolean,
            isOrganizationPartner: Boolean,
            placeholder: {
                type: String,
                default: 'Tags',
            },
            fetchSuggestions: {
                type: Function,
                default: fetchSuggestions,
            },
            allowCreate: { // Only select if false
                type: Boolean,
                default: true,
            },
            currentEvents: Object,
            publishedAt: String,
        },

        data: () => ({
            input: '',
            suggestions: [],
            skipNextNativeEnter: false,
            events,
        }),

        computed: {
            ...mapGetters('auth', ['isPartner']),

            tagsArray() {
                return this.value ? this.value.split(',') : []
            },

            eventTags() {
                return _flatMap(this.events, event => event.tag)
            },
        },

        methods: {
            canRemove(tagName) {
                let isRemove = true
                _forEach(this.events, (event) => {
                    if (event.tag.name === tagName) {
                        isRemove = event.start_time < Date.now() && Date.now() < event.end_time
                    }
                })

                return isRemove
            },
            getTagClass(tagName) {
                const tag = _find(this.eventTags, eventTag => eventTag.name === tagName)

                if (tagName === 'ContentCreator') {
                    return {
                        background: 'linear-gradient(to right, #38A2D7, #561139)',
                        '-webkit-background-clip': 'text',
                        '-webkit-text-fill-color': 'transparent',
                        'background-size': '200% auto',
                        animation: 'textShine 2s linear infinite',
                    }
                }

                if (tagName === 'MayFest2023') {
                    return {
                        background: 'linear-gradient(262.76deg, #400094 0.6%, #BB00BF 108.32%)',
                        color: '#00FFFF',
                        'font-weight': 'bold',
                        border: '1px solid #55FFFF',
                        '-webkit-text-stroke-width': '0.3px',
                        '-webkit-text-stroke-color': '#8F0092;',
                    }
                }

                return tag
                    ? {
                        'font-family': tag.font_family,
                        'font-size': tag.font_size,
                        'line-height': tag.line_height,
                        'background-color': tag.background_color,
                        'border-color': tag.border_color || tag.background_color,
                        background: tag.background,
                        color: tag.text_color,
                    }
                    : null
            },

            onEnter(event) {
                if (this.skipNextNativeEnter) {
                    this.skipNextNativeEnter = false
                } else if (this.allowCreate) {
                    this.appendTag(event.target.value)
                    this.hideSuggestions()
                }
            },

            onBackspace() {
                // if there's no input, assume trying to delete previous tag
                if (!this.input && this.tagsArray.length > 0) {
                    const index = this.tagsArray.length - 1
                    const getEvent = _filter(this.events, event => event.tag.name === this.tagsArray[index])
                    const now = new Date()

                    const canRemovetagEvent = getEvent.length > 0 && this.publishedAt
                        && (now < getEvent[0].start_time || now >= getEvent[0].end_time)
                        && !this.checkPostInEvent(getEvent.start_time, getEvent.end_time)

                    if (!canRemovetagEvent) {
                        this.removeTag(index)
                    }
                }
            },

            onSelect(tag) {
                this.appendTag(tag.name)
                this.$refs.input.focus()
                this.skipNextNativeEnter = true
            },

            hideSuggestions() {
                this.$refs.input.suggestions = []
            },

            checkForSeparator(e) {
                if (!this.allowCreate) return

                // comma or semicolon
                if (e.keyCode === 188 || e.keyCode === 186) {
                    this.appendTag(this.input.slice(0, this.input.length - 1))
                    this.hideSuggestions()
                }
            },

            appendTag(tag) {
                this.input = ''

                tag = _trimStart(tag, '# ')
                tag = _trimEnd(tag, ' ')

                let notCountTags = []
                let canAddTagEvent = true
                let postPublishInEvent = false

                if (this.currentEvents && tag === this.currentEvents.tag.name) {
                    canAddTagEvent = this.currentEvents.active

                    if (
                        this.publishedAt
                        && this.checkPostInEvent(this.currentEvents.start_time, this.currentEvents.end_time)
                    ) {
                        postPublishInEvent = true
                        canAddTagEvent = false
                    }

                    if (postPublishInEvent) {
                        const message = this.$t('postPublicNotInEvent', { eventName: this.currentEvents.name })
                        this.$message.error(message)
                    }
                }

                _forEach(this.events, (event) => {
                    const eventNames = event.tag.name ? event.tag.name.split(',') : []
                    notCountTags = notCountTags.concat(eventNames)
                })
                notCountTags = notCountTags.concat(['ContentCreator'])
                const tagsArrayWithoutSystemTag = this.tagsArray.filter(item => !notCountTags.includes(item))

                const canAddMoreTag = !this.limit
                    || tagsArrayWithoutSystemTag.length < this.limit
                    || notCountTags.includes(tag)

                if (canAddMoreTag
                    && !hasTag(tag, this.tagsArray)
                    && !this.tagContainOnlySpace(tag)
                    && canAddTagEvent
                ) {
                    this.emitChange([...this.tagsArray, tag])
                }
            },

            removeTag(index) {
                const newTagsArray = [...this.tagsArray.slice(0, index), ...this.tagsArray.slice(index + 1)]
                this.emitChange(newTagsArray)
            },

            removeTagByName(tagName) {
                const index = this.findTagIndex(tagName)

                if (index >= 0) {
                    this.removeTag(index)
                }
            },

            findTagIndex(tagName) {
                return this.tagsArray.findIndex(t => t === tagName)
            },

            tagContainOnlySpace(tag) {
                return tag.length === 0
            },

            hasSubArray(master, sub) {
                for (let i = 0; i < master.length; i += 1) {
                    if (sub.includes(master[i])) {
                        return true
                    }
                }
                return false
            },

            emitChange(tagsArray) {
                let hasInvalidEventTag = false
                const now = new Date()
                const newTag = _filter(tagsArray, tag => !this.tagsArray.includes(tag))
                const notStartEvents = _filter(this.events, event => now < event.start_time || now >= event.end_time)

                _forEach(notStartEvents, (event) => {
                    const eventTagNameArray = event.tag.name ? event.tag.name.split(',') : []

                    if (this.hasSubArray(tagsArray, eventTagNameArray)) {
                        const postPublishInEvent = this.publishedAt
                            ? this.checkPostInEvent(event.start_time, event.end_time)
                            : true

                        if (postPublishInEvent || newTag.includes(event.tag.name)) {
                            const nextTags = _filter(tagsArray, _ => !eventTagNameArray.includes(_))
                            const message = this.$t('canNotTag', { eventName: event.name })

                            this.$message.error(message)
                            this.$emit('input', nextTags.join(','))
                            hasInvalidEventTag = true
                        }
                    }
                })

                if (this.hasSubArray(tagsArray, ['ContentCreator'])) {
                    if (!this.isPostTagsInput) {
                        const message = this.$t('canNotTagContentCreatorIfNotPost')
                        this.$message.error(message)
                        hasInvalidEventTag = true
                    } else if (!this.isPartner && !this.isOrganizationPartner) {
                        const message = this.$t('canNotTagContentCreator')
                        this.$message.error(message)
                        hasInvalidEventTag = true
                    }
                }

                if (!hasInvalidEventTag) {
                    this.$emit('input', tagsArray.join(','))
                }
            },

            checkPostInEvent(startTime, endTime) {
                return startTime > new Date(this.publishedAt)
                    || new Date(this.publishedAt) > endTime
            },
        },
    }
</script>

<style lang="scss">
    @keyframes textShine {
        to {
            background-position: 200%;
        }
    }
    .tags-input {
        &__inner {
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;
            background-color: #fff;
            height: auto !important;

            &:focus-within {
                border-color: #409EFF;
            }
        }

        &__tag {
            margin-left: .5rem;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &__autocomplete {
            flex-grow: 1;

            input {
                width: 100%;
                border: none;
                background-color: transparent;
            }
        }
    }
    .year {
        font-size: 0.62rem !important;
        color: #00FFFF;
    }
</style>
