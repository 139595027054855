// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <section-title :text="$t('inviteInOrgaization.invitations')" class="my-2" />
        <inviting-user-item
            v-for="user in invitations"
            :key="user.username"
            :user="user"
        />
    </div>
</template>

<script>
    import SectionTitle from '~/components/settings/SectionTitle.vue'
    import InvitingUserItem from './Item.vue'

    export default {
        components: {
            SectionTitle,
            InvitingUserItem,
        },
        props: {
            invitations: {
                type: Array,
                default: () => [],
            },
        },
    }
</script>
