// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <tags-list :tags="showTags" :size="size">
        <template v-if="special" slot="prepend">
            <v-tag
                v-if="promoted"
                :size="size"
                url="/editors-choice"
                primary
            >
                Editors' Choice
            </v-tag>

            <v-tag
                v-if="trending"
                :size="size"
                url="/trending"
                primary
            >
                Trending
            </v-tag>

            <span
                v-if="isMayfest2023"
                name="MayFest2023"
                class="mayfest2023"
                @click="toMayfest"
            >
                <span
                    class="mayfest2023-tag"
                    :style="`padding: ${size === 'medium' ? 0.4 : 0.2}em 0.6em`"
                >

                    Mayfest<span class="year">2023</span>
                </span>
            </span>

            <v-tag
                v-if="isMayfest2022"
                :size="size"
                url="/tags/mayfest2022"
                class-name="mayfest2022-tag"
                name="MayFest2022"
            />
            <v-tag
                v-if="isMayfest2024"
                :size="size"
                url="/tags/mayfest2024"
                class-name="mayfest2024-tag"
                name="MayFest2024"
            />

            <v-tag
                v-if="isReconnection"
                :size="size"
                url="/tags/mayfest2022"
                class-name="reconnection-tag"
                name="Reconnection"
            />

            <v-tag
                v-if="newYearEvent"
                :size="size"
                url="/tags/happy-new-year"
                class-name="new-year-event-tag"
                name="Happy New Year"
            />
            <v-tag
                v-if="newYearWritingEvent"
                :size="size"
                url="/tags/khaibutdauxuan"
                class-name="new-year-writing-tag"
                name="KhaiButDauXuan"
            />
            <v-tag
                v-if="isContentCreator"
                :size="size"
                url="/content-creator"
                class-name="contentcreator-tag"
                name="ContentCreator"
            />
            <v-tag
                v-if="isJavaShow"
                :size="size"
                url="/tags/javashow2022"
                class-name="javashow2022-tag"
                name="JavaShow2022"
            />

            <v-tag
                v-if="system"
                :url="toUrl(system.slug)"
                :name="system.name"
                :size="size"
                primary
            />
        </template>
    </tags-list>
</template>

<script>
    import _indexOf from 'lodash/indexOf'
    import TagsList from '~/components/shared/tags/List.vue'

    export default {
        components: { TagsList },
        props: {
            tags: Array,
            size: {
                type: String,
                default: 'mini',
            },
            promoted: Boolean,
            trending: Boolean,
            special: {
                type: Boolean,
                default: true,
            },
            normal: {
                type: Boolean,
                default: true,
            },
            system: Object,
        },
        computed: {
            showTags() {
                if (this.normal) {
                    return this.tags
                        .filter(t => t.slug !== 'may-fest')
                        .filter(t => t.slug !== 'happy-new-year')
                        .filter(t => t.slug !== 'khaibutdauxuan')
                        .filter(t => t.slug !== 'mayfest2022')
                        .filter(t => t.slug !== 'reconnection')
                        .filter(t => t.slug !== 'mayfest2023')
                        .filter(t => t.slug !== 'mayfest2024')
                        .filter(t => t.slug !== 'contentcreator')
                        .filter(t => t.slug !== 'javashow2022')
                }
                return []
            },
            isMayfest2022() {
                const mayfestTag = this.tags.find(t => t.slug === 'mayfest2022')
                if (mayfestTag) {
                    return true
                }
                return false
            },
            isMayfest2024() {
                const mayfestTag = this.tags.find(t => t.slug === 'mayfest2024')
                if (mayfestTag) {
                    return true
                }
                return false
            },
            isReconnection() {
                if (this.tags.find(t => t.slug === 'reconnection')) {
                    return true
                }
                return false
            },
            isMayfest2023() {
                const mayfestTag = this.tags.find(t => t.slug === 'mayfest2023')
                if (mayfestTag) {
                    return true
                }
                return false
            },
            newYearEvent() {
                if (this.tags.find(t => t.slug === 'happy-new-year')) {
                    return true
                }
                return false
            },
            newYearWritingEvent() {
                if (this.tags.find(t => t.slug === 'khaibutdauxuan')) {
                    return true
                }
                return false
            },
            isContentCreator() {
                if (this.tags.find(t => t.slug === 'contentcreator')) {
                    return true
                }
                return false
            },
            isJavaShow() {
                if (this.tags.find(t => t.slug === 'javashow2022')) {
                    return true
                }
                return false
            },
        },
        methods: {
            toMayfest() {
                this.$router.push('/mayfest2023')
            },

            toUrl(slug) {
                if (_indexOf(['en_terms', 'vi_terms', 'jp_terms'], slug) !== -1) {
                    const singularSlug = this.singularizePluralWord(slug)
                    return `/terms/${singularSlug}`
                }
                return `/${slug}`
            },

            singularizePluralWord(pluralWord) {
                if (pluralWord.endsWith('s')) {
                    return pluralWord.slice(0, -1)
                }
                return pluralWord
            },
        },
    }
</script>
<style lang="scss">
.mayfest2023-tag {
    cursor: pointer;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: #8F0092;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    background: linear-gradient(262.76deg, #400094 0.6%, #BB00BF 108.32%);
    color: #00FFFF;
    font-size: 0.95em;
    text-shadow: 0px 0.8px 0.8px #55ffff;
    border: 1px solid #55FFFF;
    .year {
        font-size: 0.65rem !important;
        color: #00FFFF;
    }
}

.mayfest2023 {
    line-height: 1.4 !important;
    margin-right: 3px;
    margin-bottom: 8px;
}

@keyframes textShine {
    to {
        background-position: 200%;
    }
}
</style>

<style lang="scss">
.theme-dark {
    .tags .contentcreator-tag {
      background: linear-gradient(to right, rgb(56, 162, 215), #fff) 0% 0% / 200% text;
    }

    .el-tag.el-tag--info {
        background-color: #fff;
        color: #000;
        border: none;
    }
}
</style>
