// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        {{ title }} &nbsp;&nbsp;
        <span
            ref="clipboard"
            :data-clipboard-text="clipboardData"
            class="viblo-tooltip cursor-pointer"
            area-label="Copied!"
            area-tooltip-hidden="true"
            :title="$t('copyURL')"
            @click.prevent="onCopied"
        >
            <i class="fa fa-link" />
        </span>
        <slot />
    </div>
</template>

<script>
    import Clipboard from 'clipboard'

    export default {
        props: {
            title: String,
            clipboardData: String,
        },
        data() {
            return {
                $clipboard: null,
            }
        },
        mounted() {
            if (this.$refs.clipboard) {
                this.$clipboard = new Clipboard(this.$refs.clipboard)
            }
        },
        destroyed() {
            if (this.$clipboard) {
                this.$clipboard.destroy()
                this.$clipboard = null
            }
        },
        methods: {
            onCopied() {
                this.$refs.clipboard.setAttribute('area-tooltip-hidden', false)
                // Set timeout to hide tooltip:
                setTimeout(() => {
                    if (this.$refs.clipboard) {
                        this.$refs.clipboard.setAttribute('area-tooltip-hidden', true)
                    }
                }, 1000)
            },
        },
    }
</script>
