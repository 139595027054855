// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="position-relative justify-content-center">
        <div
            class="d-flex"
            :class="organization.level_partner ? 'partner-boder-image-org' : 'boder-image-org'"
        >
            <img
                v-if="organization.level_partner"
                src="~/assets/images/partner-subdomain-img-cover.png"
                class="profile-cover-photo subdomain-header container"
                alt="Image Cover"
            >
            <img
                v-else
                src="~/assets/images/subdomain-img-cover.png"
                class="profile-cover-photo subdomain-header container"
                alt="Image Cover"
            >
        </div>
        <div class="subdomain-header__card subdomain-header container">
            <div class="profile-avatar org-avatar d-flex">
                <Avatar
                    :object="organization"
                    :image="organization.avatar"
                    :level-partner="organization.level_partner"
                    :partner-width="180"
                    :partner-hight="180"
                    is-subdomain
                    image-size="avatar"
                    size="xxl"
                    class="user-card__main__hero"
                />

                <div class="profile-avatar_info d-flex justify-content-between">
                    <div>
                        <h1 class="user-name d-inline text-break">
                            {{ organization.name }}
                        </h1>

                        <DescriptionLevelPartner
                            v-if="organization.level_partner"
                            :level="organization.level_partner"
                        />

                        <div class="profile-avatar_describe my-2">
                            {{ organization.short_description }}
                        </div>
                    </div>

                    <div class="d-flex header-right">
                        <div class="d-flex align-items-center">
                            <div
                                v-tooltip
                                :title="descButtonSwitchTheme"
                                class="button-switch-theme"
                                :class="modeThemeSubdomain"
                                @click="switchTheme"
                            />
                        </div>

                        <div class="subscribe d-flex flex-column justify-content-center">
                            <nuxt-link
                                v-if="isOwner"
                                :to="toOrganization(organization, 'info/edit')"
                                class="btn btn-edit edit"
                            >
                                <div>
                                    <img
                                        src="/images/icon-edit.png"
                                        class="mr-05"
                                    >
                                    {{ $t('detailOrgaization.edit') }}
                                </div>
                            </nuxt-link>
                            <subscribe
                                v-else
                                ref="subscribe"
                                :send="handleSubscribe"
                                :subscribable="{ type: 'Organization', id: organization.slug }"
                            >
                                <button
                                    :class="{ 'following-org': organization.following }"
                                    class="btn btn-follow-org"
                                    @click="$refs.subscribe.handle(!organization.following)"
                                >
                                    <span
                                        v-if="organization.following"
                                    >
                                        {{ $t('detailOrgaization.following') }}
                                    </span>
                                    <span v-else>
                                        <img
                                            src="/images/icon-follow.png"
                                            class="stats-item__icon mr-05"
                                        >
                                        {{ $t('detailOrgaization.follow') }}
                                    </span>
                                </button>
                            </subscribe>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="overview.length > 0"
                class="subdomain-header_stats"
            >
                <div
                    v-for="(item, index) in overview"
                    :key="index"
                    :class="`stats-card stats-${item.type}`"
                >
                    <p>{{ $t(item.title) }}</p>
                    <span class="d-flex justify-content-between align-items-center">
                        {{ item.type === 'views' || item.type === 'new-posts' ? item.value : item.total }}
                        <span
                            v-tooltip
                            :title="growth"
                            class="ml-2"
                            :style="`color: ${item.diff >= 0 ? '#0E9A0C' : '#F23939'}`"
                        >{{ item.diff >= 0 ? `+${item.diff}` : item.diff }}%</span>
                    </span>
                </div>
                <div class="stats-card stats-posts">
                    <p>{{ $t('profile.organizations.totalPost') }}</p>
                    <span>{{ lastStats.posts_count }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { format, subDays } from 'date-fns'
    import switchModeTheme from '~/pages/__mixins/switchModeTheme'
    import { getOrganizationGeneralStats } from '~/api/organizations'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import { getContentSubDomain } from '~/utils/pages'
    import { organization as routeToOrganization } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import DescriptionLevelPartner from '~/components/subdomains/DescriptionLevelPartner.vue'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        components: {
            Avatar,
            Subscribe,
            DescriptionLevelPartner,
        },

        mixins: [switchModeTheme],

        props: {
            organization: {
                type: Object,
                require: true,
            },
            isOwner: Boolean,
        },

        data() {
            return {
                statsData: null,
                statsDifferences: null,
                query: {
                    from: dateString(subDays(new Date(), 14)),
                    to: dateString(new Date()),
                },
                overview: [],
                firstStats: {},
                lastStats: {},
            }
        },

        computed: {
            ...mapState('auth', ['user']),
            growth() {
                return this.$t('subdomain.growthDate', { from: this.query.from, to: this.query.to })
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },

            descButtonSwitchTheme() {
                return this.modeThemeSubdomain === 'default' ? this.$t('detailOrgaization.darkMode') : this.$t('detailOrgaization.lightMode')
            },
        },
        watch: {
            statsData() {
                this.firstStats = this.statsData ? this.statsData[0] : null
                this.lastStats = this.statsData ? this.statsData[this.statsData.length - 1] : null
                if (this.firstStats && this.lastStats) {
                    this.overview = [
                        {
                            title: 'profile.organizations.view',
                            type: 'views',
                            value: this.getValue('views'),
                            total: this.getTotal('views'),
                            diff: this.getDiff('views'),
                        },
                        {
                            title: 'profile.organizations.members',
                            type: 'members',
                            value: this.getValue('members'),
                            total: this.getTotal('members'),
                            diff: this.getDiff('members'),
                        },
                        {
                            title: 'profile.organizations.newPost',
                            type: 'new-posts',
                            value: this.getValue('posts'),
                            total: this.getTotal('posts'),
                            diff: this.getDiff('posts'),
                        },
                        {
                            title: 'profile.organizations.follow',
                            type: 'followers',
                            value: this.getValue('followers'),
                            total: this.getTotal('followers'),
                            diff: this.getDiff('followers'),
                        },
                    ]
                } else {
                    this.overview = []
                }
            },
        },

        mounted() {
            this.getData()
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
            handleSubscribe(value) {
                return this.subscribe({ organization: this.organization, value })
            },
            toOrganization(organizationItem, tab) {
                if (this.$store.state.config.vars.is_special_organization) {
                    tab = (typeof tab === 'undefined') ? '' : `/${tab}`

                    return `${tab}`
                }

                return routeToOrganization(organizationItem, tab)
            },
            switchTheme() {
                const theme = this.modeThemeSubdomain === 'default' ? 'theme-dark' : 'default'
                this.handleSwitchTheme(theme)
            },
            async getData() {
                try {
                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getContentSubDomain(window.location.host) : this.$route.params.organization
                    const response = await getOrganizationGeneralStats(
                        orgSubdomain,
                        this.query
                    )
                    const data = response.data
                    this.statsData = data.data
                    this.statsDifferences = data.differences
                } catch (err) {
                    // TODO catch error
                }
            },
            getValue(key) {
                return this.lastStats[`${key}_count`] - this.firstStats[`${key}_count`]
            },

            getTotal(key) {
                return this.lastStats[`${key}_count`]
            },

            getDiff(key) {
                return this.statsDifferences[`percent_${key}`] || 0
            },
        },
    }
</script>

<style lang="scss">
@import '../../../assets/sass/components/subdomain-header';
.subdomain-header__card {
    .profile-avatar {
        .subscribe .btn-edit,.following-org {
            font-weight: 550;
            color: #ADA7A7 !important;
            border: 1px solid #0EA8FF;
            background-color: #F6FDFF !important;
            height: 47px;
            min-height: 47px;
            border-radius: 0.5rem;

            &:hover {
                opacity: 0.7;
            }
        }

        .btn.btn-follow-org {
            font-weight: 550;
            color: white;
            background-color: #0EA8FF;
            height: 47px;
            min-height: 47px;
            border-radius: 0.5rem;
        }

        .header-right {
            min-width: 13rem;

            @media screen and (max-width: 769px) {
                margin-bottom: 12px;
            }
        }
    }
    .subdomain-header_stats {
        display: grid;
        grid-gap: 16px;

        .stats-card {
            padding: 12px 16px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                font-size: 18px;
                font-weight: bold;
            }
            span {
                font-size: 30px;
                font-weight: bold;
                span {
                    font-size: 18px;
                    cursor: default;
                }
            }
        }
        .stats-views {
            background-color: rgba(115, 87, 246, 0.08);
        }
        .stats-members {
            background-color: rgba(132, 187, 240, 0.24);
        }
        .stats-posts {
            background-color: rgba(240, 189, 88, 0.27);
        }
        .stats-followers {
            background-color: rgba(151, 223, 216, 0.3);
        }
        .stats-new-posts {
            background-color: rgba(252, 123, 255, 0.3);
        }
    }
}

.theme-dark {
    .subdomain-header__card {
        background: #000;

        .subdomain-header_stats {
            .stats-views, .stats-members, .stats-posts, .stats-followers, .stats-new-posts {
                background: #fff;
                color: #000;
            }
        }
    }
}

.partner-boder-image-org {
    background-color: #00A2E9;
}
.boder-image-org {
    background-color: #0420C1;
}

@media (min-width: 320px) and (max-width: 520px) {
    .subdomain-header_stats {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 520px) and (max-width: 768px) {
    .subdomain-header_stats {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (min-width: 769px) {
    .subdomain-header_stats {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .subdomain-header {
        .org-avatar {
            a .avatar {
                width: 150px !important;
                max-height: 150px !important;
            }

            .border-avt-by-level {
                left: -14px !important;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 834px) {
    .subdomain-header {
        .org-avatar {
            .border-avt-by-level {
                left: -9px !important;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .subdomain-header_stats {
        .stats-card {
            p {
                font-size: 16px;
            }

            span {
                font-size: 24px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
}

.button-switch-theme {
    width: 60px;
    height: 47px;
    background-image: url('/images/button-dark-theme.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    margin-right: 8px;

    &.theme-dark {
        background-image: url('/images/button-light-theme.png');
    }
}
</style>
