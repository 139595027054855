// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link
        :to="toLink"
        class="position-relative d-flex position-relative flex-col justify-content-center align-items-center"
    >
        <Avatar
            v-if="isUser"
            v-bind="$props"
            :image="image || imagePolyfill"
            :class="{'position-absolute': levelPartner}"
            class="level-default avatar-event flex-center"
        />
        <Avatar
            v-else
            v-bind="$props"
            :image="image || imagePolyfill"
            rectangle
            organization
            :class="{'position-absolute': levelPartner}"
            class="level-default avatar-event flex-center"
        />
        <MarkLevelPartner
            v-if="levelPartner"
            :level="levelPartner"
            :width="partnerWidth"
            :height="partnerHight"
            :is-subdomain="isSubdomain"
            class="position-relative flex-center"
            :class="isUser ? 'position-left-user' : 'position-top-organ'"
        />
    </nuxt-link>
</template>

<script>
    import { organization as toOrganization, user as toUser } from '~/utils/url'
    import avatarLink from '~/components/mixins/avatarLink'
    import avatarLazyLoad from '~/components/mixins/avatarLazyLoad'
    import MarkLevelPartner from '~/components/organizations/MarkLevelPartner.vue'

    export default {
        components: {
            MarkLevelPartner,
        },
        mixins: [avatarLink, avatarLazyLoad],

        props: {
            object: {
                type: Object,
            },

            isUser: {
                type: Boolean,
                default: false,
            },

            levelPartner: {
                type: String,
                default: null,
            },

            partnerWidth: {
                type: Number,
            },

            partnerHight: {
                type: Number,
            },
            isSubdomain: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            toLink() {
                if (this.isSubdomain) {
                    return '/'
                }

                if (this.isUser) {
                    return toUser(this.object)
                }

                return toOrganization({ slug: this.object.slug })
            },
        },
    }
</script>

<style>
    .position-top-organ {
        top: 1px;
    }
    .position-left-user {
        top: 1px;
    }
</style>
