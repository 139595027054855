<template>
    <div ref="chart" class="ct-chart ct-perfect-fifth stats-chart" />
</template>

<script>
    import { format, parseISO } from 'date-fns'
    import { withCommaInThousandsPlaces } from '~/filters/formatNumber'

    export default {
        props: {
            chartData: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                chartist: null,
            }
        },

        watch: {
            chartData(to, from) {
                if (to !== from) {
                    this.reloadChart()
                }
            },
        },

        mounted() {
            const Chartist = require('chartist')
            require('chartist-plugin-tooltips')
            require('chartist-plugin-axistitle')

            const options = {
                axisY: {
                    onlyInteger: true,
                    labelInterpolationFnc(value, index) {
                        return index % 3 === 0 ? value : null
                    },
                },
                axisX: {
                    labelInterpolationFnc(value, index) {
                        return index % 4 === 0 ? value : null
                    },
                },
                showArea: true,
                plugins: [
                    Chartist.plugins.tooltip({
                        appendToBody: true,
                        tooltipFnc(meta, value) {
                            const data = JSON.parse(atob(meta))
                            return `
                                <div class="font-weight-bold">${format(parseISO(data.date), 'MMM d, y')}</div>
                                <div class="tooltip-separator"></div>
                                <div>${data.type}: <b>${withCommaInThousandsPlaces(value)}</b></div>
                                <div>Total: <b>${withCommaInThousandsPlaces(data.total)}</b></div>`
                        },
                    }),
                    Chartist.plugins.ctAxisTitle({
                        axisY: {
                            axisTitle: this.chartData.unit,
                            axisClass: 'ct-axis-title',
                            offset: {
                                x: 0,
                                y: 0,
                            },
                            textAnchor: 'middle',
                            flipTitle: false,
                        },
                    }),
                ],
            }

            this.chartist = new Chartist
                .Bar(this.$refs.chart, this.chartData, options)

            this.chartist.on('draw', (data) => {
                if (data.type === 'bar') {
                    const yOffset = this.chartist.options.axisY.offset
                    const chartWidth = this.chartist.container.clientWidth
                    const xWidth = chartWidth - yOffset
                    const stepLength = xWidth / this.chartist.data.series[0].length

                    data.element._node.style.strokeWidth = Math.round(stepLength * 0.85)
                }
            })
        },

        beforeDestroy() {
            this.chartist.detach()
            const obsoleteTooltipNodes = document.getElementsByClassName('chartist-tooltip')
            Array.from(obsoleteTooltipNodes).forEach(node => node.remove())
        },

        methods: {
            reloadChart() {
                this.chartist.update(this.chartData)
            },
        },
    }
</script>

<style lang="scss">
    .ct-axis-title {
        fill: rgba(181, 181, 181, 0.75);
    }

    .ct-horizontal {
        stroke: none !important;
    }
    .ct-grid {
        stroke: rgba(181, 181, 181, 0.33);
    }

    .ct-bar {
        stroke: rgba(83, 135, 198, 0.75) !important;
    }

    .tooltip-separator {
        margin: 3px 0;
        border-top: 1px solid rgba(95, 95, 95, 0.75);
    }

    .chartist-tooltip {
        z-index: -1;
    }

    .tooltip-show {
        z-index: 1;
    }

    .theme-dark {
        .ct-axis-title {
            fill: #fff;
        }

        .ct-label {
            fill: #fff;
            color: #fff;
        }

        .ct-bar {
            stroke: #fff !important;
        }
    }
</style>
