// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const getInvitingUsers = slug => axios.get(`/organizations/${slug}/invitations`)
export const myInvitations = () => axios.get('/me/invitations')

export const invite = (slug, data) => axios.post(`/organizations/${slug}/invite`, data)
export const cancelInvitation = (slug, username) => axios.delete(`/organizations/${slug}/invites/${username}`)
export const getInvitation = (slug, username) => axios.get(`/organizations/${slug}/invites/${username}`)

export const acceptInvitation = (slug, username) => axios.post(`/organizations/${slug}/invites/${username}/accept`)
export const declineInvitation = (slug, username) => axios.delete(`/organizations/${slug}/invites/${username}/decline`)
