// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        class="series-editor-add-posts modal-body-fit-screen"
        :title="$t('createSeries.addPosts.titleDialog')"
    >
        <div class="sb flex-fill">
            <input
                v-model="titleSearch"
                type="text"
                :placeholder="$t('search.title')"
                class="sb__input"
                @change="search"
            >
            <button class="btn btn-primary h-100" @click="search(titleSearch)">
                <i class="fa fa-search" />
            </button>
        </div>
        <!-- TODO add by url, search .etc -->
        <div class="available-own-posts mt-1">
            <ul class="list-group">
                <i v-if="loading" class="fa fa-circle-o-notch fa-spin sb-icon loading-search" aria-hidden="true" />
                <li
                    v-for="(post, index) in (searchPosts.length <= 0 ? posts : searchPosts)"
                    v-else
                    :key="post.id"
                    :class="{
                        'active': (selected.indexOf(post.slug) !== -1),
                    }"
                    class="list-group-item word-break"
                    @click="markForAdd(post.slug)"
                >
                    <add-post-item
                        :title="`${index + 1}. ${post.title}`"
                        :clipboard-data="postUrl(post)"
                    />
                </li>
            </ul>
        </div>

        <div v-if="noPosts" class="text-center my-05">
            {{ $t('createSeries.addPosts.notPost') }}
            <nuxt-link to="/publish/post">
                {{ $t('createSeries.addPosts.write') }}
            </nuxt-link>
        </div>

        <client-only>
            <InfiniteLoading
                v-show="!finishLoad"
                ref="infiniteLoading"
                :distance="50"
                spinner="spiral"
                @infinite="fetchPosts"
            />
        </client-only>

        <template slot="footer">
            <button
                :disabled="selected.length === 0"
                class="btn btn-primary btn-sm cursor-pointer"
                @click="add"
            >
                {{ $t('createSeries.addPosts.buttonAdd') }}
            </button>
            <button class="btn btn-default btn-sm cursor-pointer" @click="close">
                {{ $t('createSeries.addPosts.cancel') }}
            </button>
        </template>
    </v-dialog>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _remove from 'lodash/remove'
    import _findIndex from 'lodash/findIndex'
    import _includes from 'lodash/includes'
    import InfiniteLoading from 'vue-infinite-loading'
    import { absoluteUrl, routeToPost } from '~/lib/functions'

    import { getPostsForSeries } from '~/api/series'
    import AddPostItem from './AddPostItem.vue'

    export default {
        components: {
            InfiniteLoading,
            AddPostItem,
        },
        props: {
            series: {
                type: Object,
            },
        },

        data: () => ({
            show: false,
            posts: [],
            pagination: null,
            organizationID: [],
            selected: [],
            finishLoad: false,
            titleSearch: '',
            searchPosts: [],
            loading: false,
        }),

        computed: {
            ...mapGetters('auth', ['isAdmin']),

            noPosts() {
                const loadCompleted = this.pagination
                    && this.pagination.current_page >= this.pagination.total_pages

                return loadCompleted && this.posts.length === 0
            },
        },

        inject: ['actions'],

        methods: {
            postUrl(post) {
                return absoluteUrl(routeToPost(post))
            },

            open() {
                this.searchPosts = ''
                this.selected = []
                this.posts = []
                this.pagination = null
                if (this.$refs.infiniteLoading) {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
                }
                this.finishLoad = false
                this.show = true
            },

            close() {
                this.show = false
                this.titleSearch = ''
            },

            markForAdd(id) {
                if (!this.markedForAdd(id)) {
                    this.selected = [...this.organizationID, id]
                } else {
                    this.selected = this.organizationID.filter(item => item !== id)
                }
            },

            markedForAdd(id) {
                return _includes(this.organizationID, id)
            },

            add() {
                this.titleSearch = ''
                this.actions.addPost(this.selected)
                    .then(() => {
                        _remove(this.posts,
                                post => _findIndex(this.selected, selected => selected === post.slug) !== -1)
                        this.close()
                    })
            },

            search() {
                this.searchPosts = []
                this.loading = true
                setTimeout(() => {
                    if (this.titleSearch) {
                        this.posts.forEach((post) => {
                            if (post.title.match(this.titleSearch)) {
                                this.searchPosts.push(post)
                            }
                        })
                    } else {
                        this.searchPosts = []
                    }

                    this.loading = false
                }, 1500)
            },

            async fetchPosts($state) {
                const page = this.pagination ? this.pagination.current_page + 1 : 1
                const seriesHashId = this.series.hash_id

                const posts = await getPostsForSeries({
                    page,
                    series_hash_id: seriesHashId,
                    all: (this.isAdmin ? 1 : 0),
                }).then(response => response.data.posts)

                this.posts = [...this.posts, ...posts.data]
                this.pagination = posts.meta.pagination

                if (this.pagination.current_page < this.pagination.total_pages) {
                    $state.loaded()
                } else {
                    this.finishLoad = true
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss">
    .series-editor-add-posts {
        .modal-dialog {
            max-width: 95vw;
            width: 1100px;
        }

        .available-own-posts {
            max-height: 430px;
            overflow: auto;
        }

        .modal-body {
            padding: 0 15px;
        }

        .modal-footer {
            padding: 10px;
            justify-content: center;
        }

        .list-group-item {
            cursor: pointer;
        }
    }
    .loading-search{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
    }
</style>
