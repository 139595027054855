// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="stats-subdomain pt-2 pb-2">
        <StatsHeader :organization="organization" />
        <GeneralStats />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import { fetchOrganization } from '~/api/organizations'
    import GeneralStats from '~/components/organizations/stats/GeneralStats.vue'
    import StatsHeader from './stats/stats-header.vue'

    export default {
        name: 'OrganizationStats',
        layout: 'subdomain/default',

        components: {
            StatsHeader,
            GeneralStats,
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),

            menuDefaultActive() {
                return this.$route.name
            },
        },

        async asyncData({
            store, params, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const res = await fetchOrganization(orgSubdomain)
                const organization = res.data
                return { organization }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        head() {
            return {
                title: this.withUnreadCount(`Stats - ${this.organization.name}`),
            }
        },

        scrollToTop: true,

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Stats',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/stats`,
        }),
    }
</script>

<style lang="scss">
.stats-subdomain {
    @media screen and (max-width: 1280px) {
        padding: 0 4rem;
    }

    .chart {
        padding-top: 16px;

        &-item {
            background-color: #fff;
            border-radius: 8px;
            margin-bottom: 16px !important;
            padding: 0px 60px;
            border: none !important;

            .ct-bar {
                stroke: #0EA8FF !important;
            }

            .ct-label {
                color: #000;
                font-weight: bold;
            }
        }

        &-desc {
            flex-direction: row;
            width: 100%;
        }
    }

    .stats-table {
        .organization-stats {
            overflow-x: auto;

            .el-table {
              min-width: 640px;

                &__header {
                    width: auto !important;
                }

                &__body-wrapper {
                    overflow-x: hidden;
                }
            }
        }
    }
}

.theme-dark {
    .stats-subdomain {
        .chart {
            &-item {
                background-color: #000;
            }
        }
    }
}
</style>
