// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <user-grid :actions="{ follow }" store-path="organizationView/followers" class="post-feed-org px-1 pb-2">
        <template slot="empty">
            <div class="pt-2 text-empty">
                {{ $t('organization.noFollowers') }}
            </div>
        </template>
    </user-grid>
</template>

<script>
    import { mapActions } from 'vuex'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import UserGrid from '~/components/subdomains/users/UserGrid.vue'

    export default {
        name: 'OrganizationFollowers',

        components: {
            UserGrid,
        },

        watchQuery: ['page'],

        scrollToTop: true,

        fetch: ({
            store, params, query, error, req,
        }) => {
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            return store.dispatch('organizationView/followers/fetch', {
                slug: orgSubdomain,
                params: {
                    limit: store.state.settings.perPage,
                    page: query.page,
                },
            }).catch(handlePageError(error))
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Followers',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/followers`,
        }),
    }
</script>

<style lang="scss" scoped>
.theme-dark {
    .post-feed-org {
        background-color: #000;
    }

    .text-empty {
        color: #fff;
    }
}
</style>
