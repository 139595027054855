<template>
    <GeneralStats />
</template>

<script>
    import GeneralStats from '~/components/organizations/stats/GeneralStats.vue'

    export default {
        components: {
            GeneralStats,
        },
    }
</script>
