// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-item-draft">
        <div class="py-05 mr-05">
            <UserAvatar :image="user.avatar" :username="user.username" size="md" />
        </div>
        <div class="post-feed-item-draft__info">
            <div class="text-muted mb-05">
                <nuxt-link :to="toUser">
                    {{ user.name }}
                </nuxt-link>
                <span title="Last edit" class="text-time">
                    {{ post.updated_at | humanizeTime }}
                </span>
            </div>

            <div class="post-header">
                <div class="post-title-box d-flex align-items-center">
                    <h3 class="post-title-header">
                        <nuxt-link :to="toPost" class="link">
                            {{ post.title }}
                        </nuxt-link>
                    </h3>
                    <tags :tags="post.tags.data" class="ml-05" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { user as toUser } from '~/utils/url'
    import Tags from '~/components/posts/widgets/Tags.vue'
    import UserAvatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Tags,
            UserAvatar,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },
        },

        computed: {
            user() {
                return this.post.user.data
            },

            toUser() {
                return toUser(this.user)
            },

            toPost() {
                return this.post.is_video ? `/videos/${this.post.slug}/edit` : `/posts/${this.post.slug}/edit`
            },
        },
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/variables";

    .draft-status {
        color: #4bcd9f;
        margin-right: 0.3rem;
    }

    .post-feed-item-draft {
        padding: .5rem;
        border-bottom: 1px solid $base-border-color;
        display: flex;


        .text-time {
            margin-left: 0.35rem;
            font-size: 0.8rem;
        }
    }

    .post-title-header{
        word-break: break-all;
    }

    .theme-dark {
        .post-title-header {
            color: #fff;
        }
    }
</style>
