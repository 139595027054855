// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="organization-stats">
        <StatsHeader :organization="organization" />
        <div class="container">
            <GeneralStats />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import { fetchOrganization } from '~/api/organizations'
    import GeneralStats from '~/components/organizations/stats/GeneralStats.vue'
    import StatsHeader from './stats/stats-header.vue'

    export default {
        name: 'OrganizationStats',
        layout: 'subdomain/default',

        components: {
            StatsHeader,
            GeneralStats,
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),

            menuDefaultActive() {
                return this.$route.name
            },
        },

        async asyncData({
            store, params, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const res = await fetchOrganization(orgSubdomain)
                const organization = res.data
                return { organization }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        head() {
            return {
                title: this.withUnreadCount(`Stats - ${this.organization.name}`),
            }
        },

        scrollToTop: true,

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Stats',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/stats`,
        }),
    }
</script>
