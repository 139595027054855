// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container post-section my-4">
        <section-title :text="$t('createOrganization.title')" class="mb-2" />

        <el-alert
            type="warning"
            :title="$t('common.alert.warning')"
            show-icon
            class="mb-3"
        >
            {{ $t('createOrganization.alert') }}
        </el-alert>

        <profile-form
            :save="create"
            label-save-button="createOrganization.create"
            @success="({ slug }) => $router.push(`/o/${slug}/info/edit`)"
        />
    </div>
</template>

<script>
    import { create } from '~/api/organizations'
    import ProfileForm from '~/components/organizations/form/Profile.vue'
    import SectionTitle from '~/components/settings/SectionTitle.vue'

    export default {
        middleware: 'createOrganizations',
        components: {
            ProfileForm,
            SectionTitle,
        },

        methods: {
            create,
        },
    }
</script>
