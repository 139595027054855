// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <img
        :src="urlImage"
        class="border-avt-by-level"
        :width="width"
        :height="height"
    >
</template>

<script>
    export default {
        props: {
            level: {
                type: String,
                require: true,
            },
            width: {
                type: Number,
                default: 80,
            },
            height: {
                type: Number,
                default: 80,
            },
            isSubdomain: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            urlImage() {
                switch (this.level) {
                    case 'gold':
                        return '/images/avt-level-gold.png'
                    case 'diamond':
                        return this.isSubdomain
                            ? '/images/avt-diamond-subdomain.png'
                            : '/images/avt-level-diamond.png'
                    case 'silver':
                        return '/images/avt-level-silver.png'
                    default:
                        return null
                }
            },
        },
    }
</script>

<style type="scss">
    .border-avt-by-level {
        z-index: 1;
    }
</style>
