// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="section-title-line">
        <h2>{{ text }}</h2>
        <hr class="filler-line">
    </div>
</template>

<script>
    export default {
        props: {
            text: String,
        },
    }
</script>
