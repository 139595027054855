// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="title-navbar-top-members">{{ $t('navbarOrg.topMembers') }}</span>
            <el-button class="see-all-members" @click="redirect">
                {{ $t('navbarOrg.seeAll') }}
            </el-button>
        </div>

        <section class="members-top block-exhibition row">
            <div
                v-for="(member, index) in topMembers"
                :key="index"
                class="members-avatar col-12"
            >
                <user-grid-item
                    :user-id="member.id"
                    :is-follower="true"
                />
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UserGridItem from '~/components/subdomains/users/UserItem.vue'

    export default {
        components: {
            UserGridItem,
        },

        props: {
            topMembers: {
                type: Array,
                default: () => [],
            },
            organization: Object,
        },

        methods: {
            redirect() {
                if (this.$route.path !== `/o/${this.organization.slug}/members`) {
                    const redirectPath = this.$store.state.config.vars.is_special_organization ? '/members' : `/o/${this.organization.slug}/members`
                    this.$router.push(redirectPath)
                }
            },

            ...mapActions('entities/users', ['follow']),
        },

        provide() {
            return {
                follow: this.follow,
            }
        },

        companyAnalytics: route => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${route.params.organization}/members`,
        }),
    }
</script>

<style>
    .members-top .members-avatar{
        padding: 5px 15px;
    }

    .seemore-button {
        position: absolute;
        left: 30%;
        top:85%;
    }

    .more-members-btn {
        vertical-align: top;
        width: 37px;
        height: 37px;
    }

    .title-navbar-top-members {
        font-size: 20px;
        line-height: 23px;
        font-family: 'RobotoCondensed Medium', sans-serif;
    }

    .see-all-members {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #0EA8FF;
        border: none;
    }
</style>

<style lang="scss" scoped>
.theme-dark {
    .title-navbar-top-members {
        color: #fff;
        font-weight: 500;
    }

    .see-all-members {
        background-color: #0D1117;
        color: #fff;
    }
}
</style>
