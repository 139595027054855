<template>
    <div class="py-1">
        <ElAlert
            v-if="errors"
            :title="errors.message"
            type="error"
            class="mb-1"
            show-icon
        >
            <ul>
                <li v-for="(error, index) in errors.errors" :key="index">
                    {{ error[0] }}
                </li>
            </ul>
        </ElAlert>

        <el-input
            v-model="search"
            class="mb-2"
            size="small"
            :placeholder="$t('statsOrganization.contribution.search')"
        >
            <i slot="prefix" class="el-input__icon el-icon-search" />
        </el-input>
        <Table
            :users="filterUser"
            :header="header"
        />
    </div>
</template>

<script>
    import Table from '~/components/organizations/stats/Table.vue'

    export default {
        components: {
            Table,
        },

        props: {
            users: {
                type: Array,
                default: () => ([]),
            },

            header: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                search: '',
                errors: null,
            }
        },

        computed: {
            filterUser() {
                return this.users.filter(data => !this.search || data.name.toLowerCase()
                    .includes(this.search.toLowerCase()))
            },
        },
    }
</script>
