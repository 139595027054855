// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="organization-stats full-width">
        <ElTable
            :data="users"
            class="full-width"
            stripe
        >
            <ElTableColumn type="index" align="center" :label="$t('statsOrganization.contribution.table.stt')" />

            <ElTableColumn
                :label="$t('statsOrganization.contribution.table.name')"
                prop="name"
                class-name="word-break"
                align="center"
                sortable
                width="200"
            >
                <template slot-scope="scope">
                    <div class="d-flex">
                        <UserAvatar
                            :image="scope.row.avatar"
                            :username="scope.row.username"
                            size="sm"
                        />
                        <div class="d-inline-block text-left ml-1 my-auto">
                            <nuxt-link :to="toUser(scope.row)">
                                {{ scope.row.name }}
                            </nuxt-link>
                        </div>
                    </div>
                </template>
            </ElTableColumn>

            <ElTableColumn
                prop="total_viblo_reputations"
                class-name="word-break"
                align="center"
                sortable
            >
                <template v-slot:header>
                    {{ $t('statsOrganization.contribution.table.reputations') }} <br>
                    ({{ header.total_viblo_reputations || 0 }})
                </template>
                <template slot-scope="scope">
                    <!-- eslint-disable -->
                    <span
                        v-tooltip
                        :title="$t('statsOrganization.contribution.table.tootipReputations').concat(`${scope.row.total_viblo_reputations}`)"
                    >
                        {{ scope.row.total_viblo_reputations | formatNumber }}
                    </span>
                    <!--eslint-enable-->
                </template>
            </ElTableColumn>

            <ElTableColumn
                :label="$t('statsOrganization.contribution.table.publish')"
                class-name="word-break"
                header-align="center"
            >
                <ElTableColumn
                    prop="total_organization_posts"
                    class-name="word-break"
                    align="center"
                    sortable
                >
                    <template v-slot:header>
                        {{ $t('statsOrganization.contribution.table.inOrganization') }} <br>
                        ({{ header.total_organization_posts || 0 }})
                    </template>
                    <template slot-scope="scope">
                        <!-- eslint-disable -->
                        <span
                            v-tooltip
                            :title="$t('statsOrganization.contribution.table.organizationPosts').concat(`${scope.row.total_organization_posts}`)"
                        >
                            {{ scope.row.total_organization_posts | formatNumber }}
                        </span>
                        <!--eslint-enable-->
                    </template>
                </ElTableColumn>

                <ElTableColumn
                    prop="total_viblo_posts"
                    class-name="word-break"
                    align="center"
                    sortable
                >
                    <template v-slot:header>
                        {{ $t('statsOrganization.contribution.table.allIn') }} <br>
                        ({{ header.total_viblo_posts || 0 }})
                    </template>
                    <template slot-scope="scope">
                        <!-- eslint-disable -->
                        <span
                            v-tooltip
                            :title="$t('statsOrganization.contribution.table.toolTipAllIn').concat(`${scope.row.total_viblo_posts}`)"
                        >
                            {{ scope.row.total_viblo_posts | formatNumber }}
                        </span>
                        <!--eslint-enable-->
                    </template>
                </ElTableColumn>
            </ElTableColumn>

            <ElTableColumn
                prop="total_viblo_postviews"
                class-name="word-break"
                header-align="center"
                align="center"
                sortable
            >
                <template v-slot:header>
                    {{ $t('statsOrganization.contribution.table.postViews') }} <br>
                    ({{ header.total_viblo_postviews || 0 }})
                </template>
                <template slot-scope="scope">
                    <!-- eslint-disable -->
                    <span
                        v-tooltip
                        :title="$t('statsOrganization.contribution.table.tootipPostView').concat(`${scope.row.total_viblo_postviews}`)"
                    >
                        {{ scope.row.total_viblo_postviews | formatNumber }}
                    </span>
                    <!--eslint-enable-->
                </template>
            </ElTableColumn>

            <ElTableColumn
                prop="total_viblo_answers"
                class-name="word-break"
                header-align="center"
                align="center"
                sortable
            >
                <template v-slot:header>
                    {{ $t('statsOrganization.contribution.table.answers') }} <br>
                    ({{ header.total_viblo_answers || 0 }})
                </template>
                <template slot-scope="scope">
                    <!-- eslint-disable -->
                    <span
                        v-tooltip
                        :title="$t('statsOrganization.contribution.table.tootipAnswers').concat(`${scope.row.total_viblo_answers}`)"
                    >
                        {{ scope.row.total_viblo_answers | formatNumber }}
                    </span>
                    <!--eslint-enable-->
                </template>
            </ElTableColumn>
        </ElTable>
    </div>
</template>

<script>
    import UserAvatar from '~/components/shared/user/Avatar.vue'
    import { user as toUser } from '~/utils/url'

    export default {
        components: {
            UserAvatar,
        },

        props: {
            users: {
                type: Array,
                default: () => ([]),
            },

            header: {
                type: Object,
                default: () => ({}),
            },
        },

        methods: {
            toUser,
        },
    }
</script>

<style lang="scss">
    .organization-stats .word-break > .cell {
        word-break: break-word;
    }

    .organization-stats .el-table__body-wrapper {
        max-height: 70vh;
        overflow-y: auto;
    }

    .theme-dark {
        .organization-stats {
            .el-table thead.is-group th {
                background-color: #000;
            }

            .el-table tr, th, .el-table {
                background-color: #000;
                color: #fff;

                tr:hover {
                background-color: #464646;
                }
            }

            .el-table--striped .el-table__body tr.el-table__row--striped td {
                background-color: #000;
            }

            .el-table--enable-row-hover .el-table__body tr:hover > td {
                background-color: #464646;
            }

            .el-table {
                padding-bottom: 0 !important;
            }

            .section-title-line, .el-table__empty-text {
                color: #fff;
            }

            .el-button--danger {
                background-color: #fff;
                border: none;

                .el-icon-delete {
                    color: #464646;
                }
            }
        }
}
</style>
