// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="viblo-editor--smde">
        <textarea
            ref="textarea"
            :name="name"
            :value="value"
            style="display:none"
        />

        <client-only>
            <div class="no-ssr">
                <Suggestions
                    v-if="!fullScreen"
                    ref="suggestions"
                    :typing-text="typingText"
                    :style="{
                        top: `${suggestionBoxPosition.top}px`,
                        left: `${suggestionBoxPosition.left}px`
                    }"
                    @select="applySuggestion"
                    @pending-complete="adjustSuggestionBoxPosition"
                    @add-emoji-suggestion="addEmojiSuggestion"
                />

                <ImageFinder ref="imageFinder" @image-picked="insertImage" />
                <MatexDetection ref="matexDetection" @matex-picked="insertText" />
                <MarkdownHelpDialog ref="md-help" />
                <EmojiSuggestionDialog ref="emoji-suggestion-dialog" />
                <ShortcutCheatsheetDialog ref="md-shortcut" />
                <AddLinkPost ref="addLinkPost" @insertLinkPost="insertLinkPost" />
            </div>
        </client-only>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _merge from 'lodash/merge'
    import _includes from 'lodash/includes'
    import _replace from 'lodash/replace'
    import editorMixins from '../../utils/editor/mixin'
    import { full as md } from '../../lib/markdown'

    import ImageFinder from './widgets/ImageFinder.vue'
    import MatexDetection from './widgets/MatexDetection.vue'
    import Suggestions from './Suggestions/Index.vue'
    import MarkdownHelpDialog from '../help/MarkdownHelpDialog.vue'
    import EmojiSuggestionDialog from '../help/EmojiSuggestionDialog.vue'
    import ShortcutCheatsheetDialog from '../help/ShortcutCheatsheetDialog.vue'
    import AddLinkPost from '../help/AddLinkPost.vue'

    const insertTexts = {
        link: ['[', '](#url#)'],
        image: ['![](', '#url#)'],
        table: ['', '\n\n| Column 1 | Column 2 | Column 3 |\n| -------- | -------- | -------- |\n| Text     | Text     | Text     |\n\n'],
        horizontalRule: ['', '\n\n-----\n\n'],
    }

    const placeholder = 'editor.placeholder'

    const styleTools = [
        'bold',
        'italic',
        'strikethrough',
        'heading-1',
        'heading-2',
        'heading-3',
    ]

    const blockTools = [
        'code',
        'quote',
        'unordered-list',
        'ordered-list',
        'table',
        'horizontal-rule',
        'clean-block',
    ]

    const viewTools = [
        'preview',
    ]

    const editorTools = [
        'undo',
        'redo',
    ]

    export default {
        components: {
            Suggestions,
            ImageFinder,
            MatexDetection,
            MarkdownHelpDialog,
            EmojiSuggestionDialog,
            ShortcutCheatsheetDialog,
            AddLinkPost,
        },

        mixins: [editorMixins],

        props: {
            value: String,
            name: String,
            options: Object,
        },

        data: () => ({
            insertTexts,
            typingText: '',
            fullScreen: false,
            isUploading: false,
            suggestionBoxPosition: {
                top: 0,
                left: 0,
            },
            md: null,
            widgetLines: [],
            newDetectionFunction: false,
        }),

        provide() {
            const actions = {
                insertText: this.insertText,
            }

            return {
                actions,
            }
        },

        mounted() {
            const now = new Date()
            const endDate = new Date('2022-11-23')
            if (now < endDate) {
                this.newDetectionFunction = true
            }

            this.md = md()
            this.createEditor(this.$refs.textarea)

            window.addEventListener('keydown', this.overrideDefaultKeyShortcut)

            this.simplemde.codemirror.on('change', () => {
                this.$emit('input', this.simplemde.value())
                this.typingText = this.getTypingText()
            })

            this.simplemde.codemirror.on('keydown', (cm, e) => {
                this.$refs.suggestions.handleKeyEvents(e)
            })

            this.simplemde.codemirror.on('paste', (codemirror, event) => {
                this.handlePasteImage(event)
            })

            this.simplemde.codemirror.on('drop', (codemirror, event) => {
                this.handleDrop(event)
            })
        },

        destroyed() {
            window.removeEventListener('keydown', this.overrideDefaultKeyShortcut)
        },

        methods: {
            getTypingText() {
                const codemirror = this.simplemde.codemirror
                const cursor = codemirror.getCursor()

                return codemirror.getLine(cursor.line).slice(0, cursor.ch)
            },

            /**
             * @param {string} pendingText
             */
            adjustSuggestionBoxPosition(pendingText = '') {
                const codemirror = this.simplemde.codemirror
                const cursor = codemirror.getCursor()
                const localCoords = codemirror.cursorCoords({ ...cursor, ch: cursor.ch - pendingText.length }, 'local')
                const scrollCoords = codemirror.getScrollInfo()
                const lineOffsetHeight = codemirror.heightAtLine(cursor.line, 'local')
                const lineHeight = codemirror.doc.lineInfo(cursor.line).handle.height

                this.suggestionBoxPosition = {
                    top: (lineOffsetHeight - localCoords.top + lineHeight - 20) * -1,
                    left: localCoords.left - scrollCoords.left,
                }
            },

            internalValue(text) {
                return this.simplemde.value(text)
            },

            overrideDefaultKeyShortcut(e) {
                if (e.ctrlKey) {
                    this.ctrlKeyCombinations(e)
                }
            },

            ctrlKeyCombinations(event) {
                if (_includes([80, 83, 75], event.keyCode)) {
                    event.preventDefault()
                }

                switch (event.keyCode) {
                    case 80:
                        // Ctrl + P
                        this.simplemde.togglePreview()
                        break
                    case 83:
                        // Ctrl + S
                        this.$emit('save')
                        break
                    case 75:
                        // Ctrl + K
                        this.$refs.addLinkPost.open()
                        break
                    default:
                }

                if (event.shiftKey) {
                    this.ctrlShiftKeyCombinations(event)
                }
            },

            ctrlShiftKeyCombinations(event) {
                if (_includes([69, 76, 82], event.keyCode)) {
                    event.preventDefault()
                }

                const codemirror = this.simplemde.codemirror
                const selections = codemirror.getSelections()

                switch (event.keyCode) {
                    case 69:
                        // Ctrl + Shift + E
                        this.insertText(`<div align="center">${selections}</div>`)
                        break
                    case 76:
                        // Ctrl + Shift + L
                        this.insertText(`<div align="left">${selections}</div>`)
                        break
                    case 82:
                        // Ctrl + Shift + R
                        this.insertText(`<div align="right">${selections}</div>`)
                        break
                    default:
                }
            },

            applySuggestion({ type, suggestion, pending }) {
                const apply = _get({
                    emoji: this.completeEmoji,
                }, type, () => {})

                apply(suggestion, pending)
            },

            completeEmoji(emoji, pending) {
                const codemirror = this.simplemde.codemirror
                const cursors = codemirror.listSelections()

                const primaryCursor = codemirror.getCursor()
                const nextChar = codemirror.getRange(primaryCursor, { ...primaryCursor, ch: primaryCursor.ch + 1 })
                const shouldInsertSpace = !(/\s/.test(nextChar))

                const replacements = cursors.map((cursor) => {
                    const anchor = cursor.anchor
                    const matchedPendingText = codemirror
                        .getRange({ ...anchor, ch: anchor.ch - pending.length - 1 }, anchor)

                    const replacement = matchedPendingText === `:${pending}` ? `${emoji.slice(pending.length)}:` : `:${emoji}:`

                    return shouldInsertSpace ? `${replacement} ` : replacement
                })

                codemirror.replaceSelections(replacements)
            },

            insertLink(link) {
                const codemirror = this.simplemde.codemirror
                const selections = codemirror.getSelections()
                const replacements = selections.map(text => `[${text}](${link})`)

                const currentCursors = codemirror.listSelections()
                const nextCursors = currentCursors.map(range => ({
                    anchor: { ...range.anchor, ch: range.anchor.ch + 1 },
                    head: { ...range.head, ch: range.head.ch + 1 },
                }))

                codemirror.replaceSelections(replacements, 'start')
                codemirror.setSelections(nextCursors)
                codemirror.focus()
            },

            handlePasteImage(event) {
                const file = this.getImageFromClipboard(event)

                if (file && !this.isUploading) {
                    this.uploadAndInsertImage(file)
                }
            },

            handleDrop(event) {
                const file = this.getImageFromDropEvent(event)

                if (file && !this.isUploading) {
                    this.uploadAndInsertImage(file)
                } else {
                    const imageUrl = this.getImageUrlFromDropEvent(event)

                    if (imageUrl) {
                        event.preventDefault()
                        this.insertImage({ path: imageUrl })
                    }
                }
            },

            async uploadAndInsertImage(file) {
                const codemirror = this.simplemde.codemirror
                const cursor = codemirror.getCursor()
                const uploadingText = this.imageUploadMdText(file)

                this.isUploading = true
                this.insertText(uploadingText)
                const image = await this.uploadImage(file)
                const imageMdText = this.imageMdText(image)
                this.isUploading = false
                this.replaceText(uploadingText, imageMdText)

                const currentCursor = codemirror.getCursor()
                if (currentCursor.line === cursor.line && currentCursor.ch < cursor.ch + imageMdText.length) {
                    cursor.ch += imageMdText.length
                    codemirror.setCursor(cursor)
                }
            },

            insertImage(image) {
                const url = image.path
                const cm = this.simplemde.codemirror

                const selectionStart = cm.getCursor('from')
                const selectionEnd = cm.getCursor('to')

                const startToken = cm.getTokenAt(selectionStart)
                const endToken = cm.getTokenAt(selectionEnd)

                const cursorInUrlToken = _includes(startToken.type, 'string url')
                    && _includes(endToken.type, 'string url')

                const text = cursorInUrlToken ? url : '![]($url)\n'.replace('$url', url)

                cm.replaceRange(text, selectionStart, selectionEnd)
                cm.focus()
            },

            insertText(text) {
                const cm = this.simplemde.codemirror

                const selectionStart = cm.getCursor('from')
                const selectionEnd = cm.getCursor('to')

                cm.replaceRange(text, selectionStart, selectionEnd)
                cm.focus()
            },

            replaceText(searchText, text) {
                const codemirror = this.simplemde.codemirror
                const cursor = codemirror.getCursor()
                const value = codemirror.getValue()

                codemirror.getDoc().setValue(_replace(value, searchText, text))
                codemirror.getDoc().setCursor(cursor)
            },

            insertLinkPost(linkPost) {
                this.insertLink(linkPost)
                this.$refs.addLinkPost.close()
            },

            createEditor(textarea) {
                // Local require to avoid server-side issues
                const SimpleMDE = require('simplemde')
                const _this = this

                const linkAction = {
                    name: 'link',
                    title: _this.$t('editor.actions.link'),
                    className: 'fa fa-link',
                    action: () => {
                        this.$refs.addLinkPost.open()
                    },
                }
                const imageAction = {
                    name: 'imageAction',
                    action: () => {
                        this.$refs.imageFinder.open()
                    },
                    className: 'fa fa-image',
                    title: _this.$t('editor.actions.image'),
                }
                const embedAction = {
                    name: 'embed',
                    title: _this.$t('editor.actions.embed'),
                    className: 'fa fa-file-code-o',
                    action: () => {
                        this.$prompt(
                            _this.$t('editor.actions.embedPopup.label'),
                            _this.$t('editor.actions.embedPopup.title'),
                            {
                                confirmButtonText: _this.$t('editor.actions.embedPopup.ok'),
                                cancelButtonText: _this.$t('editor.actions.embedPopup.cancel'),
                                inputPattern: /^http[s]?:\/\/(?:www\.)?(youtube\.com|vimeo\.com|slideshare\.net|codepen\.io|gist\.github\.com|jsfiddle\.net|docs\.google\.com|soundcloud\.com)/, /* eslint-disable-line */
                                inputErrorMessage: _this.$t('editor.actions.embedPopup.invalid') /* eslint-disable-line */
                            }
                        ).then(({ value }) => this.insertText(`{@embed: ${value}}`))
                    },
                }
                const detectMatexAction = {
                    name: 'detectMatexAction',
                    action: () => {
                        this.$refs.matexDetection.open()
                    },
                    className: this.newDetectionFunction ? 'new-function fa fa-superscript' : 'fa fa-superscript',
                    title: _this.$t('editor.actions.detectMatex'),
                }
                const alignCenter = {
                    name: 'center',
                    title: _this.$t('editor.actions.alignCenter'),
                    className: 'fa fa-align-center',
                    action: () => {
                        const codemirror = this.simplemde.codemirror
                        const selections = codemirror.getSelections()
                        this.insertText(`<div align="center">${selections}</div>`)
                    },
                }

                const alignLeft = {
                    name: 'left',
                    title: _this.$t('editor.actions.alignLeft'),
                    className: 'fa fa-align-left',
                    action: () => {
                        const codemirror = this.simplemde.codemirror
                        const selections = codemirror.getSelections()
                        this.insertText(`<div align="left">${selections}</div>`)
                    },
                }

                const alignRight = {
                    name: 'right',
                    title: _this.$t('editor.actions.alignRight'),
                    className: 'fa fa-align-right',
                    action: () => {
                        const codemirror = this.simplemde.codemirror
                        const selections = codemirror.getSelections()
                        this.insertText(`<div align="right">${selections}</div>`)
                    },
                }

                const helpAction = {
                    name: 'help',
                    action: () => {
                        this.$refs['md-help'].show()
                    },
                    className: 'fa fa-question-circle write-post-step-4',
                    title: _this.$t('editor.actions.help'),
                }

                const shortcutAction = {
                    name: 'shortcut',
                    action: () => {
                        this.$refs['md-shortcut'].show()
                    },
                    className: 'fa fa-info',
                    title: _this.$t('editor.actions.shortcut'),
                }

                const emojiSuggestion = {
                    name: 'emoji',
                    action: () => {
                        this.$refs['emoji-suggestion-dialog'].open()
                    },
                    className: 'fa fa-smile-o',
                    title: _this.$t('editor.actions.emoji'),
                }

                const fullscreenAction = {
                    name: 'fullscreen',
                    action: () => {
                        this.simplemde.toggleFullScreen()
                    },
                    className: 'fa fa-arrows-alt no-disable no-mobile',
                    title: _this.$t('editor.actions.fullscreen'),
                }

                const sideBySideAction = {
                    name: 'side-by-side',
                    action: () => {
                        this.simplemde.isFullscreenActive()
                        this.simplemde.toggleSideBySide()
                    },
                    className: 'fa fa-columns no-disable no-mobile',
                    title: _this.$t('editor.actions.sideBySide'),
                }

                const statusBar = [{
                    className: 'uploading',
                    defaultValue() {},
                    onUpdate(el) {
                        if (_this.isUploading) {
                            el.innerHTML = `<i class="el-icon-loading"></i><span class="ml-05">${_this.$t('editor.upload.fileUploading')}...</span>`
                        } else {
                            el.innerHTML = null
                        }
                    },
                }, 'autosave', 'lines', 'words', 'cursor']

                const options = _merge({
                    tabSize: 4,
                    spellChecker: false,
                    indentWithTabs: false,
                    autoDownloadFontAwesome: false,
                    element: textarea,
                    placeholder: this.$t(placeholder),
                    toolbar: [
                        ...styleTools, '|',
                        ...blockTools, '|',
                        linkAction, imageAction, embedAction, detectMatexAction, '|',
                        alignLeft, alignCenter, alignRight, '|',
                        ...viewTools, sideBySideAction, fullscreenAction, '|',
                        ...editorTools, '|',
                        helpAction,
                        emojiSuggestion,
                        shortcutAction,
                    ],
                    previewRender: _ => this.md.render(_),
                    shortcuts: {
                        togglePreview: null,
                        drawLink: null,
                    },
                    status: statusBar,
                }, this.options)

                this.simplemde = new SimpleMDE(options)
            },

            addEmojiSuggestion(data) {
                const codemirror = this.simplemde.codemirror

                this.widgetLines.forEach((indexLine) => {
                    const widgets = codemirror.doc.lineInfo(indexLine).widgets

                    if (widgets) {
                        widgets.forEach(widget => codemirror.doc.removeLineWidget(widget))
                    }
                })

                this.widgetLines = []

                if (data.status) {
                    const cursorLine = codemirror.getCursor().line
                    codemirror.doc.addLineWidget(cursorLine, this.$refs.suggestions.$el)
                    this.widgetLines.push(cursorLine)
                }
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/fonts";
    @import "simplemde/src/css/simplemde.css";

    .viblo-editor--smde {
        font-family: $font-family-sans-serif;

        .CodeMirror-line {
            .cm-comment {
                font-family: $font-family-monospace;
            }
        }

        .CodeMirror-placeholder {
            font-family: FontAwesome, $font-family-sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .editor-toolbar.fullscreen, .CodeMirror-fullscreen, .editor-preview-side {
            z-index: 1100 !important;
        }

        .editor-toolbar {
            opacity: 1;
        }

        .editor-statusbar {
            display: flex;
            font-weight: bold;
            color: $dark;

            .uploading {
                flex-grow: 1;
                text-align: left;
                margin-left: 0px;
            }
        }

        .editor-preview, .editor-preview-side {
            font-family: $font-family-sans-serif;
            line-height: inherit;
            p {
                margin-bottom: 1rem;
            }
        }

        .CodeMirror-fullscreen, .editor-toolbar.fullscreen, .editor-preview-side {
            z-index: 1100;
        }
    }

    .el-form-item {
        &.smde-editor-inside {
            .el-form-item {
                &__error {
                    top: -25px;
                }

                &__content {
                    line-height: inherit;

                }

            }
        }
    }

    .is-error {
        .viblo-editor--smde {
            .editor-toolbar, .CodeMirror {
                border-color: $danger;
            }

            .CodeMirror {
                border-top-color: #ddd;
            }
        }
    }

    .editor-preview-side > pre {
        background: #2d2d2d;
    }

    .CodeMirror-linewidget {
        z-index: 10;
        padding: 0px;
    }

    .suggestion-container {
        z-index: 999;
    }

    .new-function::after {
        content: '';
        position: absolute;
        height: .65em;
        width: .65em;
        background: red;
        border-radius: 50%;
    }
</style>
