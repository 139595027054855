// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <profile-form
            :organization="organization"
            :save="update"
            label-save-button="createOrganization.update"
        />
    </div>
</template>

<script>
    import { update, getOrganizationEdit } from '~/api/organizations'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import ProfileForm from '~/components/subdomains-theme-2/organizations/form/Profile.vue'

    export default {
        components: {
            ProfileForm,
        },

        async asyncData({
            store, route, error, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const slug = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : route.params.organization

            const organization = await getOrganizationEdit(slug)
                .then(({ data }) => data.data)
                .catch(handlePageError(error))

            return { organization, slug }
        },

        methods: {
            update(data) {
                return update(this.slug, data)
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'info edit',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/info/edit`,
        }),
    }
</script>
