// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        :title="$t('editor.emoji.title')"
        class="emoji-suggestion-dialog"
        size="small"
    >
        <emoji-suggestion class="d-flex justify-content-center" @emojiClick="handleEmojiClick" />
    </v-dialog>
</template>

<script>
    export default {
        components: {
            EmojiSuggestion: () => import('./EmojiSuggestion.vue'),
        },

        data() {
            return {
                show: false,
                emojiSuggestionStyle: { margin: 'auto' },
            }
        },

        inject: ['actions'],

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            handleEmojiClick(detail) {
                this.actions.insertText(detail.unicode)
            },
        },
    }
</script>
