// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog :show.sync="show">
        <div slot="title" class="mb-1">
            <span class="dialog-custom-heading"> {{ $t(title) }} </span>
        </div>
        <div class="flex flex-column">
            <div id="app" class="d-flex justify-content-center">
                <croppa
                    v-model="myCroppa"
                    :width="260"
                    :height="260"
                    :image-border-radius="300"
                    prevent-white-space
                    canvas-color="transparent"
                    @image-remove="handleImageRemove"
                />
            </div>
            <p class="text-center mt-1 title-tip">
                <small>{{ $t('uploadAvt.tip') }}</small>
            </p>
            <div class="picture-toolbox text-center mt-4">
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-reply fa-rotate-270"
                    title="Rotate the image 90 degrees to the left"
                    @click="myCroppa.rotate(-1)"
                />
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-share fa-rotate-90"
                    title="Rotate the image 90 degrees to the right"
                    @click="myCroppa.rotate()"
                />
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-repeat fa-rotate-90"
                    title="Flip vertical"
                    @click="myCroppa.flipX()"
                />
                <ElButton
                    :disabled="hasNotImage || processing"
                    :loading="processing"
                    type="primary"
                    @click="generateImage"
                >
                    {{ $t('uploadAvt.save') }}
                </ElButton>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    import { uploadFile } from '~/api/uploads'

    export default {
        props: {
            title: {
                type: String,
                default: 'uploadAvt.title',
            },
        },

        data() {
            return {
                show: false,
                myCroppa: null,
                processing: false,
            }
        },

        computed: {
            hasNotImage() {
                return this.myCroppa && !this.myCroppa.imageSet
            },
        },

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            generateImage() {
                this.myCroppa.generateBlob(async (blob) => {
                    try {
                        this.processing = true
                        const formData = new FormData()
                        formData.append('file', blob, this.myCroppa.chosenFile.name)
                        const image = await uploadFile(formData)
                        this.$emit('getIdImage', image.data.data)
                        this.show = false
                    } catch (error) {
                        this.handleError(error)
                    } finally {
                        this.processing = false
                    }
                })
            },

            handleImageRemove() {
                this.$emit('removeImage')
            },
        },
    }
</script>
