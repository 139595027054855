// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <el-popover
            v-model="visible"
            placement="bottom-start"
            transition="el-zoom-in-top"
            trigger="click"
            class="manage-o-menu"
        >
            <el-button
                slot="reference"
                type="text"
                class="border-0 text-o-role outline-none"
            >
                <span class="text-capitalize">{{ $t(`inviteInOrgaization.${organization.pivot.role}`) }}</span>
                <i class="el-icon-arrow-down el-icon--right" />
            </el-button>

            <template>
                <div class="company-menu">
                    <nuxt-link
                        :to="`/publish/post`"
                        class="link d-block py-05"
                    >
                        {{ $t('organization.companyItem.menuAction.writePost') }}
                    </nuxt-link>
                    <nuxt-link
                        v-if="isOwner || isEditor || isAdmin"
                        :to="toOrganization(organization, 'submissions')"
                        class="link d-block py-05"
                    >
                        {{ $t('organization.companyItem.menuAction.submissions') }}
                    </nuxt-link>
                    <nuxt-link
                        v-if="isOwner || isAdmin"
                        :to="toOrganization(organization, 'invitations')"
                        class="link d-block py-05"
                    >
                        {{ $t('organization.companyItem.menuAction.manageMembers') }}
                    </nuxt-link>
                    <nuxt-link
                        v-if="isOwner"
                        :to="toOrganization(organization, 'info/edit')"
                        class="link d-block py-05"
                    >
                        {{ $t('organization.companyItem.menuAction.editOrganization') }}
                    </nuxt-link>
                    <hr class="my-05">
                    <el-button
                        v-if="isOwner"
                        type="text"
                        class="link link--inherit-color d-block outline-none py-05"
                        @click="this.delete"
                    >
                        {{ $t('organization.companyItem.menuAction.deleteOrganization') }}
                    </el-button>
                    <el-button
                        v-if="!isOwner"
                        type="text"
                        class="link link--inherit-color d-block outline-none py-05 ml-0"
                        @click="leave"
                    >
                        {{ $t('organization.companyItem.menuAction.leaveOrganization') }}
                    </el-button>
                </div>
            </template>
        </el-popover>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { organization as toOrganization } from '~/utils/url'
    import { doNothing } from '~/utils/pages'

    export default {
        props: {
            organization: Object,
        },

        data: () => ({ visible: false }),

        computed: {
            ...mapGetters('auth', { authID: 'userId' }),

            isOwner() {
                return this.organization.pivot.role === 'owner'
            },

            isAdmin() {
                return this.organization.pivot.role === 'admin'
            },

            isEditor() {
                return this.organization.pivot.role === 'editor'
            },
        },

        inject: [
            'leaveCompany',
            'deleteCompany',
        ],

        methods: {
            toOrganization,

            toSetting(page = '') {
                return `/o/${this.organization.slug}/${page}`
            },

            toggleMenu() {
                this.visible = !this.visible
            },

            delete() {
                this.toggleMenu()
                this.$confirm(this.$t('organization.companyItem.confirmDelete.content'), this.$t('organization.companyItem.confirmDelete.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(agreed => (agreed ? this.deleteCompany(this.organization) : null))
                    .catch(doNothing)
            },

            leave() {
                this.toggleMenu()
                return this.$confirm(this.$t('organization.companyItem.confirmLeave.content'), this.$t('organization.companyItem.confirmLeave.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(agreed => (agreed ? this.leaveCompany(this.organization) : null))
                    .catch(doNothing)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .manage-o-menu {
        .text-o-role {
            color: rgba(0,0,0,.54);
            &:hover {
                color: #5488c7;
            }
        }
    }
</style>
