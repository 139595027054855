// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="subdomain-members py-2 px-1">
        <div v-if="topMembers.length > 0" class="subdomain-top-members">
            <p class="title-section">
                {{ $t('navbarOrg.topMembers') }}
            </p>
            <top-member-card
                :members="topMembers"
                :is-follower="true"
                :actions="{ follow }"
            />
        </div>
        <div class="mt-4 pt-4 subdomain-all-members">
            <p class="title-section">
                {{ $t('subdomain.AllMembers') }}
            </p>
            <user-grid :actions="{ follow }" store-path="organizationView/members" :is-follower="true">
                <template slot="empty">
                    <div class="text-empty">
                        {{ $t('detailOrgaization.noMember') }}
                    </div>
                </template>
            </user-grid>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import UserGrid from '~/components/subdomains/users/AllUserGrid.vue'
    import TopMemberCard from '~/components/subdomains/organizations/users/TopMemberCard.vue'

    export default {
        name: 'OrganizationMembers',

        components: {
            UserGrid,
            TopMemberCard,
        },

        watchQuery: ['page'],

        scrollToTop: true,

        computed: {
            ...mapState('organizationView/topMembers', { topMembers: 'topMembers' }),
        },

        fetch: async ({
            store, params, query, error, req,
        }) => {
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            await store.dispatch('organizationView/topMembers/fetch', orgSubdomain)

            return store.dispatch('organizationView/members/fetch', {
                slug: orgSubdomain,
                params: {
                    limit: store.state.settings.perPage,
                    page: query.page,
                },
            }).catch(handlePageError(error))
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/members`,
        }),
    }
</script>

<style lang="scss">
.subdomain-members {
    background-color: white;
    border-radius: 0.5rem;

    .title-section {
        font-weight: 500;
        font-size: 28px;
        font-family: 'RobotoCondensed Medium', sans-serif;
    }

    .user-stats span {
        margin-right: 8px;
    }
}
.subdomain-all-members {
    .followers-tags .item-author {
        .subscribe, .edit-profile {
            .btn-follow {
                border: 1px solid #3772FF !important;
                border-radius: 1em;
                color: black;
                padding: .5em 1em;
            }
            .following {
                color: white;
            }
        }
    }
}
.subdomain-top-members {
    .subdomain-top-members_item {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
@media screen and (min-width: 480px) {
    .subdomain-top-members {
        .subdomain-top-members_item {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
@media screen and (min-width: 768px) {
    .subdomain-top-members {
        .subdomain-top-members_item {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}
@media screen and (min-width: 1440px) {
    .subdomain-top-members {
        .subdomain-top-members_item {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
</style>

<style lang="scss" scoped>
    .theme-dark {
        .subdomain-members {
            background-color: #000;

            .title-section {
              color: #fff;
            }
        }

        .text-empty {
            color: #fff;
        }
    }
</style>
