const currentYear = (new Date()).getFullYear() // for the annual event

export const events = [
    {
        name: 'Viblo - Happy New Year 2021',
        reward: 'Viblo Keycaps (limited edition)',
        tag: {
            name: 'Happy New Year',
            text_color: '#ffffff',
            background_color: '#f8221b',
            background: 'linear-gradient(20deg, #980326, #ee5f1c)',
        },
        start_time: new Date(Date.UTC(2021, 0, 1, -7)), // -7: Asia/Ho_Chi_Minh
        end_time: new Date(Date.UTC(2021, 3, 1, -7)),
        links: {
            landing_page: 'https://tet.viblo.asia',
            reward_page: 'https://www.facebook.com/viblo.asia/posts/1698770193628103',
        },
    },
    {
        name: 'May Fest',
        reward: 'rewards',
        tag: {
            name: 'MayFest2024',
            text_color: '#ffffff',
            background: 'linear-gradient(90deg, #B335EF, #2DC1F0)',
            font_family: 'Origin Tech Demo',
            line_height: '28px',
            font_size: '16px',
        },
        start_time: new Date(`${currentYear}-05-01T00:00:00.000+07:00`),
        end_time: new Date(`${currentYear}-06-01T00:00:00.000+07:00`),
        links: {
            landing_page: 'https://mayfest.viblo.asia',
            reward_page: 'https://mayfest.viblo.asia/#section-prize',
        },
    },
    {
        name: 'Khai Bút Đầu Xuân',
        reward: 'rewards',
        tag: {
            name: 'KhaiButDauXuan',
            text_color: '#fd4e73',
            background_color: '#fea3b8',
            background: 'linear-gradient(20deg, #fea3b8, #fff4e8)',
        },

        start_time: new Date('2022-01-01T00:00:00.000+07:00'),
        end_time: new Date('2022-01-29T00:00:00.000+07:00'),
        links: {
            landing_page: 'https://viblo.asia/announcements/khai-but-dau-xuan-nhan-ngay-li-xi-tu-Qbq5QBnzKD8',
            reward_page: 'https://viblo.asia/announcements/khai-but-dau-xuan-nhan-ngay-li-xi-tu-Qbq5QBnzKD8',
        },
    },
]
