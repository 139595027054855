// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-select
        v-model="selectedRole"
        :disabled="permissions.cannotEdit"
        size="small"
        style="max-width: 120px"
        @change="confirmChangeRole"
    >
        <el-option value="writer" :label="$t('inviteInOrgaization.writer')" />
        <el-option value="editor" :label="$t('inviteInOrgaization.editor')" />
        <el-option value="admin" :label="$t('inviteInOrgaization.admin')" />
        <el-option v-if="isOwner" value="owner" :label="$t('inviteInOrgaization.owner')" />
    </el-select>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { displayError } from '~/utils/pages'

    export default {
        props: {
            user: Object,
            changeRole: Function,
            isOwner: Boolean,
            authIsOwner: Boolean,
            currentRole: String,
            permission: Boolean,
        },
        data() {
            return {
                selectedRole: this.currentRole,
            }
        },


        computed: {
            ...mapGetters('auth', ['userId']),

            permissions() {
                return {
                    cannotEdit: this.user.id === this.userId || this.isOwner,
                }
            },
        },

        methods: {
            confirmChangeRole(newRole) {
                const message = newRole === 'owner' && this.authIsOwner
                    ? this.$t('inviteInOrgaization.confirmChangeRole.setOwner').concat(`${this.user.name}`).concat(this.$t('inviteInOrgaization.confirmChangeRole.as')).concat(`${newRole}`)
                        .concat(this.$t('inviteInOrgaization.confirmChangeRole.roleOwner'))
                    : this.$t('inviteInOrgaization.confirmChangeRole.set').concat(`${this.user.name}`).concat(this.$t('inviteInOrgaization.confirmChangeRole.as')).concat(`${newRole}?`)

                this.$confirm(message, this.$t('inviteInOrgaization.confirmChangeRole.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => {
                        this.changeRole(this.user, newRole)
                            .then(() => this.$message.success(this.$t('inviteInOrgaization.confirmChangeRole.success')))
                            .catch(displayError(this.$message.error))
                    })
                    .catch(() => { this.selectedRole = this.currentRole })
            },
        },
    }
</script>
