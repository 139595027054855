// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="posts.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <PostPin
            :subdomain="orgSubdomain"
            :posts="postPins"
            :org-posts="posts"
            :is-owner="isOwner || isAdmin"
        />
        <div class="post-feed-org py-2 px-1">
            <p class="title-all-posts">
                {{ $t('allPosts') }}
            </p>
            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
                class="mb-4"
            />

            <FeedItem
                v-for="(post, index) in posts"
                :key="index"
                :post="post"
                :show-organization="false"
                :show-preview="layout === 'preview'"
                :is-owner="isOwner"
            />

            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Empty from '~/components/shared/Empty.vue'
    import { fetchPostOrganization, getListPostPinOrganization } from '~/api/organizations'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import FeedItem from '~/components/subdomains/posts/PostFeedItem.vue'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import PostPin from '~/components/subdomains/posts/PostPin.vue'

    export default {
        name: 'PostFeed',

        components: {
            FeedItem,
            Pagination,
            PostPin,
            Empty,
        },

        props: {
            isOwner: {
                type: Boolean,
                default: false,
            },
            isAdmin: {
                type: Boolean,
                default: false,
            },
        },

        watchQuery: ['page'],

        computed: {
            ...mapState('settings', ['layout']),
        },

        async asyncData({
            store, params, query, req, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization

                const posts = await fetchPostOrganization(orgSubdomain, query)
                    .then(({ data }) => data)

                const postPins = await getListPostPinOrganization(orgSubdomain)
                    .then(({ data }) => data)

                return {
                    posts: posts.data,
                    pagination: posts.meta.pagination,
                    orgSubdomain,
                    postPins: postPins.data,
                }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Posts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}`,
        }),
    }
</script>

<style lang="scss">
    .post-feed-org {
        background-color: white;
        border-radius: 0.5rem;
        .title-all-posts {
            font-weight: 500;
            font-size: 30px;
            font-family: 'RobotoCondensed Medium', sans-serif;
        }
        max-width: 836px;
    }
</style>

<style lang="scss" scoped>
.theme-dark {
    .post-feed-org {
        background-color: #000;
    }

    .text-empty, .title-all-posts {
        color: #fff;
    }
}
</style>
