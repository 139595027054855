// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="inviting-users">
        <HeadingTitle :title="$t('inviteInOrgaization.invitations')" />
        <inviting-user-item
            v-for="user in invitations"
            :key="user.username"
            :user="user"
        />
    </div>
</template>

<script>
    import InvitingUserItem from './UserItem.vue'
    import HeadingTitle from '~/components/subdomains-theme-2/shared/HeadingTitle.vue'

    export default {
        components: {
            InvitingUserItem,
            HeadingTitle,
        },
        props: {
            invitations: {
                type: Array,
                default: () => [],
            },
        },
    }
</script>

<style lang="scss" scoped>
.inviting-users {
    margin-bottom: 32px;
    padding: 32px 48px;
    border-radius: 8px;
    background-color: #fff;
}

.theme-dark {
    .inviting-users {
        background-color: #100F09;
        border: 1px solid #585858;
    }
}
</style>
