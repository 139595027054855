<template>
    <div class="stats-table">
        <MemberStats :users="users" :header="header" />
    </div>
</template>

<script>
    import { format, subDays } from 'date-fns'
    import MemberStats from '~/components/organizations/stats/MemberStats.vue'
    import { getOrganizationStats } from '~/api/organizations'
    import { getSlugSubdomain } from '~/utils/pages'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        components: {
            MemberStats,
        },

        props: {
            users: {
                type: Array,
                default: () => ([]),
            },

            header: {
                type: Object,
                default: () => ({}),
            },
        },

        watch: {
            '$route.query': function $routeQuery(valueNew, valueOld) {
                if (valueNew !== valueOld) {
                    this.getData()
                }
            },
        },

        methods: {
            async getData() {
                try {
                    const query = {
                        from: this.$route.query.from || dateString(subDays(new Date(), 14)),
                        to: this.$route.query.to || dateString(new Date()),
                    }

                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getSlugSubdomain(window.location.host) : this.$route.params.organization

                    const response = await getOrganizationStats(orgSubdomain, query)

                    const data = response.data

                    this.users = data.users
                    this.header = data.header
                    this.errors = null
                } catch (e) {
                    this.users = []
                    this.header = {}
                    this.errors = e.response.data
                }
            },
        },
    }
</script>

<style lang="scss">
.stats-table {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;

    .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
    }

    .el-table thead.is-group th {
        background-color: #0EA8FF;
    }

    .el-table thead {
        color: #fff;
    }

    .el-table tr td a {
        font-weight: 600;
        text-decoration: none;
    }
}

.chart {
    .ct-horizontal, .ct-vertical {
        stroke: #625151 !important;
    }
}

.theme-dark {
    .stats-table {
        background-color: #000;

        .el-table tr td a {
            color: #fff;
        }
    }

    .chart {
        .ct-horizontal, .ct-vertical {
            stroke: #fff !important;
        }
    }
}
</style>
