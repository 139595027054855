// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-flex align-items-center">
        <div class="d-flex">
            <el-select
                v-model="selectedRole"
                :disabled="permissions.cannotEdit"
                size="small"
                style="max-width: 110px"
                @change="confirmChangeRole"
            >
                <el-option value="writer" :label="$t('inviteInOrgaization.writer')" />
                <el-option value="editor" :label="$t('inviteInOrgaization.editor')" />
                <el-option value="admin" :label="$t('inviteInOrgaization.admin')" />
                <el-option v-if="isOwner" value="owner" :label="$t('inviteInOrgaization.owner')" />
            </el-select>

            <el-button
                v-if="actions.cancelInvitation"
                size="small"
                class="ml-1"
                type="danger"
                @click="confirmCancelInvitation"
            >
                {{ $t('inviteInOrgaization.cancel') }}
            </el-button>
            <el-button
                v-if="actions.removeMember && !permissions.cannotEdit"
                type="danger"
                size="small"
                icon="el-icon-delete"
                class="px-05 ml-1"
                @click="confirmRemove"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { displayError, doNothing } from '~/utils/pages'

    export default {
        props: {
            user: Object,
            actions: {
                type: Object,
                required: true,
            },
            isOwner: Boolean,
            authIsOwner: Boolean,
            currentRole: String,
        },

        data() {
            return {
                selectedRole: this.currentRole,
            }
        },

        computed: {
            ...mapGetters('auth', ['userId']),

            permissions() {
                return {
                    cannotEdit: this.user.id === this.userId || this.isOwner,
                }
            },
        },

        methods: {
            confirmCancelInvitation() {
                this.$confirm(this.$t('inviteInOrgaization.confirmCancel.title'), this.$t('inviteInOrgaization.confirmCancel.content'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => {
                        this.actions.cancelInvitation(this.user)
                            .then(() => this.$message.success(this.$t('inviteInOrgaization.confirmCancel.success')))
                            .catch(displayError(this.$message.error))
                    })
                    .catch(doNothing)
            },

            confirmChangeRole(newRole) {
                const message = newRole === 'owner' && this.authIsOwner
                    ? this.$t('inviteInOrgaization.confirmChangeRole.setOwner').concat(`${this.user.name}`).concat(this.$t('inviteInOrgaization.confirmChangeRole.as')).concat(`${newRole}`)
                        .concat(this.$t('inviteInOrgaization.confirmChangeRole.roleOwner'))
                    : this.$t('inviteInOrgaization.confirmChangeRole.set').concat(`${this.user.name}`).concat(this.$t('inviteInOrgaization.confirmChangeRole.as')).concat(`${newRole}?`)

                this.$confirm(message, this.$t('inviteInOrgaization.confirmChangeRole.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => {
                        this.actions.changeRole(this.user, newRole)
                            .then(() => this.$message.success(this.$t('inviteInOrgaization.confirmChangeRole.success')))
                            .catch(displayError(this.$message.error))
                    })
                    .catch(() => { this.selectedRole = this.currentRole })
            },

            confirmRemove() {
                this.$confirm(this.$t('inviteInOrgaization.confirmRemove.title'), this.$t('inviteInOrgaization.confirmRemove.content'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.actions.removeMember(this.user))
                    .catch(() => {})
            },
        },
    }
</script>
