// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog :show.sync="showing" :title="$t('shortcutCheatsheet.title')" class="md-shortcut-dialog">
        <div v-once>
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('shortcutCheatsheet.shortcut') }}</th>
                        <th>{{ $t('shortcutCheatsheet.description') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ctrl-'</td>
                        <td>{{ $t('shortcutCheatsheet.row1.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-B</td>
                        <td>{{ $t('shortcutCheatsheet.row2.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-E</td>
                        <td>{{ $t('shortcutCheatsheet.row3.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-H</td>
                        <td>{{ $t('shortcutCheatsheet.row4.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-I</td>
                        <td>{{ $t('shortcutCheatsheet.row5.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-K</td>
                        <td>{{ $t('shortcutCheatsheet.row6.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-L</td>
                        <td>{{ $t('shortcutCheatsheet.row7.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-P</td>
                        <td>{{ $t('shortcutCheatsheet.row8.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-S</td>
                        <td>{{ $t('shortcutCheatsheet.row9.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Alt-C</td>
                        <td>{{ $t('shortcutCheatsheet.row10.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Alt-I</td>
                        <td>{{ $t('shortcutCheatsheet.row11.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Alt-L</td>
                        <td>{{ $t('shortcutCheatsheet.row12.description') }}</td>
                    </tr>
                    <tr>
                        <td>Shift-Ctrl-H</td>
                        <td>{{ $t('shortcutCheatsheet.row13.description') }}</td>
                    </tr>
                    <tr>
                        <td>F9</td>
                        <td>{{ $t('shortcutCheatsheet.row14.description') }}</td>
                    </tr>
                    <tr>
                        <td>F11</td>
                        <td>{{ $t('shortcutCheatsheet.row15.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Shift-L</td>
                        <td>{{ $t('shortcutCheatsheet.row16.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Shift-R</td>
                        <td>{{ $t('shortcutCheatsheet.row17.description') }}</td>
                    </tr>
                    <tr>
                        <td>Ctrl-Shift-E</td>
                        <td>{{ $t('shortcutCheatsheet.row18.description') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        data: () => ({
            showing: false,
        }),

        methods: {
            show() {
                this.showing = true
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/mixins";

    .el-table thead {
        color: #404a5e;
    }

    .md-link {
        text-decoration: none !important;
    }

    .md-shortcut-dialog {
        &.dialog__wrapper {
            .dialog {
                margin: 20px auto 0;
                width: 100px;
            }
        }

        .md-shortcut-contents {
            overflow: auto;
            max-height: calc(100vh - 13vh) !important;
        }

        table {
            width: 100%;

            th,
            td {
                padding: 0.5rem;
                border: solid 1px $gray-light;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .md-shortcut-dialog {
            .md-shortcut-contents {
                font-size: .8rem;
            }
        }
    }
</style>
