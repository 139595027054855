// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <InviteForm
            :disabled="!organization.approved"
            :organization="organization"
            :invitations="invitations"
            @saved="refresh"
        />

        <InvitingUsers
            v-if="invitations.length"
            :invitations="invitations"
        />

        <ExistingMembers
            :members="members.data"
            :organization="organization"
        />

        <client-only>
            <InfiniteLoading
                :distance="50"
                spinner="spiral"
                @infinite="load"
            >
                <span slot="no-results" />
                <span slot="no-more" />
            </InfiniteLoading>
        </client-only>
    </div>
</template>

<script>
    import _findIndex from 'lodash/findIndex'
    import InfiniteLoading from 'vue-infinite-loading'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'
    import { cancelInvitation, getInvitingUsers } from '~/api/invitations'
    import {
        getOrganizationEdit, changeRoleMember, getExistingMembers, removeMember,
    } from '~/api/organizations'

    import ExistingMembers from '~/components/subdomains-theme-2/invitations/existing-members/ExistingMembers.vue'
    import InvitingUsers from '~/components/subdomains-theme-2/invitations/inviting-users/InvitingUsers.vue'
    import InviteForm from '~/components/subdomains-theme-2/invitations/form-invite/FormInviteMember.vue'

    const fetchOrganization = slug => getOrganizationEdit(slug).then(({ data }) => data.data)
    const fetchInvitations = slug => getInvitingUsers(slug).then(({ data }) => data.data)
    const fetchMembers = (slug, params) => getExistingMembers(slug, params).then(({ data }) => data)

    export default {
        components: {
            InviteForm,
            InvitingUsers,
            ExistingMembers,
            InfiniteLoading,
        },

        watchQuery: ['user', 'role'],

        scrollToTop: true,

        data() {
            return {
                organization: {},
                invitations: [],
                members: {},
            }
        },

        async asyncData({
            store, route, error, query, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const slug = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : route.params.organization
                const page = 1
                const user = query.user
                const role = query.role

                const [organization, invitations, members] = await Promise.all([
                    fetchOrganization(slug),
                    fetchInvitations(slug),
                    fetchMembers(slug, { page, user, role }),
                ])

                return { organization, invitations, members }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        provide() {
            return {
                cancelInvitation: this.cancelInvitation,
                changeRole: this.changeRoleMember,
                removeMember: this.removeMember,
            }
        },

        methods: {
            async refresh() {
                this.invitations = await fetchInvitations(this.organization.slug)
            },

            async cancelInvitation({ username }) {
                await cancelInvitation(this.organization.slug, username)
                this.refresh()
            },

            changeRoleMember(member, newRole) {
                return changeRoleMember(this.organization.slug, member.username, { newRole })
            },

            async removeMember(member) {
                try {
                    await removeMember(this.organization.slug, { member: member.username })
                    this.$message.success(this.$t('inviteInOrgaization.removeMember.succes'))
                    const index = _findIndex(this.members.data, { id: member.id })
                    this.members.data.splice(index, 1)
                } catch (error) {
                    this.$message.error(this.$t('inviteInOrgaization.removeMember.error'))
                }
            },

            // eslint-disable-next-line consistent-return
            async loadMoreMembers() {
                const req = this.$nuxt.context.ssrContext?.req
                const store = this.$nuxt.context.store
                const params = this.$nuxt.context.route.params
                const query = this.$nuxt.context.route.query
                const error = this.$nuxt.context.error

                const currentPage = this.members.meta.pagination?.current_page
                const page = currentPage ? currentPage + 1 : 1
                const host = process.client ? window.location.host : req.headers.host
                const orgSubdomain = store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : params.organization
                const user = query.user
                const role = query.role

                try {
                    const members = await fetchMembers(orgSubdomain, { page, user, role })

                    this.members = {
                        ...this.members,
                        data: [...this.members.data, ...members.data],
                        meta: members.meta,
                    }
                } catch (err) {
                    return handlePageError(error, err)
                }
            },

            async load($state) {
                const currentPage = this.members.meta.pagination?.current_page
                const totalPages = this.members.meta.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.loadMoreMembers()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/invitations`,
        }),
    }
</script>
