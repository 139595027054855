// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="submissions-post-subdomain p-2">
        <DraftsList :posts="posts" :pagination="pagination" />
    </div>
</template>

<script>
    import { fetchSubmittedDrafts } from '~/api/organizations'
    import DraftsList from '~/components/organizations/drafts/List.vue'
    import { getSlugSubdomain } from '~/utils/pages'

    export default {
        components: {
            DraftsList,
        },

        watchQuery: ['page'],

        scrollToTop: true,

        async asyncData({
            store, params, query, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : params.organization

            const posts = await fetchSubmittedDrafts(orgSubdomain, query).then(({ data }) => data)

            return {
                posts: posts.data,
                pagination: posts.meta.pagination,
            }
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Drafts',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/drafts`,
        }),
    }
</script>

<style lang="scss">
.submissions-post-subdomain {
    background-color: white;
    border-radius: 0.5em;
}

.theme-dark {
    .submissions-post-subdomain {
        background-color: #000;
    }
}
</style>
