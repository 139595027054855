// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        class="tags d-flex flex-wrap"
        :class="{'align-items-center': size === 'medium'}"
    >
        <slot name="prepend" />

        <slot
            v-for="(tag, index) in tags"
            :index="index"
            :tag="tag"
        >
            <v-tag
                :url="toTag(tag, page)"
                :name="tag.name"
                :size="size"
            />
        </slot>

        <slot name="append" />
    </div>
</template>

<script>
    import { tag as toTag } from '~/utils/url'

    export default {
        props: {
            tags: Array,
            size: {
                type: String,
                default: 'mini',
            },
            page: String,
        },

        methods: {
            toTag,
        },
    }
</script>

<style lang="scss" scoped>
    @import "~assets/sass/bootstrap/mixins";

    .tags .tag:not(:last-child) {
        margin-right: 5px;
    }

    .tags .tag.badge-primary:hover, .tags .tag.badge-primary:focus {
        color: #fff;
    }

    .tags .tag {
        font-size: 90%;
        margin-bottom: 0;
        max-width: 200px;
    }

    @include media-breakpoint-down (sm) {
        .tags .tag {
            font-size: .7rem;
        }
    }
</style>
