// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="state.show"
        :title="title"
        class="tags-moderation"
        @open="state.tags = implodeTags()"
    >
        <tags-input
            v-model="state.tags"
            :fetch-suggestions="fetchSuggestions"
            :placeholder="placeholder"
            :allow-create="allowCreate"
            popper-class="tags-moderation__tag-suggestion-popper"
        />

        <template slot="footer">
            <el-button
                size="medium"
                @click="$emit('change', state.tags)"
            >
                {{ $t('detailOrgaization.dialogTag.save') }}
            </el-button>
        </template>
    </v-dialog>
</template>

<script>
    import standaloneDialog from '~/components/mixins/standaloneDialog'
    import TagsInput from '~/components/shared/TagsInput.vue'

    export default {
        components: { TagsInput },
        mixins: [standaloneDialog],
        props: {
            tags: {
                type: Array,
                default: () => [],
            },
            title: {
                type: String,
                default: 'Tags Moderation',
            },
            fetchSuggestions: Function,
            placeholder: {
                type: String,
                default: 'Tags',
            },
            allowCreate: {
                type: Boolean,
                default: true,
            },
        },
        data: () => ({
            state: {
                tags: null,
            },
        }),
        methods: {
            implodeTags() {
                return this.tags.map(tag => tag.name).join(',')
            },
        },
    }
</script>

<style lang="scss">
    .tags-moderation {
        &__tag-suggestion-popper {
            z-index: 2021 !important;
        }
    }
</style>
