// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <section class="company-list-item d-flex company-show py-1">
        <div>
            <Avatar
                :object="organization"
                :image="organization.avatar"
                :level-partner="organization.level_partner"
                :partner-width="65"
                :partner-hight="65"
                :size="organization.level_partner ? 'xl-mini' : 'xl'"
                class="d-block"
                image-size="avatar"
            />
        </div>

        <div class="d-flex flex-column flex-grow-1 justify-content-between mx-1">
            <div class="company-item-name word-break">
                <nuxt-link :to="toOrganization(organization)">
                    {{ organization.name }}
                </nuxt-link>
            </div>
            <div class="company-status">
                <span
                    v-tooltip
                    class="mr-1"
                    :title="$t('organization.companyItem.stast.posts').concat(`${organization.posts_count}`)"
                >
                    <i class="fa fa-pencil" /> {{ organization.posts_count || 0 }}
                </span>
                <span
                    v-tooltip
                    class="mr-1"
                    :title="$t('organization.companyItem.stast.followers').concat(`${organization.followers_count}`)"
                >
                    <i class="fa fa-user-plus" /> {{ organization.followers_count || 0 }}
                </span>
                <span
                    v-tooltip
                    class="mr-1"
                    :title="$t('organization.companyItem.stast.member').concat(`${organization.members_count}`)"
                >
                    <i class="fa fa-users" /> {{ organization.members_count || 0 }}
                </span>
                <span
                    v-tooltip
                    class="mr-1"
                    :title="$t('profile.organizations.view').concat(`${organization.postviews_count}`)"
                >
                    <i class="fa fa-eye" /> {{ organization.postviews_count | formatNumber }}
                </span>
            </div>
        </div>

        <company-menu :organization="organization" class="my-auto" />
    </section>
</template>

<script>
    import { organization as toOrganization } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import CompanyMenu from './Menu.vue'

    export default {
        components: {
            Avatar,
            CompanyMenu,
        },
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        methods: {
            toOrganization,
        },
    }
</script>

<style scoped lang="scss">
    .avt-orglevel-partner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .company-status {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: #9b9b9b;
    }
</style>
