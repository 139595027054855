<template>
    <MemberStats :users="users" :header="header" />
</template>

<script>
    import { format, subDays } from 'date-fns'
    import MemberStats from '~/components/organizations/stats/MemberStats.vue'
    import { getOrganizationStats } from '~/api/organizations'
    import { getSlugSubdomain } from '~/utils/pages'

    const dateString = date => (date ? format(new Date(date), 'yyyy-MM-dd') : '')

    export default {
        components: {
            MemberStats,
        },

        props: {
            users: {
                type: Array,
                default: () => ([]),
            },

            header: {
                type: Object,
                default: () => ({}),
            },
        },

        watch: {
            '$route.query': function $routeQuery(valueNew, valueOld) {
                if (valueNew !== valueOld) {
                    this.getData()
                }
            },
        },

        methods: {
            async getData() {
                try {
                    const query = {
                        from: this.$route.query.from || dateString(subDays(new Date(), 14)),
                        to: this.$route.query.to || dateString(new Date()),
                    }

                    const orgSubdomain = this.$store.state.config.vars.is_special_organization
                        ? getSlugSubdomain(window.location.host) : this.$route.params.organization

                    const response = await getOrganizationStats(orgSubdomain, query)

                    const data = response.data

                    this.users = data.users
                    this.header = data.header
                    this.errors = null
                } catch (e) {
                    this.users = []
                    this.header = {}
                    this.errors = e.response.data
                }
            },
        },
    }
</script>
