// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <user-grid :actions="{ follow }" store-path="organizationView/followers">
        <template slot="empty">
            {{ $t('organization.noFollowers') }}
        </template>
    </user-grid>
</template>

<script>
    import { mapActions } from 'vuex'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import UserGrid from '~/components/users/UserGrid.vue'

    export default {
        name: 'OrganizationFollowers',

        components: {
            UserGrid,
        },

        watchQuery: ['page'],

        scrollToTop: true,

        fetch: ({
            store, params, query, error, req,
        }) => {
            const host = process.client ? window.location.host : req.headers.host
            const orgSubdomain = store.state.config.vars.is_special_organization
                ? getContentSubDomain(host) : params.organization

            return store.dispatch('organizationView/followers/fetch', {
                slug: orgSubdomain,
                params: {
                    limit: store.state.settings.perPage,
                    page: query.page,
                },
            }).catch(handlePageError(error))
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'Followers',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getContentSubDomain(window.location.host) : route.params.organization}/followers`,
        }),
    }
</script>
