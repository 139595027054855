// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        v-bind="$attrs"
        :show.sync="state.show"
        size="small"
        class="users-dialog"
        v-on="$listeners"
    >
        <Empty v-if="users.length === 0">
            <slot name="empty" />
        </Empty>

        <template v-else>
            <div v-for="user in users" :key="user.username" class="users-dialog__item">
                <Avatar
                    :username="user.username"
                    :image="user.avatar"
                    size="sm"
                    class="mr-05"
                />

                <div class="users-dialog__item__name">
                    <nuxt-link :to="profilePage(user)" class="link">
                        <span class="font-weight-bold">{{ user.name }}</span>
                        <span class="text-muted ml-05">{{ `@${user.username}` }}</span>
                    </nuxt-link>
                </div>
            </div>
        </template>
    </v-dialog>
</template>

<script>
    import { routeToUser as profilePage } from '~/lib/functions'
    import standaloneDialog from '~/components/mixins/standaloneDialog'
    import Empty from '~/components/shared/Empty.vue'
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Empty,
            Avatar,
        },

        mixins: [standaloneDialog],

        props: {
            users: Array,
        },

        methods: {
            profilePage,
        },
    }
</script>

<style lang="scss">
    .users-dialog {
        &__item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                padding-bottom: .65rem;
                margin-bottom: .65rem;
                border-bottom: 1px solid #ddd;
            }
        }
    }
</style>
