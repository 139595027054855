// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <section-title :title="$t('detailOrgaization.membersList')" />

        <section class="position-relative">
            <div
                v-for="(member, index) in filterMembers"
                :key="index"
                class="members-avatar d-inline-block"
            >
                <UserInline
                    :user="member"
                    :show-avatar="false"
                    :show-username="false"
                    :default-reference="false"
                >
                    <UserAvatar
                        slot="reference"
                        :image="member.avatar"
                        :username="member.username"
                        size="md"
                    />
                </UserInline>
            </div>
            <div v-if="more" class="members-avatar d-inline-block more-members-btn">
                <el-button
                    size="medium"
                    circle
                    @click="redirect"
                >
                    {{ count }}
                </el-button>
            </div>
        </section>
    </div>
</template>

<script>
    import SectionTitle from '~/components/users/SectionTitle.vue'
    import UserInline from '~/components/shared/user/Inline.vue'
    import UserAvatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            SectionTitle,
            UserInline,
            UserAvatar,
        },

        props: {
            members: {
                type: Array,
                default: () => [],
            },
            pagination: Object,
            organization: Object,
            showMore: {
                type: Boolean,
                default: true,
            },
            limit: {
                type: Number,
                default: 5,
            },
        },

        computed: {
            filterMembers() {
                return this.members.filter((member, index) => index < this.limit)
            },

            more() {
                return this.showMore && this.pagination.total > this.limit
            },

            count() {
                const more = this.more ? this.pagination.total - this.limit : 0
                return more > 9 ? '9+' : `+${more}`
            },
        },

        methods: {
            redirect() {
                if (this.$route.path !== `/o/${this.organization.slug}/members`) {
                    const redirectPath = this.$store.state.config.vars.is_special_organization ? '/members' : `/o/${this.organization.slug}/members`
                    this.$router.push(redirectPath)
                }
            },
        },

        companyAnalytics: route => ({
            autoTrack: false,
            title: 'Members',
            page: `/o/${route.params.organization}/members`,
        }),
    }
</script>

<style>
    .members-avatar{
        padding: 5px;
    }

    .seemore-button {
        position: absolute;
        left: 30%;
        top:85%;
    }

    .more-members-btn {
        vertical-align: top;
        width: 37px;
        height: 37px;
    }
</style>
