// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-form
        ref="form"
        :model="form"
        :disabled="disabled"
        :inline="false"
        size="medium"
        class="card-invite-user"
    >
        <HeadingTitle :title="$t('inviteInOrgaization.title')" class="members-title" />
        <p class="card-invite-user--desc">
            {{ $t('inviteInOrgaization.fistContent') }}
            <nuxt-link :to="toOrganization()">
                {{ organization.name }}
            </nuxt-link> {{ $t('inviteInOrgaization.lastContent') }}
        </p>
        <div class="card-invite-user--form">
            <el-autocomplete
                v-model="form.users"
                :fetch-suggestions="fetchSuggestions"
                :trigger-on-focus="false"
                class="inline-input d-inline-block card-invite-user--form---input-username"
                :placeholder="$t('inviteInOrgaization.formSearch')"
                size="medium"
                @select="handleSelect"
            >
                <span slot-scope="props" class="d-flex user-item">
                    <UserAvatar
                        :image="props.item.avatar"
                        :username="props.item.username"
                        size="sm"
                    />

                    <div class="flex-grow-1 ml-1">
                        <span class="text-name text-dark">{{ props.item.name }}</span>
                        <span class="text-username text-muted ml-1">@{{ props.item.username }}</span>
                    </div>
                </span>
            </el-autocomplete>

            <el-form-item :error="serverErrors.role" prop="role" class="d-inline-block card-invite-user--form---role">
                <el-select
                    v-model="form.role"
                    name="role"
                    :placeholder="$t('inviteInOrgaization.formRole')"
                >
                    <el-option :label="$t('inviteInOrgaization.admin')" value="admin" />
                    <el-option :label="$t('inviteInOrgaization.writer')" value="writer" />
                    <el-option :label="$t('inviteInOrgaization.editor')" value="editor" />
                </el-select>
            </el-form-item>

            <div class="justify-content-end d-inline-block">
                <el-button
                    :loading="processing"
                    size="medium"
                    type="primary"
                    plain
                    class="card-invite-user--form---button-invite"
                    @click="submit($refs.form, inviteUsers)"
                >
                    {{ $t('inviteInOrgaization.invite') }}
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapState } from 'vuex'
    import _forEach from 'lodash/forEach'
    import _includes from 'lodash/includes'
    import _map from 'lodash/map'
    import _isEmpty from 'lodash/isEmpty'

    import { search } from '~/api/search'
    import { organization as toOrganization } from '~/utils/url'
    import { invite } from '~/api/invitations'
    import formMixin from '~/utils/form/mixin'
    import UserAvatar from '~/components/shared/user/Avatar.vue'
    import HeadingTitle from '~/components/subdomains-theme-2/shared/HeadingTitle.vue'

    export default {
        components: {
            UserAvatar,
            HeadingTitle,
        },

        mixins: [formMixin],

        props: {
            organization: {
                type: Object,
                required: true,
            },
            invitations: {
                type: Array,
                default: () => [],
            },
            members: {
                type: Array,
                default: () => [],
            },
            disabled: Boolean,
        },

        data: () => ({
            isDirty: false,
            searchTimeout: null,
            loading: false,
            processing: false,
            form: { users: '', role: 'writer' },
        }),

        computed: {
            ...mapState('auth', { auth: 'user' }),
        },

        methods: {
            fetchSuggestions: (input, done) => (input
                ? search(`users?q=${input}`)
                    .then(_ => _.data)
                    .then(done)
                : done([])
            ),

            handleSelect(item) {
                this.form.users = item.username
            },

            setDirty() {
                this.isDirty = true
            },

            toOrganization() {
                return toOrganization(this.organization)
            },

            reset() {
                this.form.users = ''
            },

            async inviteUsers() {
                this.processing = true
                try {
                    const users = this.form.users ? this.form.users.split(',') : []
                    const formData = { users, role: this.form.role }
                    if (this.validateUsers(users)) {
                        await invite(this.organization.slug, formData)
                        this.$message.success(this.$t('inviteInOrgaization.success'))
                    }
                    this.reset()
                    this.$emit('saved')
                } catch (error) {
                    if (error.response.status === 403) {
                        this.$message.error(this.$t('inviteInOrgaization.error'))
                    }
                    this.handleError(error)
                } finally {
                    this.processing = false
                }
            },

            validateUsers(users) {
                if (_isEmpty(users)) {
                    this.$message.error(this.$t('inviteInOrgaization.valdateError1'))
                    return false
                }
                if (!this.checkUserNotBeenInvited(users)) {
                    this.$message.error(this.$t('inviteInOrgaization.valdateError2'))
                    return false
                }

                return true
            },

            checkUserNotBeenInvited(users) {
                const invitedMember = _map(this.invitations, 'username')
                let condition = true

                _forEach(users, (o) => {
                    condition = !_includes(invitedMember, o)

                    return condition
                })

                return condition
            },
        },
    }
</script>

<style lang="scss" scoped>
.card-invite-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    padding: 32px 48px;
    border-radius: 8px;
    background-color: #fff;

    &--title {
        margin-bottom: 16px;
        color: #000;
        font-weight: 700;
    }

    &--desc {
        margin-bottom: 8px;
        font-size: 1rem;
        margin-left: 48px;

        @media screen and (max-width: 640px) {
            margin-left: 0;
        }
    }

    &--form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        margin-left: 48px;

        @media screen and (max-width: 640px) {
            margin-left: 0;
        }

        &---input-username {
            width: 350px;
        }

        &---role {
            width: 110px;
            margin-bottom: 0 !important
        }

        &---button-invite {
            border-radius: 20px;
            background: #D9D9D9;
            color: #fff;
            font-weight: 700;
        }
    }
}

.theme-dark {
    .card-invite-user {
        background-color: #100F09;
        border: 1px solid #585858;
    }
}
</style>
