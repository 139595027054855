<template>
    <div class="container mb-3">
        <div class="row d-flex flex-column">
            <el-alert
                :closable="false"
                show-icon
                type="warning"
                class="mb-2"
            >
                <slot name="title">
                    {{ $t('verifyOrganization.firstTitle') }}
                    <a
                        :href="organization.website"
                        target="_blank"
                        rel="noopener
                        noreferrer"
                    >{{ organization.website }}</a>
                    {{ $t('verifyOrganization.lastTitle') }}
                </slot>
            </el-alert>

            <div class="p-4 border">
                <h2 class="pb-1 ">
                    {{ $t('verifyOrganization.strategies') }}
                </h2>

                <el-collapse accordion>
                    <el-collapse-item title="HTML file upload" class="typeVerify">
                        <template slot="title">
                            <div class="collapse-title font-weight-bold">
                                {{ $t('verifyOrganization.htmlFile.title') }}
                            </div>
                        </template>
                        <div class="tutorial-verify">
                            <p>
                                {{ $t('verifyOrganization.htmlFile.step1') }}
                                <a
                                    :href="htmlFileUrl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {{ $t('verifyOrganization.htmlFile.here') }}
                                </a>.
                            </p>
                            <p>
                                {{ $t('verifyOrganization.htmlFile.step2') }}
                            </p>
                            <p>
                                {{ $t('verifyOrganization.htmlFile.step3') }}
                            </p>
                            <el-button type="primary" class="my-1" @click="handleVerify('html-file')">
                                {{ $t('verifyOrganization.htmlFile.button') }}
                            </el-button>
                        </div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div class="collapse-title font-weight-bold">
                                {{ $t('verifyOrganization.htmlTag.title') }}
                            </div>
                        </template>
                        <div class="tutorial-verify">
                            <p>
                                {{ $t('verifyOrganization.htmlTag.step1') }}
                            </p>
                            <p>
                                {{ $t('verifyOrganization.htmlTag.step2') }}
                            </p>
                            <pre class="language-php" data-filename="">{{ htmlTag }}</pre>
                            <p>
                                {{ $t('verifyOrganization.htmlTag.step3') }}
                            </p>
                            <el-button type="primary" class="my-1" @click="handleVerify('html-meta')">
                                {{ $t('verifyOrganization.htmlFile.button') }}
                            </el-button>
                        </div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div class="collapse-title font-weight-bold">
                                {{ $t('verifyOrganization.dnsRecord.title') }}
                            </div>
                        </template>
                        <div class="tutorial-verify">
                            <p>{{ $t('verifyOrganization.dnsRecord.step1') }}</p>
                            <p>{{ $t('verifyOrganization.dnsRecord.step2') }}</p>
                            <p>{{ $t('verifyOrganization.dnsRecord.step3') }}</p>
                            <pre class="language-php" data-filename="">{{ dnsRecord }}</pre>
                            <p>{{ $t('verifyOrganization.dnsRecord.step4') }}</p>
                            <el-button type="primary" class="my-1" @click="handleVerify('dns-record')">
                                {{ $t('verifyOrganization.htmlFile.button') }}
                            </el-button>
                        </div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div class="collapse-title font-weight-bold">
                                {{ $t('verifyOrganization.email.title') }}
                            </div>
                        </template>
                        <div class="tutorial-verify">
                            <p>
                                {{ $t('verifyOrganization.email.step1') }}
                            </p>
                            <template v-if="!sent">
                                <p>
                                    {{ $t('verifyOrganization.email.step2') }}
                                </p>
                                <el-select v-model="emailTo" placeholder="Select">
                                    <el-option
                                        v-for="item in ['admin', 'webmaster']"
                                        :key="item"
                                        :label="`${item}@${domainName}`"
                                        :value="item"
                                    />
                                </el-select>

                                <el-button type="primary" class="my-2" @click="sendMail">
                                    {{ $t('verifyOrganization.htmlFile.button') }}
                                </el-button>
                            </template>

                            <span v-else class="text-success">
                                {{ $t('verifyOrganization.email.sent.firstTitle') }}
                                <a
                                    class="text-bold text-success"
                                    href="#"
                                    @click.prevent="sent = false"
                                ><u>{{ $t('verifyOrganization.email.sent.betweenTitle') }}</u></a>
                                {{ $t('verifyOrganization.email.sent.lastTitle') }}
                            </span>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    import ElCollapse from 'element-ui/lib/collapse'
    import ElCollapseItem from 'element-ui/lib/collapse-item'
    import 'element-ui/packages/theme-chalk/src/collapse.scss'
    import {
        getOrganizationEdit,
        getVerifyToken,
        websiteVerify,
        sendVerificationMail,
    } from '~/api/organizations'
    import { handlePageError, getSlugSubdomain } from '~/utils/pages'

    export default {
        components: {
            ElCollapse,
            ElCollapseItem,
        },

        data() {
            return {
                radio: '1',
                emailTo: 'admin',
            }
        },

        computed: {
            htmlTag() {
                return `<meta name="${this.verifyName}" content="${this.verifyToken}">`
            },

            dnsRecord() {
                return `${this.verifyName}=${this.verifyToken}`
            },
        },

        async asyncData({
            store, route, error, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const slug = store.state.config.vars.is_special_organization
                ? getSlugSubdomain(host) : route.params.organization

            const organization = await getOrganizationEdit(slug)
                .then(({ data }) => data.data)
                .catch(handlePageError(error))

            const data = await getVerifyToken(slug, { strategy: 'html-file' })
                .then(res => res.data)
                .catch(handlePageError(error))

            const sent = data.email_sent_at

            return {
                slug,
                organization,
                verifyName: data.name,
                verifyToken: data.token,
                htmlFileUrl: data.html_file,
                domainName: data.domain_name,
                sent,
            }
        },

        methods: {
            async sendMail() {
                try {
                    await sendVerificationMail(this.slug, {
                        email_to: this.emailTo,
                    }).then(res => res.data)
                    this.sent = true
                    this.$message.success(this.$t('verifyOrganization.email.success'))
                } catch (error) {
                    this.$message.error(this.$t('verifyOrganization.email.error'))
                }
            },

            async handleVerify(strategy) {
                try {
                    const data = await websiteVerify(this.slug, { strategy })
                        .then(res => res.data)

                    if (data.result === true) {
                        this.$message.success(this.$t('verifyOrganization.success'))
                        this.$router.push(`/o/${this.slug}`)
                    } else {
                        this.$message.warning(this.$t('verifyOrganization.warning'))
                    }
                } catch (error) {
                    this.$message.error(this.$t('verifyOrganization.error'))
                }
            },
        },

        companyAnalytics: (route, store) => ({
            autoTrack: false,
            title: 'info edit',
            page: `/o/${store.state.config.vars.is_special_organization
                ? getSlugSubdomain(window.location.host) : route.params.organization}/info/website-verify`,
        }),
    }
</script>

<style>
.collapse-title {
    color: #292b2c;
}
</style>
